import Demo1Raw from 'images/demo-1-raw.jpg';
import Demo2Raw from 'images/demo-2-raw.jpg';
import Demo3Raw from 'images/demo-3-raw.jpg';
import Demo4Raw from 'images/demo-4-raw.jpg';
import Demo5Raw from 'images/demo-5-raw.jpg';

const localImageAssetToName = (image: string): string => {
  switch (image) {
    case 'demo-1-raw.jpg': {
      return Demo1Raw;
    }
    case 'demo-2-raw.jpg': {
      return Demo2Raw;
    }
    case 'demo-3-raw.jpg': {
      return Demo3Raw;
    }
    case 'demo-4-raw.jpg': {
      return Demo4Raw;
    }
    case 'demo-5-raw.jpg': {
      return Demo5Raw;
    }
    default: {
      return image;
    }
  }
};

export default localImageAssetToName;
