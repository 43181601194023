import React, { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as SpinnerIcon } from 'icons/spinner.svg';
import useOAuth from 'hooks/api/useOAuth';
import useAuth from 'hooks/useAuth';

const GoogleCallback = (): ReactElement => {
  const [isVerifying, setIsVerifying] = React.useState<boolean>(true);
  const [success, setSuccess] = React.useState<{
    status: boolean;
    error: string;
  }>({ status: false, error: '' });

  const location = useLocation();
  const { search } = location;
  const response = decodeURIComponent(search);

  const searchParams = new URLSearchParams(response);
  const code = searchParams.get('code');
  const err = searchParams.get('error');

  const { oauthGoogle } = useOAuth();
  const { storeTokenCredentials } = useAuth();

  useEffect(() => {
    setIsVerifying(true);
    // Hit the end-point
    if (code) {
      oauthGoogle(code)
        .then((data) => {
          if ('errors' in data) {
            // Handle error
            console.log(data);
            setIsVerifying(false);
            setSuccess({ status: false, error: JSON.stringify(data) });
          } else {
            // Start authenticating
            setIsVerifying(false);
            setSuccess({ status: true, error: '' });
            // Send a message with this information to the main window
            storeTokenCredentials(data);
            window.location.replace(`${window.location.origin}/quiz`);
          }
        })
        .catch((err) => {
          console.log(err);
          setSuccess({ status: false, error: err.message });
        });
    } else {
      setIsVerifying(false);
      setSuccess({
        status: false,
        error: `Google did not send back a code for this user. Path was: ${search}`,
      });
    }
  }, []);

  return (
    <div className="h-screen w-full p-10 flex justify-center items-center">
      {!isVerifying && (
        <div className="mb-24">
          {success && (
            <>
              <p className="text-neutral-900 font-medium text-xl">
                Thanks for signing in with Google!
              </p>
              <p className="text-neutral-800 font-medium pt-2">
                You should now be signed in. You can close this popup window.
              </p>
            </>
          )}
          {!success && (
            <>
              <p className="text-neutral-900 font-medium text-xl">
                Signin with Google failed :(
              </p>
              <p className="text-neutral-800 font-medium pt-2">
                Try signing in again. If the problem persists please contact 3DQ
                developers with the following error message:
              </p>
              <p className="text-red-600 font-medium pt-2">{err}</p>
            </>
          )}
        </div>
      )}
      {isVerifying && (
        <div className="mb-24">
          <SpinnerIcon
            className="animate-spin mt-3 mr-3 h-5 w-5 text-blue-500"
            width={50}
            height={50}
          />
        </div>
      )}
    </div>
  );
};

export default GoogleCallback;
