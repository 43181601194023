import React, { ReactElement, useContext } from 'react';
import { AuthContext } from 'providers/AuthContext';
import { QuizExperienceResponse } from 'hooks/api/useQuiz';
import { ReactComponent as GoogleButton } from 'icons/icons8-google.svg';
import { ReactComponent as AwardIcon } from 'icons/award.svg';
import Popup from 'reactjs-popup';
import millify from 'millify';
import { v4 as uuidv4 } from 'uuid';
import googleOAuthURLBuilder from 'util/OAuth';
import useAuth from 'hooks/useAuth';

interface PrintleNavbarProps {
  experience?: QuizExperienceResponse;
  points: number;
}

export const PrintleNavbar = ({
  experience,
  points,
}: PrintleNavbarProps): ReactElement => {
  const { auth } = useContext(AuthContext);
  const { unAuthenticate } = useAuth();

  const onSign = () => {
    const OAuthURL = googleOAuthURLBuilder({
      clientID: process.env.REACT_APP_OAUTH_GOOGLE_CLIENT || '',
      accessType: 'offline',
      includeGrantedScopes: true,
      redirectURI: process.env.REACT_APP_OAUTH_GOOGLE_REDIRECT || '',
      responseType: 'code',
      scopes: [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
      ],
      state: uuidv4(),
    });
    window.location.replace(OAuthURL);
  };

  return (
    <div className="flex items-center justify-between w-full bg-blue-600 h-14 px-6">
      <div className="text-ellipsis whitespace-nowrap overflow-hidden min-w-0">
        <p className="text-neutral-50 font-semibold text-lg whitespace-nowrap overflow-hidden overflow-ellipsis">
          {auth && '3DPrintle'}
          {!auth &&
            '3DPrintle: The daily game where you stop print fails before they stop you!'}
        </p>
      </div>
      <div className="flex-1 lg:flex justify-center hidden">
        {experience && auth && (
          <Popup
            on={'hover'}
            trigger={
              <div
                className="inline-flex items-center max-w-2xl"
                style={{ width: '90%' }}
              >
                <div className="font-semibold text-neutral-50 flex justify-center items-center select-none">
                  <p>QuinlyVision AI Progress</p>
                </div>
                <div
                  className="bg-neutral-200 flex-1 mx-2 rounded-md"
                  style={{ height: 25 }}
                >
                  <div
                    className="bg-gradient-to-r from-yellow-400 to-yellow-500 transition-all delay-300 rounded-md overflow-x-visible text-neutral-900 font-semibold flex align-center"
                    style={{
                      width: `${
                        (experience.experienceCurrentLevel /
                          (experience.experienceCurrentLevel +
                            experience.experienceToNextLevel)) *
                        100
                      }%`,
                      height: 25,
                    }}
                  >
                    <p
                      className="ml-2"
                      style={{ paddingTop: 1, whiteSpace: 'nowrap' }}
                    >
                      {experience.experienceCurrentLevel * 100} exp
                    </p>
                  </div>
                </div>
                <div className="bg-blue-900 rounded-full font-semibold text-neutral-50 flex justify-center items-center select-none px-3 py-1">
                  <p style={{ paddingTop: 1 }}>LVL {experience.level + 1}</p>
                </div>
              </div>
            }
            position={['bottom center']}
            arrow={false}
          >
            <div className="rounded-md py-4 px-4 bg-neutral-900 shadow-lg select-none m-2">
              <p className="text-neutral-50 text-left max-w-md">
                Work together as a community to make QuinlyVision smarter! Watch
                QuinlyVision improve over time.
              </p>
              <div className="w-full grid grid-flow-col pt-2 gap-4">
                <div>
                  <p className="uppercase text-neutral-300 font-bold text-xs">
                    Experience Until Next Level
                  </p>
                  <p className="text-blue-400 font-bold">
                    {millify(experience.experienceToNextLevel * 100)}
                  </p>
                </div>
                <div>
                  <p className="uppercase text-neutral-300 font-bold text-xs">
                    Your Lifetime Contribution
                  </p>
                  <p className="text-blue-400 font-bold">
                    {millify(experience.userContribution * 100)} (
                    {(() => {
                      const percent =
                        Math.round(
                          (experience.userContribution /
                            experience.totalExperience) *
                            100 *
                            100,
                        ) / 100;
                      if (!percent) {
                        return 0;
                      }
                      return percent;
                    })()}
                    %)
                  </p>
                </div>
              </div>
            </div>
          </Popup>
        )}
      </div>
      <div className="h-full flex items-center flex-shrink-0">
        {auth && (
          <div className="mr-6 flex justify-center items-center text-neutral-50">
            <AwardIcon width={24} height={24} strokeWidth={2} />
            <p className="ml-1 font-semibold">{millify(points)} points</p>
          </div>
        )}
        {!auth && (
          <button
            className="bg-neutral-900 bg-opacity-60 hover:bg-opacity-30 transition-colors delay-75 text-neutral-100 py-2 px-3 rounded-lg"
            onClick={onSign}
          >
            <GoogleButton
              className="inline-block"
              width={25}
              height={25}
              strokeWidth={1.5}
            />
            <p className="font-medium inline-block align-middle pl-2">
              Play With Google
            </p>
          </button>
        )}
        {auth && (
          <Popup
            trigger={
              <div className="inline-flex items-center justify-center bg-neutral-900 bg-opacity-60 hover:bg-opacity-30 transition-colors delay-75 px-2 py-1 rounded-lg cursor-pointer select-none">
                <img
                  className="rounded-full"
                  src={auth.avatar}
                  width={35}
                  height={35}
                />
                <p className="ml-2 font-medium text-neutral-50">{auth.email}</p>
              </div>
            }
            position="bottom left"
            on="click"
            arrow={false}
          >
            <div className="bg-neutral-50 border border-neutral-300 rounded-md px-4 py-1 mt-1 flex flex-col">
              <button
                className="text-neutral-800 font-medium text-center py-1 px-1 hover:bg-neutral-100 rounded-lg select-none"
                onClick={() => {
                  unAuthenticate();
                }}
              >
                Logout
              </button>
            </div>
          </Popup>
        )}
      </div>
    </div>
  );
};

export default PrintleNavbar;
