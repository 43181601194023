export const getFullPrintleSummaryText = (
  points: number,
  maxPoints: number,
  percentile: number,
  emojis: string[],
): string => {
  return `3DPrintle#${new Date().toDateString()}\n${emojis.join(
    '',
  )}\nPoints ${points} / ${maxPoints} (Better than ${
    Math.round(percentile * 100 * 10) / 10
  }% of players)\n\nCheck it out at 3dprintle.com`;
};

export const getSimplePrintleSummaryText = (
  points: number,
  maxPoints: number,
  emojis: string[],
): string => {
  return `3DPrintle -- ${new Date().toDateString()}\n${emojis.join(
    '',
  )}\nPoints ${points} / ${maxPoints}\n\nCheck it out at 3dprintle.com`;
};
