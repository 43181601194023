import { APIErrorResponse, UserProfile } from 'common/APITypes';

const baseURL = `${process.env.REACT_APP_API_REST_PROTOCOL}://${
  process.env.REACT_APP_API_URL || ''
}/api/v1/users`;

interface UsersMeOptions {
  token: string;
}

interface IUseUsers {
  me: (opts: UsersMeOptions) => Promise<UserProfile | APIErrorResponse>;
}

const useUsers = (): IUseUsers => {
  const me = (
    opts: UsersMeOptions,
  ): Promise<UserProfile | APIErrorResponse> => {
    const { token } = opts;
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/@me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  };

  return { me };
};

export default useUsers;
