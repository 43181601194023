import { ClassificationCategory, Inference } from 'common/APITypes';
import React, { ReactElement } from 'react';
import Popup from 'reactjs-popup';
import { AnnotatorResult } from './AnnotatorResult';
import { ReactComponent as AwardIcon } from 'icons/award.svg';
import { ReactComponent as StarOutlined } from 'icons/star-outlined.svg';
import { ReactComponent as StarFilled } from 'icons/star-filled.svg';
import { ParentSize } from '@visx/responsive';

interface QuizSubmitModalProps {
  points: number;
  maxPoints: number;
  isOpen: boolean;
  truth: Inference[];
  test: Inference[];
  width: number;
  height: number;
  img: string;
  categories: ClassificationCategory[];
  hasNext?: boolean;
  onClose?: () => void;
  onContinue?: () => void;
}

const QuizSubmitModal = (props: QuizSubmitModalProps): ReactElement => {
  const {
    points,
    maxPoints,
    onClose,
    onContinue,
    hasNext,
    isOpen,
    categories,
    height,
    width,
    img,
    truth,
    test,
  } = props;

  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={() => {
        onClose && onClose();
      }}
    >
      <div
        className="bg-neutral-50 rounded-md max-w-5xl overflow-y-auto flex-col justify-center items-center"
        style={{ maxHeight: 'calc(100vh - 50px)', width: '90vw' }}
      >
        <div className="text-center bg-gradient-to-r from-blue-600 to-blue-800 px-4 py-6 pb-6">
          <p className="text-4xl text-neutral-50 font-bold inline-flex mt-4 tracking-wide">
            <span className="mr-1 mt-1">
              <AwardIcon width={40} height={40} strokeWidth={2} />
            </span>
            {points}
          </p>
          <h1 className="text-lg font-semibold text-neutral-100">
            {(() => {
              const percent = points / maxPoints;
              if (percent > 0.75) {
                return 'Amazing job! Keep it up.';
              } else if (percent > 0.4) {
                return 'You did pretty well! Keep going.';
              } else {
                return "Keep trying! Let's get a better score next time";
              }
            })()}
          </h1>
          <p className="text-neutral-200 font-semibold pt-2">
            {(() => {
              const percent = points / maxPoints;
              const starBreakpoints = [0, 0.2, 0.4, 0.6, 0.8];
              return starBreakpoints.map((sb, index) => {
                if (percent > sb) {
                  return (
                    <StarFilled
                      key={index}
                      className="inline-block align-middle"
                      width={25}
                      height={25}
                    />
                  );
                } else {
                  return (
                    <StarOutlined
                      key={index}
                      className="inline-block align-middle"
                      width={22}
                      height={22}
                    />
                  );
                }
              });
            })()}
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 pt-8 px-6">
          <div className="pt-4 mr-2">
            <div className="text-center">
              <p className="text-neutral-700 text font-medium uppercase tracking-wider">
                Your Score
              </p>
              <h2 className="text-neutral-700 font-semibold text-4xl">
                {points}
              </h2>
            </div>
            <ParentSize>
              {(parent) => (
                <AnnotatorResult
                  isOpen={isOpen}
                  categories={categories}
                  width={width}
                  height={height}
                  containerHeight={parent.height}
                  containerWidth={parent.width}
                  img={img}
                  inferences={test}
                />
              )}
            </ParentSize>
          </div>
          <div className="pt-4 ml-2">
            <div className="text-center">
              <p className="text-neutral-700 text font-medium uppercase tracking-wider">
                QuinlyVision Score
              </p>
              <h2 className="text-neutral-700 font-semibold text-4xl">
                {maxPoints}
              </h2>
            </div>
            <ParentSize>
              {(parent) => (
                <AnnotatorResult
                  isOpen={isOpen}
                  categories={categories}
                  width={width}
                  height={height}
                  containerHeight={parent.height}
                  containerWidth={parent.width}
                  img={img}
                  inferences={truth}
                />
              )}
            </ParentSize>
          </div>
        </div>
        <div className="w-full px-6">
          <div className="inline-flex py-6 justify-center md:justify-end w-full">
            {hasNext === true ||
              (hasNext === undefined && (
                <button
                  className="bg-blue-600 hover:bg-blue-700 transition-colors rounded-md font-semibold py-2 px-4 text-neutral-50"
                  onClick={onContinue}
                >
                  Do Another!
                </button>
              ))}
            <button
              className="bg-neutral-200 hover:bg-neutral-300 transition-colors rounded-md font-semibold py-2 px-4 text-neutral-800 ml-2"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default QuizSubmitModal;
