import React, { ReactElement, useState } from 'react';
import { ReactComponent as AcademicCapIcon } from '../icons/academic-cap.svg';

export interface Indicator {
  text: string;
  color: string;
}

export interface Fixes {
  problem: string;
  solution: string;
}

interface FailurePreventionCardProps {
  title: string;
  description: string;
  tips: Fixes[];
}

const FailurePreventionCard = (
  props: FailurePreventionCardProps,
): ReactElement => {
  const { title, description, tips } = props;

  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div
      onClick={() => {
        setExpanded(!expanded);
      }}
      className="bg-neutral-200 rounded-md my-2 cursor-pointer border border-neutral-400 hover:border-blue-500"
    >
      <div
        className={`flex px-5 pt-5 pb-5 rounded-t-md ${
          expanded ? 'bg-neutral-100' : ''
        }`}
      >
        <AcademicCapIcon
          className={`${
            expanded ? 'text-blue-500' : 'text-neutral-800 '
          } flex-none`}
          width={25}
          height={25}
          strokeWidth={1.5}
        />
        <div className="pl-4">
          <p className="text-neutral-800 font-semibold text-base">{title}</p>
          <p className="text-neutral-600 text text-sm pt-1">{description}</p>
        </div>
      </div>
      {expanded && (
        <div className="px-5 py-3">
          <p className="text-neutral-500 text-xs font-semibold uppercase tracking-wide pb-2">
            Possible Solutions
          </p>
          <ul className="list-inside list-none">
            {tips.map((tip, i) => (
              <li
                className={`text-sm py-3 list-ite px-4 rounded-md ${
                  i % 2 === 0 ? 'bg-neutral-100' : 'bg-transparent'
                }`}
                key={i}
              >
                <p className="text-sm text-neutral-800 font-semibold">
                  {tip.problem}
                </p>
                <p className="text-sm text-neutral-600">{tip.solution}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FailurePreventionCard;
