import { useStrictMode } from 'react-konva';
import React, { ReactElement, useContext, useEffect } from 'react';
import QVBridgingOverlap from 'images/QV_BridgingOverhang.jpg';
import { ReactComponent as GoogleButton } from 'icons/icons8-google.svg';
import GuestUserProfile from 'images/guest-user-profile.jpg';
import QVDetach from 'images/QV_Detach.jpg';
import QVLayerShift from 'images/QV_LayerShift.jpg';
import QVNoExtrusion from 'images/QV_NoExtrusion.jpg';
import QVNozzbleBlob from 'images/QV_NozzleBlob.jpg';
import QVOverExtrusion from 'images/QV_OverExtrusion.png';
import QVPoorFirstLayer from 'images/QV_PoorFirstLayer.jpg';
import QVSkirt from 'images/QV_Skirt.jpg';
import QVSpaghetti from 'images/QV_Spaghetti.jpg';
import QVStringing from 'images/QV_Stringing.png';
import QVUnderExtrusion from 'images/QV_Underextrusion.jpg';
import QVWarping from 'images/QV_Warping.jpg';
import QVZits from 'images/QV_Zits.jpg';
import TutorialEasy from 'images/tutorial-easy.gif';
import TutorialHard from 'images/tutorial-hard.gif';
import { OAuthResponse } from 'hooks/api/useOAuth';
import useAuth from 'hooks/useAuth';
import Popup from 'reactjs-popup';
import useQuiz, {
  Inference,
  PrintleDifficulty,
  QuizImagesResponse,
  QuizNextAvailableResponse,
  QuizSubmitResponse,
  QuizSummaryResponse,
} from 'hooks/api/useQuiz';
import usePoints from 'hooks/usePoints';
import {
  APIErrorResponse,
  ClassificationCategory,
  PrintleExperience,
  QuizState,
} from 'common/APITypes';
import FailVideos from 'components/quiz/FailVideos';
import { PrintleSummary } from 'components/PrintleSummary';
import AnnotatorCanvas from 'components/AnnotatorCanvas';
import { AuthContext } from 'providers/AuthContext';
import { io } from 'socket.io-client';
import { Helmet } from 'react-helmet';
import { ModalContext } from 'providers/ModalContext';
import PrintleNavbar from 'components/PrintleNavbar';
import googleOAuthURLBuilder from 'util/OAuth';
import { v4 as uuidv4 } from 'uuid';
import useMarketingAPI from 'hooks/useMarketingAPI';
import NewPrintleUserModal from 'components/modals/NewPrintleUserModal';

useStrictMode(true);

const categories: ClassificationCategory[] = [
  {
    color: '#fc8642',
    name: 'detach',
    displayName: 'Detach',
    videoURL: 'https://www.youtube.com/embed/NSaigUEJxbw',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">Detach</h1>
        <p className="text-neutral-700 leading-6 py-2">
          Detach is identified by printed parts in the photo that are no longer
          connected to the bed or the model.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          Detach can happen when a part falls over mid-print, a first-layer
          peels up, or when part of a print breaks off and falls on the bed.
        </p>
        <img className="w-full mt-4" height="auto" src={QVDetach}></img>
      </>
    ),
    enabled: true,
  },
  {
    color: '#66C7F4',
    name: 'layer-shift',
    displayName: 'Layer Shift',
    videoURL: 'https://www.youtube.com/embed/S8QFziLYS2c',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">Layer Shift</h1>
        <p className="text-neutral-700 leading-6 py-2">
          Layer-Shift is identified by a part that has a misalignment at some
          point during the print, causing the part to look like it has shifted
          sideways. Usually, layer shifts have exposed infill on one side, and
          droopy overhangs on the other side.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          The nice thing is layer shifts don&apos;t usually cause a mess or
          decalibration, so it&apos;s possible to automatically stop, reset, and
          reprint the model with Quinly automation.
        </p>
        <img className="w-full mt-4" height="auto" src={QVLayerShift}></img>
      </>
    ),
    enabled: true,
  },
  {
    color: '#1b0bb3',
    name: 'no-extrusion',
    displayName: 'No Extrusion',
    videoURL: 'https://www.youtube.com/embed/aKi2FATxSCI',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">No Extrusion</h1>
        <p className="text-neutral-700 leading-6 py-2">
          No-Extrusion is identified by a gap between the nozzle and the part
          being printed, and/or nothing coming out of the nozzle.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          Pictures taken during travel moves look like “No-Extrusion”
          situations. Travel moves still count as No-Extrusion. QuinlyVision
          analyzes the gcode to filter out any No-Extrusion warnings that occur
          during a travel (G0) movement.
        </p>
        <img className="w-full mt-4" height="auto" src={QVNoExtrusion}></img>
      </>
    ),
    enabled: true,
  },
  {
    color: '#ACF39D',
    name: 'nozzle-blob',
    displayName: 'Nozzle Blob',
    videoURL: 'https://www.youtube.com/embed/uqNPh1NOILM',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">Nozzle Blob</h1>
        <p className="text-neutral-700 leading-6 py-2">
          Nozzle-Blobs are identified by a large, molten clump of plastic stuck
          to the nozzle and/or hotend assembly.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          Nozzle blobs are probably the most destructive kind of 3D print
          failure, because they can cause irreparable damage to hotend wiring,
          damage to the motherboard from short circuits, and can encase the
          hotend making it very difficult to remove and clean
        </p>
        <img className="w-full mt-4" height="auto" src={QVNozzbleBlob}></img>
      </>
    ),
    enabled: true,
  },
  {
    color: '#FF1053',
    name: 'over-extrusion',
    displayName: 'Over Extrusion',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">
          Over Extrusion
        </h1>
        <p className="text-neutral-700 leading-6 py-2">
          Over-Extrusion is identified by bulging corners, uneven layers, and an
          overall “melted” look.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          Over-extruded parts are rarely useful, and over-extrusion can be an
          early warning sign of other failures such as a nozzle blob, or
          no-extrusion due to the increased nozzle pressure causing a filament
          jam.
        </p>
        <img className="w-full mt-4" height="auto" src={QVOverExtrusion}></img>
      </>
    ),
    enabled: true,
  },
  {
    color: '#34344A',
    name: 'poor-bridging-overhang',
    displayName: 'Poor Bridging Overhang',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">
          Poor Bridging Overhang
        </h1>
        <p className="text-neutral-700 leading-6 py-2">
          Poor-Bridging-Overhang is identified by drooping lines of material on
          an otherwise good part.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          It is an indicator of poor filament settings or insufficient cooling.
          Parts that have drooping bridges or overhangs should be caught early
          to avoid wasting time and material.
        </p>
        <img
          className="w-full mt-4"
          height="auto"
          src={QVBridgingOverlap}
        ></img>
      </>
    ),
    enabled: false,
  },
  {
    color: '#8826eb',
    name: 'poor-initial-layer',
    displayName: 'Poor Initial Layer',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">
          Poor Initial Layer
        </h1>
        <p className="text-neutral-700 leading-6 py-2">
          Poor-Initial-Layer is identified by lines that do not stick to the
          print bed, or first layers that are so squished that it grinds into
          the bed surface.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          Lines that are too far from the bed, or too close, can both lead to
          many different kinds of 3D print failures, especially nozzle blobs, so
          it is very important to be able to catch these bad prints early.
        </p>
        <img className="w-full mt-4" height="auto" src={QVPoorFirstLayer}></img>
      </>
    ),
    enabled: false,
  },
  {
    color: '#F2E863',
    name: 'skirt',
    displayName: 'Skirt',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">Skirt</h1>
        <p className="text-neutral-700 leading-6 py-2">
          Skirt failure is identified when the skirt peels up before the actual
          print begins.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          Skirt failure only happens on the first layer of the print, and will
          likely lead to a nozzle blob if left unattended.
        </p>
        <img className="w-full mt-4" height="auto" src={QVSkirt}></img>
      </>
    ),
    enabled: false,
  },
  {
    color: '#EAD2AC',
    name: 'spaghetti',
    displayName: 'Spaghetti',
    videoURL: 'https://www.youtube.com/embed/cAhHtMhTGtc',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">Spaghetti</h1>
        <p className="text-neutral-700 leading-6 py-2">
          Spaghetti is identified by random squiggly lines of material that are
          not attached to the part being printed.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          Spaghetti is the best indicator of catastrophic failure. When the
          nozzle extrudes material in mid-air with nothing underneath to print
          on, then it spirals out into a pile of spaghetti.
        </p>
        <img className="w-full mt-4" height="auto" src={QVSpaghetti}></img>
        <div className="bg-yellow-400 rounded-md py-2 px-4 mt-2">
          <div className="bg-neutral-800 rounded-md p-4 mb-2 text-neutral-100">
            <p className="font-semibold">
              WAIT! Do not confuse Spaghetti with Stringing.
            </p>
            <p className="font-semibold">
              Stringing actually looks more like a spider web.
            </p>
          </div>
          <img className="w-full" height="auto" src={QVStringing}></img>
        </div>
      </>
    ),
    enabled: true,
  },
  {
    color: '#F9C22E',
    name: 'stringing',
    displayName: 'Stringing',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">Stringing</h1>
        <p className="text-neutral-700 leading-6 py-2">
          Stringing is identified by thin, wispy strands of material connecting
          between two points on a printed part.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          Stringing is an annoying defect to deal with because it can make
          prints unusable, or require extensive post-processing.
        </p>
        <img className="w-full mt-4" height="auto" src={QVStringing}></img>
        <div className="bg-yellow-400 rounded-md py-2 px-4 mt-2">
          <div className="bg-neutral-800 rounded-md p-4 mb-2 text-neutral-100">
            <p className="font-semibold">
              WAIT! Do not confuse Stringing with Spaghetti.
            </p>
            <p className="font-semibold">
              Spaghetti looks more random than Stringing, it does not look like
              spider web from one part of the print to the other.
            </p>
          </div>
          <img className="w-full" height="auto" src={QVSpaghetti}></img>
        </div>
      </>
    ),
    enabled: true,
  },
  {
    color: '#C1CAD6',
    name: 'under-extrusion',
    displayName: 'Under Extrusion',
    videoURL: 'https://www.youtube.com/embed/eAJqABGmC9I',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">
          Under Extrusion
        </h1>
        <p className="text-neutral-700 leading-6 py-2">
          Under-Extrusion is identified by a spongy print texture, or gaps
          between lines of material, especially noticeable on walls or top
          surfaces.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          Under-Extrusion can indicate incorrect flow settings, various extruder
          issues, or a partial nozzle clog.
        </p>
        <img className="w-full mt-4" height="auto" src={QVUnderExtrusion}></img>
      </>
    ),
    enabled: true,
  },
  {
    color: '#6C6EA0',
    name: 'warping',
    displayName: 'Warping',
    videoURL: 'https://www.youtube.com/embed/dlP2OBGEJ_8',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">Warping</h1>
        <p className="text-neutral-700 leading-6 py-2">
          Warping is identified by curling corners on the bottom surface of a
          part.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          Warping is an early warning sign that your print might fail. It
          indicates that temperature settings are wrong, the first layer
          settings are wrong, or an enclosure is required for the material.
        </p>
        <img className="w-full mt-4" height="auto" src={QVWarping}></img>
      </>
    ),
    enabled: true,
  },
  {
    color: '#f74aa4',
    name: 'zits',
    displayName: 'Zits',
    info: (
      <>
        <h1 className="text-neutral-900 font-medium text-2xl">Zits</h1>
        <p className="text-neutral-700 leading-6 py-2">
          Zits are identified by small blobs on the surface of a print. They may
          be randomly distributed, or they may follow some sort of pattern.
        </p>
        <p className="text-neutral-700 leading-6 py-2">
          Zits usually indicate bad retraction settings, gcode stuttering, or
          connection issues when printing over USB.
        </p>
        <img className="w-full mt-4" height="auto" src={QVZits}></img>
      </>
    ),
    enabled: false,
  },
];

const Annotator = (): ReactElement => {
  // The available quiz images
  const [currentQuiz, setCurrentQuiz] =
    React.useState<QuizImagesResponse | null>(null);
  const [result, setResult] = React.useState<QuizSubmitResponse | undefined>();
  const [results, setResults] = React.useState<QuizSubmitResponse[]>([]);
  const [nextAvailableQuiz, setNextAvailableQuiz] = React.useState<
    QuizNextAvailableResponse | null | undefined
  >();
  const [timeTillNextQuiz, setTimeTillNextQuiz] = React.useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [resultModalActive, setResultModalActive] =
    React.useState<boolean>(false);
  const [summary, setSummary] = React.useState<
    QuizSummaryResponse | undefined
  >();
  const [quizState, setQuizState] = React.useState<QuizState>('none');
  const [chosenMode, setChosenMode] = React.useState<PrintleDifficulty>('easy');
  // The modal for new users which just became challengers
  const [welcomeModalOpen, setWelcomeModalOpen] =
    React.useState<boolean>(false);

  const { storeTokenCredentials, authenticate, authenticateGuest } = useAuth();
  const { getMarkettingSettings } = useMarketingAPI();
  const { auth } = useContext(AuthContext);
  const { modal, setModal } = useContext(ModalContext);
  const {
    getNewQuiz,
    getOngoingQuiz,
    submitQuizResponse,
    getExperienceStats,
    globalQuizExperience,
    setGlobalQuizExperience,
    getSummary,
    getSummaryGuest,
    completeQuiz,
    getNextAvailableQuiz,
  } = useQuiz();
  const { points, retrievePointsBackend, addPoints, setPoints } = usePoints();

  const updateGlobalStats = () => {
    getExperienceStats().then((exp) => {
      if ('errors' in exp) {
        return;
      }
      setGlobalQuizExperience(exp);
    });
  };

  const getTimeBetweenDates = (
    now: Date,
    future: Date,
  ): { days: number; hours: number; minutes: number; seconds: number } => {
    let seconds = Math.floor((future.getTime() - now.getTime()) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (nextAvailableQuiz) {
        const now = new Date();
        const availableAt = new Date(nextAvailableQuiz.availableAt);
        console.log(`availableAt=${availableAt.toISOString()}`);
        if (now > availableAt) {
          setTimeTillNextQuiz({
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          });
        } else {
          console.log(timeTillNextQuiz);
          setTimeTillNextQuiz(getTimeBetweenDates(now, availableAt));
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [nextAvailableQuiz]);

  // Called when this component is first rendered
  useEffect(() => {
    // Try to authenticate when we first load
    authenticate();

    const listener = (
      event: MessageEvent<{ provider: string; credentials: OAuthResponse }>,
    ) => {
      // We do not listen to messages from unknown origins
      if (event.origin !== window.location.origin) {
        return;
      }
      // Make sure the provider exists on this data
      if (!event.data.provider || !event.data.credentials) {
        return;
      }

      const { credentials } = event.data;
      // Store these credentials in local storage, then try to authenticate
      storeTokenCredentials(credentials);

      // Run the authentication
      authenticate();
    };

    // Set up the websocket connection for live events
    const socket = io(
      `${process.env.REACT_APP_API_WS_PROTOCOL}://${process.env.REACT_APP_API_URL}/printle`,
      {
        reconnectionDelayMax: 10000,
        path: '/ws',

        transports: ['websocket', 'polling'],
      },
    );

    socket.on('printle:experience', (stats: PrintleExperience) => {
      setGlobalQuizExperience({
        ...stats,
        userContribution: globalQuizExperience?.userContribution || 0,
      });
    });

    socket.on('connection', () => {
      console.log('live websocket started');
    });

    socket.on('disconnect', () => {
      console.log('disconnected');
    });

    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  const loadGame = async () => {
    if (!auth) {
      return;
    }
    // If the user is a guest let them choose a difficulty right away
    if (auth.guest) {
      return;
    }

    // If the user is authenticated we should see if the user has an ongoing quiz
    let onGoingQuiz: QuizImagesResponse | APIErrorResponse;
    try {
      onGoingQuiz = await getOngoingQuiz();
    } catch (err) {
      console.log(err);
      onGoingQuiz = { status: 0, errors: [] };
    }

    // Check if the user had a currently ongoing quiz, if they have one then put
    // them into the game right away
    if (!('errors' in onGoingQuiz)) {
      // Remove any inferences that have already been submitted
      const q: QuizImagesResponse = onGoingQuiz;
      q.inferences = q.inferences.filter(
        (s) => q.submitted.find((i) => i.inferenceId == s.id) === undefined,
      );

      setCurrentQuiz(q);
      setQuizState('playing');

      // Check if our quiz is empty
      if (q.inferences.length === 0) {
        // Complete this quiz for the user
        try {
          await completeQuiz(q.id);
          // Then get our summary and next available quiz
          const summary = await getSummary();
          if (!('errors' in summary)) {
            setQuizState('summary');
            setSummary(summary);
          } else {
            // We failed getting their summary, we can transition them into a
            // new game
            setQuizState('game_choice');
            return;
          }

          const nextAvailableQuiz = await getNextAvailableQuiz();
          if (!('errors' in nextAvailableQuiz)) {
            setNextAvailableQuiz(nextAvailableQuiz);
          } else {
            setNextAvailableQuiz(null);
          }
        } catch (err) {
          console.log(err);
        }
      }

      return;
    } else {
      // The user may have no ongoing quiz, but they might have a summary to
      // display
      // Complete this quiz for the user
      try {
        // Then get our summary and next available quiz
        const summary = await getSummary();
        if (!('errors' in summary)) {
          setQuizState('summary');
          setSummary(summary);
        } else {
          // We failed getting their summary, we can transition them into a
          // new game
          setQuizState('game_choice');
          return;
        }

        const nextAvailableQuiz = await getNextAvailableQuiz();
        if (!('errors' in nextAvailableQuiz)) {
          setNextAvailableQuiz(nextAvailableQuiz);
        } else {
          setNextAvailableQuiz(null);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (!auth) {
      setPoints(0);
      setQuizState('game_choice');
      setResults([]);
      setResult(undefined);
      setCurrentQuiz(null);
      setSummary(undefined);
    }

    if (auth) {
      // Check if we need to open the first-time account setup modal
      const marketingSettings = getMarkettingSettings();
      if (!auth.guest && marketingSettings.newUser) {
        setWelcomeModalOpen(true);
      }

      // Get the user's points only if they are authenticated
      if (!auth.guest) {
        retrievePointsBackend();
      }
      // Get the
      updateGlobalStats();

      loadGame();
    }
  }, [auth]);

  const onSignIn = () => {
    const OAuthURL = googleOAuthURLBuilder({
      clientID: process.env.REACT_APP_OAUTH_GOOGLE_CLIENT || '',
      accessType: 'offline',
      includeGrantedScopes: true,
      redirectURI: process.env.REACT_APP_OAUTH_GOOGLE_REDIRECT || '',
      responseType: 'code',
      scopes: [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
      ],
      state: uuidv4(),
    });
    window.location.replace(OAuthURL);
  };

  const submitQuiz = (activeInferenceId: string, inferences: Inference[]) => {
    if (!currentQuiz) {
      return;
    }

    submitQuizResponse(currentQuiz.id, activeInferenceId, inferences)
      .then((data) => {
        if ('errors' in data) {
          console.log('failed submitting quiz: ', data);
          return;
        }
        setResult(data);
        setResults((prev) => [...prev, data]);
        // Remove this quiz from the list of possible quizes
        const indexQuiz = currentQuiz.inferences
          .map((q) => q.id)
          .indexOf(activeInferenceId);
        const newQuizSet = currentQuiz;
        newQuizSet.inferences.splice(indexQuiz, 1);
        setCurrentQuiz(newQuizSet);
        addPoints(data.points > 0 ? data.points : 0);
        setResultModalActive(true);
        setModal(true);

        updateGlobalStats();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Got to the next quiz
  const onNextQuiz = async () => {
    console.log('fetching next quiz');
    if (!chosenMode) {
      setQuizState('game_choice');
      return;
    }

    try {
      if (currentQuiz) {
        await completeQuiz(currentQuiz.id);
      }

      const resp = await getNewQuiz(chosenMode);
      if ('errors' in resp) {
        console.log(resp);
        return;
      }

      // Remove any inferences that have already been submitted
      resp.inferences = resp.inferences.filter(
        (s) => resp.submitted.find((i) => i.inferenceId == s.id) === undefined,
      );

      setResult(undefined);
      setResults([]);
      setSummary(undefined);
      setTimeTillNextQuiz({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      setCurrentQuiz(resp);
      setQuizState('playing');
      console.log(
        `currentQuiz=${JSON.stringify(currentQuiz)} inferencesLength=${
          currentQuiz?.inferences.length
        } result=${result}`,
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if ('errors' in err) {
        console.log(err);
        return;
      }
    }
  };

  return (
    <div
      className={`w-full flex flex-col ${
        modal ? 'max-h-screen overflow-hidden' : 'min-h-screen'
      } bg-gray-50`}
    >
      <Helmet>
        <title>3DPrintle (QuinlyVision AI Game)</title>
        <meta
          name="description"
          content="Test your 3D printing skills and see how quickly QuinlyVision AI is learning!"
        />
        <meta name="og:title" content="QuinlyVision AI Demo" />
        <meta
          name="og:description"
          content="Test your 3D printing skills and see how quickly QuinlyVision AI is learning!"
        />
      </Helmet>
      {auth && (
        <PrintleNavbar points={points} experience={globalQuizExperience} />
      )}
      <div className="flex flex-row justify-center mt-4 self-stretch">
        <div className="flex flex-col mb-10 items-center">
          {currentQuiz && auth && quizState === 'playing' && (
            <AnnotatorCanvas
              categories={categories}
              currentQuiz={currentQuiz}
              onSubmitQuiz={submitQuiz}
              result={result}
              displayResult={resultModalActive}
              onCloseResultsView={() => {
                setResultModalActive(false);
                setModal(false);
                if (currentQuiz.inferences.length === 0) {
                  console.log('getting summary');
                  // Check if the user is authenticated, if they are mark their
                  // quiz as completed, if not we display locally stored stats
                  if (!auth.guest) {
                    completeQuiz(currentQuiz.id).then(() => {
                      getSummary()
                        .then((data) => {
                          if ('errors' in data) {
                            console.log(data);
                            return;
                          }
                          setQuizState('summary');
                          setSummary(data);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                      getNextAvailableQuiz()
                        .then((data) => {
                          if ('errors' in data) {
                            console.log(data);
                            setNextAvailableQuiz(null);
                            return;
                          }
                          console.log('updating next available quiz');
                          setNextAvailableQuiz(data);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    });
                  } else {
                    const points = results.reduce(
                      (prev, curr) => prev + curr.points,
                      0,
                    );

                    getSummaryGuest(points)
                      .then((data) => {
                        if ('errors' in data) {
                          console.log(data);
                          return;
                        }
                        setQuizState('summary');
                        setSummary(data);
                      })
                      .catch((err) => {
                        console.log(err);
                      });

                    getNextAvailableQuiz()
                      .then((data) => {
                        if ('errors' in data) {
                          console.log(data);
                          setNextAvailableQuiz(null);
                          return;
                        }
                        console.log('updating next available quiz');
                        setNextAvailableQuiz(data);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                }
              }}
              onOpenResultsView={() => {
                setModal(true);
                setResultModalActive(true);
              }}
              onClearResults={() => {
                setResult(undefined);
              }}
            />
          )}
          {quizState === 'game_choice' && !welcomeModalOpen && (
            <Popup modal closeOnDocumentClick={false} open={true}>
              <div
                className="bg-neutral-50 rounded-md max-w-4xl overflow-y-auto flex-col justify-center items-center mx-4"
                style={{ maxHeight: 'calc(100vh - 50px)' }}
              >
                <div className="bg-white rounded-lg">
                  <div className="w-full px-6 py-6 flex flex-col">
                    <h1 className="text-3xl font-semibold mb-1 text-center">
                      Play 3DPrintle
                    </h1>
                    <p className="text-lg text-center">
                      Test your skills against QuinlyVision AI
                    </p>
                    <div className="pt-2">
                      {!auth && (
                        <div className="grid grid-flow-row gap-2 mt-3">
                          <Popup
                            trigger={
                              <button
                                className="bg-gradient-to-r bg-white border hover:bg-neutral-100 transition-colors delay-75 font-semibold py-2 px-3 rounded-lg w-full lg:w-auto inline-flex items-center justify-center"
                                onClick={onSignIn}
                              >
                                <GoogleButton width={35} height={35} />
                                <p className="ml-2">Play As Challenger</p>
                              </button>
                            }
                            on={['hover']}
                            arrow
                          >
                            <div className="bg-white p-4 max-w-sm shadow-lg rounded-md border-2 border-neutral-100">
                              <p className="text-lg font-semibold">
                                Help us build a future where 3D printers can
                                avoid epic PrintFails, not deliver them!
                              </p>
                              <ul className="text-neutral-800 leading-7 list-outside ml-5 list-disc pt-2">
                                <li>
                                  Level up to get new features and try harder
                                  challenges.
                                </li>
                                <li>Get on the leader board!</li>
                                <li>
                                  Your score is added to the community average
                                  score.
                                </li>
                                <li>
                                  Your answers help train the AI - who will
                                  learn faster?
                                </li>
                                <li>
                                  Submit images and videos to challenge the AI.
                                </li>
                                <li>VIP invitations to special events</li>
                              </ul>
                            </div>
                          </Popup>
                          <Popup
                            trigger={
                              <button
                                className="bg-gradient-to-r bg-neutral-800 border text-neutral-50 hover:bg-neutral-700 transition-colors delay-75 font-semibold py-2 px-3 rounded-lg w-full lg:w-auto inline-flex items-center justify-center"
                                onClick={() => {
                                  authenticateGuest({
                                    id: '',
                                    permissions: [],
                                    userName: 'Guest User',
                                    avatar: GuestUserProfile,
                                    email: 'Guest User',
                                    v: '1',
                                  });
                                  onNextQuiz();
                                }}
                              >
                                <p
                                  className="ml-1 flex justify-center items-center"
                                  style={{ minHeight: 35 }}
                                >
                                  <span>Play As Guest</span>
                                </p>
                              </button>
                            }
                            on={['hover']}
                            arrow
                          >
                            <div className="bg-white p-4 max-w-sm shadow-lg rounded-md border-2 border-neutral-100">
                              <ul className="text-neutral-800 leading-7 list-outside ml-5 list-disc pt-2">
                                <li>
                                  No sign-in. Test your skills against AI! See
                                  how you do against the average player.
                                </li>
                                <li>
                                  We don’t keep guest data so your score count
                                  toward the community average and you won’t be
                                  helping train the AI.
                                </li>
                                <li>
                                  Want to help build a future where 3D printers
                                  can avoid epic PrintFails, not deliver them?{' '}
                                  <span
                                    className="text-blue-600 hover:text-blue-700 font-semibold cursor-pointer uppercase"
                                    onClick={onSignIn}
                                  >
                                    Become A Challenger
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </Popup>
                        </div>
                      )}
                      {auth && (
                        <button
                          className="bg-blue-600 hover:bg-blue-700 transition-colors delay-75 text-neutral-50 w-full py-3 rounded-lg mr-2 font-medium"
                          onClick={() => {
                            onNextQuiz();
                          }}
                        >
                          Play
                        </button>
                      )}
                    </div>
                    <h2 className="text-2xl font-medium mb-3 mt-4">
                      Choose A Game Mode
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                      <div
                        className={`w-full md:max-w-sm inline-flex items-start justify-start px-3 py-3 border-4 rounded-lg select-none cursor-pointer transition-colors delay-75 ${
                          chosenMode === 'easy'
                            ? 'border-blue-600'
                            : 'hover:border-neutral-100 border-transparent hover:bg-neutral-50'
                        }`}
                        onClick={() => {
                          setChosenMode('easy');
                        }}
                      >
                        <input
                          type="radio"
                          className="rounded text-blue-500 cursor-pointer mt-1 w-5 h-5"
                          checked={chosenMode === 'easy'}
                        />
                        <div className="ml-4">
                          <p className="font-medium">
                            Easy Mode: QuinlyVision Draws Boxes
                          </p>
                          <ul className="text-neutral-700 list-inside list-decimal">
                            <li>Click on a box.</li>
                            <li>Click proper PrintFail label.</li>
                            <li>Repeat until all boxes are labelled.</li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className={`w-full md:max-w-sm inline-flex items-start justify-start px-3 py-3 border-4 select-none cursor-pointer rounded-lg transition-colors delay-75 ${
                          chosenMode === 'hard'
                            ? 'border-blue-600'
                            : 'hover:border-neutral-100 border-transparent hover:bg-neutral-50'
                        }`}
                        onClick={() => {
                          setChosenMode('hard');
                        }}
                      >
                        <input
                          type="radio"
                          checked={chosenMode === 'hard'}
                          className="rounded text-blue-500 cursor-pointer mt-1 w-5 h-5"
                        />
                        <div className="ml-4">
                          <p className="font-medium">
                            Hard Mode: You Draw Boxes
                          </p>
                          <ul className="text-neutral-700 list-inside list-decimal">
                            <li>Draw a box around PrintFail only.</li>
                            <li>Click proper PrintFail label.</li>
                            <li>Repeat until all PrintFails are labelled.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 flex justify-center">
                      {chosenMode === 'easy' && (
                        <img
                          className="h-auto rounded-md w-full md:w-3/4"
                          src={TutorialEasy}
                        />
                      )}
                      {chosenMode === 'hard' && (
                        <img
                          className="h-auto rounded-md w-full md:w-3/4"
                          src={TutorialHard}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
          )}
          {welcomeModalOpen && auth && !auth.guest && (
            <NewPrintleUserModal
              hintEmail={auth.email}
              open={welcomeModalOpen}
              onClose={() => {
                setWelcomeModalOpen(false);
              }}
            />
          )}
          {quizState === 'summary' && auth && (
            <>
              <h1 className="text-5xl font-semibold my-2 text-center">
                3D Printle Complete
              </h1>
              <div className="">
                {nextAvailableQuiz &&
                  timeTillNextQuiz.hours +
                    timeTillNextQuiz.minutes +
                    timeTillNextQuiz.seconds >
                    0 && (
                    <>
                      <p className="text-neutral-600 font-semibold text-center">
                        Next Quiz Available In
                      </p>
                      <h2 className="text-3xl font-semibold mb-4 text-center">
                        {`${timeTillNextQuiz.days}d ${timeTillNextQuiz.hours}h ${timeTillNextQuiz.minutes}m ${timeTillNextQuiz.seconds}s`}
                      </h2>
                    </>
                  )}
                {nextAvailableQuiz &&
                  new Date().getTime() -
                    new Date(nextAvailableQuiz.availableAt).getTime() >
                    0 && (
                    <button
                      className="bg-neutral-900 py-2 mb-4 px-4 rounded-lg text-neutral-50 font-semibold"
                      onClick={() => {
                        setQuizState('game_choice');
                      }}
                    >
                      Play Past Quizzes
                    </button>
                  )}
                {!auth.guest && nextAvailableQuiz === null && (
                  <p className="text-neutral-500 font-medium mb-4">
                    You have played all of the past quizzes! Come back tomorrow.
                  </p>
                )}
                {auth.guest && nextAvailableQuiz === null && (
                  <p className="text-neutral-500 font-medium mb-4">
                    You finished today&apos;s quiz! Come back tomorrow.
                  </p>
                )}
              </div>
              <PrintleSummary
                summary={summary}
                submissions={results}
                categories={categories}
              />
              <FailVideos className="mt-16" categories={categories} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Annotator;
