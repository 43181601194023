import React, { ReactElement } from 'react';
import Popup from 'reactjs-popup';
import GradientProgressBar, {
  GradientProgressBarProps,
} from './GradientProgressBar';
import NumericBubble from './NumericBubble';
import { ReactComponent as QuestionIcon } from '../icons/question.svg';

interface FailureTypeProgress {
  progressBarProps: GradientProgressBarProps;
  failureType: string;
  failureDescription: string;
  failureTitleColor: string;
  index: string;
}

const FailureTypeProgress = (props: FailureTypeProgress): ReactElement => {
  const {
    failureType,
    failureTitleColor,
    failureDescription,
    progressBarProps,
    index,
  } = props;
  const { progress } = progressBarProps;

  const roundedProgress = progress == null ? 0 : Math.round(progress * 100);

  return (
    <div className="w-full py-2">
      <div className="flex justify-between">
        <div className="inline-flex items-center">
          <NumericBubble
            bubbleSize="5"
            color={failureTitleColor}
            text={index}
            textSize="xs"
          />
          <p
            className={`text-sm text-${failureTitleColor} font-semibold capitalize pt-1 pl-2`}
          >
            {failureType}
          </p>
          <Popup
            trigger={
              <QuestionIcon
                className={`text-${failureTitleColor} inline-block pl-1 flex-none`}
                width={20}
                height={20}
                strokeWidth={2}
                opacity={0.8}
              />
            }
            position={['left bottom', 'bottom center']}
            on={['hover']}
            arrow={false}
          >
            <div className="bg-neutral-100 border bg-opacity-95 border-neutral-600 rounded-lg p-2 mr-2">
              <p className="text-neutral-800 text-sm">{failureDescription}</p>
            </div>
          </Popup>
        </div>
        <div>
          <p
            className={`text-sm text-${failureTitleColor} font-semibold pt-1`}
          >{`${roundedProgress}%`}</p>
        </div>
      </div>
      <div className="pt-1">
        <Popup
          trigger={
            <div>
              <GradientProgressBar {...progressBarProps} />
            </div>
          }
          position={['left bottom', 'bottom center']}
          on={['hover']}
          arrow={false}
        >
          <div className="bg-neutral-100 border bg-opacity-95 border-neutral-700 rounded-lg text-neutral-800 p-2 mr-2">
            <p className="text-neutral-800 text-sm">
              We are{' '}
              <span className={`text-${failureTitleColor} font-semibold`}>
                {roundedProgress}%
              </span>{' '}
              confident this print has {failureType}
            </p>
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default FailureTypeProgress;
