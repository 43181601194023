interface GoogleOAuthBuilderOptions {
  scopes: string[];
  accessType: string;
  includeGrantedScopes: boolean;
  responseType: string;
  state: string;
  redirectURI: string;
  clientID: string;
}

// Builds an authentication URL for the Google OAuth provider
const googleOAuthURLBuilder = (options: GoogleOAuthBuilderOptions): string => {
  const {
    scopes,
    accessType,
    includeGrantedScopes,
    responseType,
    state,
    redirectURI,
    clientID,
  } = options;
  const URL = `https://accounts.google.com/o/oauth2/v2/auth?scope=${encodeURIComponent(
    scopes.join(' '),
  )}&access_type=${encodeURIComponent(
    accessType,
  )}&include_granted_scopes=${encodeURIComponent(
    includeGrantedScopes,
  )}&response_type=${encodeURIComponent(
    responseType,
  )}&state=${encodeURIComponent(state)}&redirect_uri=${encodeURIComponent(
    redirectURI,
  )}&client_id=${clientID}&prompt=consent`;
  return URL;
};

export default googleOAuthURLBuilder;
