import React, { ReactElement, useContext } from 'react';
import { QuizSubmitResponse, QuizSummaryResponse } from 'hooks/api/useQuiz';
import { ReactComponent as DoneIcon } from 'icons/done.svg';
import { ReactComponent as CopyIcon } from 'icons/copy.svg';
import Popup from 'reactjs-popup';
import { ClassificationCategory } from 'common/APITypes';
import EmailSubscribeModal from './modals/EmailSubscribeModal';
import { AuthContext } from 'providers/AuthContext';
import googleOAuthURLBuilder from 'util/OAuth';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as GoogleButton } from 'icons/icons8-google.svg';
import {
  getFullPrintleSummaryText,
  getSimplePrintleSummaryText,
} from 'common/Clipboard';
import QuizSubmitModal from './quiz/QuizSubmitModal';

export interface PrintleSummaryProps {
  summary?: QuizSummaryResponse;
  submissions?: QuizSubmitResponse[];
  categories: ClassificationCategory[];
}

export const PrintleSummary = (props: PrintleSummaryProps): ReactElement => {
  const { summary, categories, submissions } = props;
  const { auth } = useContext(AuthContext);
  const [recapId, setRecapId] = React.useState<string | undefined>(undefined);

  const [copyResults, setCopyResults] = React.useState<boolean>(false);

  const onSignIn = () => {
    const OAuthURL = googleOAuthURLBuilder({
      clientID: process.env.REACT_APP_OAUTH_GOOGLE_CLIENT || '',
      accessType: 'offline',
      includeGrantedScopes: true,
      redirectURI: process.env.REACT_APP_OAUTH_GOOGLE_REDIRECT || '',
      responseType: 'code',
      scopes: [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
      ],
      state: uuidv4(),
    });
    window.location.replace(OAuthURL);
  };

  const shareScore = () => {
    const emojis: string[] = [];
    let points = 0;
    let maxPoints = 0;

    if (summary) {
      summary.submissions.forEach((s) => {
        points += s.points;
        maxPoints += s.maxPoints;
        const percentScore = s.points / s.maxPoints;
        if (percentScore > 0.75) {
          emojis.push('🟩');
        } else if (percentScore > 0.5) {
          emojis.push('🟨');
        } else {
          emojis.push('⬛');
        }
      });
    } else if (submissions) {
      submissions.forEach((s) => {
        points += s.points;
        maxPoints += s.maxPoints;
        const percentScore = s.points / s.maxPoints;
        if (percentScore > 0.75) {
          emojis.push('🟩');
        } else if (percentScore > 0.5) {
          emojis.push('🟨');
        } else {
          emojis.push('⬛');
        }
      });
    }

    if (summary) {
      navigator.clipboard.writeText(
        getFullPrintleSummaryText(
          points,
          maxPoints,
          summary.percentile,
          emojis,
        ),
      );
    } else if (submissions) {
      navigator.clipboard.writeText(
        getSimplePrintleSummaryText(points, maxPoints, emojis),
      );
    }

    setCopyResults(true);
    setTimeout(() => {
      setCopyResults(false);
    }, 4000);
  };

  return (
    <div className="flex flex-col justify-start w-full bg-white px-6 py-8 rounded-lg">
      {recapId &&
        (() => {
          // Find it in the submission
          const submission = submissions?.find((s) => s.quiz.id === recapId);
          if (submission) {
            return (
              <QuizSubmitModal
                categories={categories}
                height={submission.quiz.height}
                width={submission.quiz.width}
                img={submission.quiz.image}
                isOpen={recapId !== undefined}
                points={submission.points}
                maxPoints={submission.maxPoints}
                onClose={() => {
                  setRecapId(undefined);
                }}
                onContinue={() => {
                  setRecapId(undefined);
                }}
                test={submission.submitted}
                truth={submission.expected}
                hasNext={false}
              />
            );
          }

          // Find it in the summary
          const summarySubmission = summary?.submissions.find(
            (s) => s.inferenceId === recapId,
          );
          if (summary && summarySubmission) {
            return (
              <QuizSubmitModal
                categories={categories}
                height={summarySubmission.height}
                width={summarySubmission.width}
                img={summarySubmission.image}
                isOpen={recapId !== undefined}
                points={summarySubmission.points}
                maxPoints={summarySubmission.maxPoints}
                onClose={() => {
                  setRecapId(undefined);
                }}
                onContinue={() => {
                  setRecapId(undefined);
                }}
                test={summarySubmission.inferences}
                truth={summarySubmission.expected}
                hasNext={false}
              />
            );
          }
        })()}
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-neutral-900 font-semibold text-2xl">You Did It!</h2>
      </div>
      <div className="w-full grid grid-flow-row md:grid-flow-col gap-4">
        <EmailSubscribeModal
          triggerButton={
            <button className="bg-neutral-900 hover:bg-neutral-700 transition-colors delay-75 text-neutral-50 font-medium rounded-lg px-2 py-2">
              Learn how to get QuinlyVision for your 3D printer
            </button>
          }
          hintEmail={auth?.guest ? '' : auth?.email}
        />
      </div>
      {auth?.guest && (
        <div className="w-full bg-blue-600 rounded-lg mt-2 flex flex-col justify-center items-center p-5">
          <p className="pb-2 text-2xl font-semibold text-neutral-50 text-center">
            Keep Your Points & See How You Compare Against Others
          </p>
          <button
            className="bg-gradient-to-r bg-white border hover:bg-neutral-100 transition-colors delay-75 font-semibold py-2 px-3 rounded-lg mt-4 w-full lg:w-auto inline-flex items-center justify-center"
            onClick={onSignIn}
          >
            <GoogleButton width={35} height={35} />
            <p className="ml-1">Play As Challenger</p>
          </button>
        </div>
      )}
      <div className="w-full grid grid-cols-1 md:grid-cols-3 my-10 gap-4">
        <div className="text-center">
          <p className="text-lg">Your Score!</p>
          <p className="text-3xl font-bold">
            {!auth?.guest &&
              summary &&
              summary.submissions
                .map((s) => s.points)
                .reduce((acc, curr) => acc + curr)}
            {auth?.guest &&
              submissions &&
              submissions
                .map((s) => s.points)
                .reduce((acc, curr) => acc + curr)}
          </p>
          <Popup
            trigger={
              <button className="bg-blue-600 hover:bg-blue-700 transition-colors delay-75 text-neutral-50 font-medium rounded-lg px-2 py-2">
                Share Your Score
              </button>
            }
            modal
            closeOnDocumentClick
          >
            <div className="bg-neutral-100 rounded-md px-8 py-8 mx-4 max-w-2xl">
              <div className="absolute top-3 right-7 z-10">
                <button
                  className="text-sm font-semibold justify-center"
                  onClick={shareScore}
                >
                  {!copyResults && (
                    <div className="text-blue-500 inline-flex items-center">
                      <span>
                        <CopyIcon width={20} height={20} />
                      </span>
                      <p className="pt-1 ml-1">Copy Score</p>
                    </div>
                  )}
                  {copyResults && (
                    <div className="text-green-700 inline-flex items-center">
                      <span>
                        <DoneIcon width={20} height={20} strokeWidth={2} />
                      </span>
                      <p className="pt-1 ml-1">Copied</p>
                    </div>
                  )}
                </button>
              </div>
              <p className="text-neutral-700">
                3DPrintle -- {new Date().toDateString()}
              </p>
              {!auth?.guest && summary && (
                <p className="inline-block">
                  {summary.submissions.map((s) => {
                    const percentScore = s.points / s.maxPoints;
                    let elem;
                    if (percentScore > 0.75) {
                      elem = (
                        <span className={`cursor-pointer`}>&#129001;</span>
                      );
                    } else if (percentScore > 0.5) {
                      elem = (
                        <span className={`cursor-pointer`}>&#x1F7E8;</span>
                      );
                    } else {
                      elem = <span className={`cursor-pointer`}>&#x2B1B;</span>;
                    }
                    return elem;
                  })}
                </p>
              )}
              {auth?.guest && submissions && (
                <p className="inline-block">
                  {submissions.map((s) => {
                    const percentScore = s.points / s.maxPoints;
                    let elem;
                    if (percentScore > 0.75) {
                      elem = (
                        <span className={`cursor-pointer`}>&#129001;</span>
                      );
                    } else if (percentScore > 0.5) {
                      elem = (
                        <span className={`cursor-pointer`}>&#x1F7E8;</span>
                      );
                    } else {
                      elem = <span className={`cursor-pointer`}>&#x2B1B;</span>;
                    }
                    return elem;
                  })}
                </p>
              )}
              <div className="bg-neutral-100 rounded-md relative">
                {!auth?.guest && summary && (
                  <p className="text-neutral-700">
                    Points{' '}
                    {summary.submissions
                      .map((s) => s.points)
                      .reduce((acc, curr) => acc + curr)}{' '}
                    /{' '}
                    {summary.submissions
                      .map((s) => s.maxPoints)
                      .reduce((acc, curr) => acc + curr)}{' '}
                    (Better than{' '}
                    {Math.round(summary.percentile * 100 * 10) / 10}% of
                    players)
                  </p>
                )}
                {auth?.guest && submissions && summary && (
                  <p className="text-neutral-700">
                    Points{' '}
                    {submissions
                      .map((s) => s.points)
                      .reduce((acc, curr) => acc + curr)}{' '}
                    /{' '}
                    {submissions
                      .map((s) => s.maxPoints)
                      .reduce((acc, curr) => acc + curr)}{' '}
                    (Better than{' '}
                    {Math.round(summary.percentile * 100 * 10) / 10}% of
                    players)
                  </p>
                )}
              </div>
            </div>
          </Popup>
        </div>
        <div className="text-center">
          <p className="text-lg">QuinlyVision AI Score</p>
          <p className="text-3xl font-bold">
            {!auth?.guest &&
              summary &&
              summary.submissions
                .map((s) => s.maxPoints)
                .reduce((acc, curr) => acc + curr)}
            {auth?.guest &&
              submissions &&
              submissions
                .map((s) => s.maxPoints)
                .reduce((acc, curr) => acc + curr)}
          </p>
        </div>
        {summary && (
          <div className="text-center">
            <p className="text-lg">Community Average Score</p>
            <p className="text-3xl font-bold">{summary.averageScore}</p>
          </div>
        )}
      </div>
      <h2 className="text-neutral-900 font-semibold text-2xl">Recap</h2>
      <p className="text-neutral-800 pt-1 pb-1">
        Click a box to see how you did on each challenge.
      </p>
      <div className="text-left px-8 bg-neutral-100 py-4 rounded-lg relative mt-3">
        <div className="absolute top-3 right-7 z-10">
          <button
            className="text-sm font-semibold justify-center"
            onClick={shareScore}
          >
            {!copyResults && (
              <div className="text-blue-500 inline-flex items-center">
                <span>
                  <CopyIcon width={20} height={20} />
                </span>
                <p className="pt-1 ml-1">Copy Score</p>
              </div>
            )}
            {copyResults && (
              <div className="text-green-700 inline-flex items-center">
                <span>
                  <DoneIcon width={20} height={20} strokeWidth={2} />
                </span>
                <p className="pt-1 ml-1">Copied</p>
              </div>
            )}
          </button>
        </div>
        {!auth?.guest && summary && (
          <p className="inline-block">
            {summary.submissions.map((s) => {
              const percentScore = s.points / s.maxPoints;
              let elem;
              if (percentScore > 0.75) {
                elem = (
                  <span
                    className={`cursor-pointer text-2xl`}
                    onClick={() => {
                      console.log('setting');
                      setRecapId(s.inferenceId);
                    }}
                  >
                    &#129001;
                  </span>
                );
              } else if (percentScore > 0.5) {
                elem = (
                  <span
                    className={`cursor-pointer text-2xl`}
                    onClick={() => {
                      console.log('setting');
                      setRecapId(s.inferenceId);
                    }}
                  >
                    &#x1F7E8;
                  </span>
                );
              } else {
                elem = (
                  <span
                    className={`cursor-pointer text-2xl`}
                    onClick={() => {
                      console.log('setting');
                      setRecapId(s.inferenceId);
                    }}
                  >
                    &#x2B1B;
                  </span>
                );
              }
              return elem;
            })}
          </p>
        )}
        {auth?.guest && submissions && (
          <p className="inline-block">
            {submissions.map((s) => {
              const percentScore = s.points / s.maxPoints;
              let elem;
              if (percentScore > 0.75) {
                elem = (
                  <span
                    className={`cursor-pointer text-2xl`}
                    onClick={() => {
                      setRecapId(s.quiz.id);
                    }}
                  >
                    &#129001;
                  </span>
                );
              } else if (percentScore > 0.5) {
                elem = (
                  <span
                    className={`cursor-pointer text-2xl`}
                    onClick={() => {
                      setRecapId(s.quiz.id);
                    }}
                  >
                    &#x1F7E8;
                  </span>
                );
              } else {
                elem = (
                  <span
                    className={`cursor-pointer text-2xl`}
                    onClick={() => {
                      setRecapId(s.quiz.id);
                    }}
                  >
                    &#x2B1B;
                  </span>
                );
              }
              return elem;
            })}
          </p>
        )}
      </div>
      {summary && (
        <div>
          <p className="pt-4 pb-2 text-lg font-semibold text-neutral-900">
            Your Stats
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gradient-to-br from-blue-500 to-blue-600 p-4 rounded-md">
              <p className="uppercase font-semibold text-xs tracking-widest  text-neutral-100">
                You Did Better Than
              </p>
              <p className="text-neutral-50 font-semibold text-2xl">
                {Math.round(summary.percentile * 100)}% of players
              </p>
            </div>

            <div className="bg-gradient-to-br from-purple-500 to-purple-600 p-4 rounded-md">
              <p className="uppercase font-semibold text-xs tracking-widest text-neutral-100">
                Average Points Per Fix
              </p>
              <p className="text-neutral-50 font-semibold text-2xl">
                {!auth?.guest &&
                  Math.round(
                    summary.submissions
                      .map((s) => s.points)
                      .reduce((prev, current) => prev + current, 0) /
                      summary.submissions.length,
                  )}
                {auth?.guest &&
                  submissions &&
                  Math.round(
                    submissions
                      .map((s) => s.points)
                      .reduce((prev, current) => prev + current, 0) /
                      submissions.length,
                  )}
              </p>
            </div>
            <div className="bg-gradient-to-br from-green-500 to-green-600 p-4 rounded-md">
              <p className="uppercase font-semibold text-xs tracking-widest text-neutral-100">
                Number Of Fixed Prints
              </p>
              <p className="text-neutral-50 font-semibold text-2xl">
                {!auth?.guest && summary.submissions.length}
                {submissions && auth?.guest && submissions.length}
              </p>
            </div>
            <div className="bg-gradient-to-br from-pink-400 to-pink-500 p-4 rounded-md">
              <p className="uppercase font-semibold text-xs tracking-widest text-neutral-100">
                Most Frequently Identified Failure
              </p>
              <p className="text-neutral-50 font-semibold text-2xl">
                {!auth?.guest &&
                  summary &&
                  (() => {
                    const fcMap = new Map<
                      string,
                      { class: string; used: number }
                    >();
                    summary.submissions.forEach((s) => {
                      s.inferences.forEach((i) => {
                        const val = fcMap.get(i.className);
                        if (val) {
                          fcMap.set(i.className, {
                            class: i.className,
                            used: val.used + 1,
                          });
                        } else {
                          fcMap.set(i.className, {
                            class: i.className,
                            used: 1,
                          });
                        }
                      });
                    });

                    const usedClasses = Array.from(fcMap.values()).sort(
                      (a, b) => {
                        if (a.used < b.used) {
                          return -1;
                        } else if (a.used > b.used) {
                          return 1;
                        } else {
                          return 0;
                        }
                      },
                    );

                    return categories.find(
                      (c) =>
                        c.name === usedClasses[usedClasses.length - 1].class,
                    )?.displayName;
                  })()}
                {auth?.guest &&
                  submissions &&
                  (() => {
                    const fcMap = new Map<
                      string,
                      { class: string; used: number }
                    >();
                    submissions.forEach((s) => {
                      s.submitted.forEach((i) => {
                        const val = fcMap.get(i.className);
                        if (val) {
                          fcMap.set(i.className, {
                            class: i.className,
                            used: val.used + 1,
                          });
                        } else {
                          fcMap.set(i.className, {
                            class: i.className,
                            used: 1,
                          });
                        }
                      });
                    });

                    const usedClasses = Array.from(fcMap.values()).sort(
                      (a, b) => {
                        if (a.used < b.used) {
                          return -1;
                        } else if (a.used > b.used) {
                          return 1;
                        } else {
                          return 0;
                        }
                      },
                    );

                    return categories.find(
                      (c) =>
                        c.name === usedClasses[usedClasses.length - 1].class,
                    )?.displayName;
                  })()}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
