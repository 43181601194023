import {
  QuizImageSnapshot,
  QuizImagesResponse,
  QuizSubmitResponse,
} from 'hooks/api/useQuiz';
import React, { ReactElement, useCallback, useContext, useEffect } from 'react';
import { ReactComponent as PointerIcon } from 'icons/mouse-pointer.svg';
import { ReactComponent as SkipForward } from 'icons/skip-forward.svg';
import { ReactComponent as AwardIcon } from 'icons/award.svg';
import { ReactComponent as QuestionIcon } from 'icons/question.svg';
import { ReactComponent as ScanOutlineIcon } from 'icons/scan-outline.svg';
import { ReactComponent as TrashIcon } from 'icons/trash.svg';
import { ReactComponent as RestartIcon } from 'icons/refresh-outline.svg';
import { ReactComponent as NextIcon } from 'icons/arrow-forward-outline.svg';
import { ReactComponent as StatsIcon } from 'icons/stats-chart-outline.svg';
import { ReactComponent as DoneIcon } from 'icons/done.svg';
import CursorBox from 'icons/cursor-box.svg';
import QVPoints1 from 'images/QV_Points_1.png';
import QVPoints2 from 'images/QV_Points_2.png';
import QVPoints3 from 'images/QV_Points_3.png';
import QVPoints4 from 'images/QV_Points_4.png';
import QVPoints5 from 'images/QV_Points_5.png';
import {
  Group,
  Image,
  Label,
  Layer,
  Rect,
  Stage,
  Tag,
  Text,
  Transformer,
} from 'react-konva';
import { Rect as RectType } from 'konva/lib/shapes/Rect';
import { Transformer as TransformerType } from 'konva/lib/shapes/Transformer';
import { Annotation, ClassificationCategory, Inference } from 'common/APITypes';
import { KonvaEventObject } from 'konva/lib/Node';
import { v4 as uuidv4 } from 'uuid';
import CategoryButton from './CategoryButton';
import QuizSubmitModal from './quiz/QuizSubmitModal';
import Popup from 'reactjs-popup';
import { useResizeDetector } from 'react-resize-detector';
import { Rectangle, Vector2D } from 'common/Geometry';
import { ModalContext } from 'providers/ModalContext';
import { UpdateCategoryModal } from './modals/UpdateCategoryModal';

type AnnotationTool = 'select' | 'box';

export interface AnnotatorCanvasProps {
  currentQuiz: QuizImagesResponse;
  categories: ClassificationCategory[];
  result?: QuizSubmitResponse;
  displayResult: boolean;
  onSubmitQuiz: (activeInferenceId: string, inferences: Inference[]) => void;
  onCloseResultsView: () => void;
  onOpenResultsView: () => void;
  onClearResults: () => void;
}

const projectAnnotation = (
  a: Annotation,
  from: Vector2D,
  to: Vector2D,
): Annotation => {
  const adjustedX = (a.x / from.x) * to.x;
  const adjustedY = (a.y / from.y) * to.y;
  const adjustedWidth = (a.width / from.x) * to.x;
  const adjustedHeight = (a.height / from.y) * to.y;
  return {
    id: a.id,
    className: a.className,
    width: adjustedWidth,
    height: adjustedHeight,
    x: adjustedX,
    y: adjustedY,
  };
};

const inferencesToAnnotation = (inferences: Inference[]): Annotation[] => {
  const annotations = inferences.map((b) => {
    const annotation = {
      className: b.className === '' ? 'No Failure' : b.className,
      id: uuidv4(),
      x: b.xmin,
      y: b.ymin,
      width: Math.round(b.xmax - b.xmin),
      height: Math.round(b.ymax - b.ymin),
    };
    return annotation;
  });
  return annotations;
};

const getSpaceToBounds = (
  position: Vector2D,
  canvas: Vector2D,
): {
  spaceTop: number;
  spaceLeft: number;
  spaceRight: number;
  spaceBottom: number;
} => {
  return {
    spaceTop: position.y,
    spaceLeft: position.x,
    spaceBottom: canvas.y - position.y,
    spaceRight: canvas.x - position.x,
  };
};

const fitBox = (
  position: Vector2D,
  dimensions: Vector2D,
  canvas: Vector2D,
): Rectangle | null => {
  const dimensionXHalf = dimensions.x / 2;
  const dimensionYHalf = dimensions.y / 2;
  const { spaceBottom, spaceTop, spaceLeft, spaceRight } = getSpaceToBounds(
    position,
    canvas,
  );

  // Check if you can place it in either the center of the click
  if (
    spaceLeft > dimensionXHalf &&
    spaceRight > dimensionXHalf &&
    spaceTop > dimensionYHalf &&
    spaceBottom > dimensionYHalf
  ) {
    const placeX = position.x - dimensionXHalf;
    const placeY = position.y - dimensionYHalf;

    return {
      xmin: placeX,
      ymin: placeY,
      xmax: placeX + dimensions.x,
      ymax: placeY + dimensions.y,
    };
  }

  // Check if we can use this position as the top left corner of the box
  if (spaceRight > dimensions.x && spaceBottom > dimensions.y) {
    return {
      xmin: position.x,
      ymin: position.y,
      xmax: position.x + dimensions.x,
      ymax: position.y + dimensions.y,
    };
  }

  // Set the position to the bottom left corner, and check if the box fits
  if (spaceTop > dimensions.y && spaceRight > dimensions.x) {
    return {
      xmin: position.x,
      ymin: position.y - dimensions.y,
      xmax: position.x + dimensions.x,
      ymax: position.y,
    };
  }

  // Set the position to the bottom left corner, and check if the box fits
  if (spaceLeft > dimensions.x && spaceBottom > dimensions.y) {
    return {
      xmin: position.x - dimensions.x,
      ymin: position.y,
      xmax: position.x,
      ymax: position.y,
    };
  }

  // Set the position to the bottom left corner, and check if the box fits
  if (spaceLeft > dimensions.x && spaceTop > dimensions.y) {
    return {
      xmin: position.x - dimensions.x,
      ymin: position.y - dimensions.y,
      xmax: position.x,
      ymax: position.y,
    };
  }

  return null;
};

export const AnnotatorCanvas = (props: AnnotatorCanvasProps): ReactElement => {
  const {
    categories,
    currentQuiz,
    onSubmitQuiz,
    result,
    displayResult,
    onCloseResultsView,
    onOpenResultsView,
    onClearResults,
  } = props;

  // All of the annotations the user has made
  const [annotations, setAnnotations] = React.useState<Annotation[]>([]);
  // The annotation the user is currently drawing on the screen
  const [activeAnnotation, setActiveAnnotation] =
    React.useState<Annotation | null>(null);
  // The id of the active inference
  const [activeInferenceId, setActiveInferenceId] = React.useState<
    string | null
  >(null);
  // The currently selected tool (by default we assume the select tool)
  const [tool, setTool] = React.useState<AnnotationTool>('select');
  // The selected classifications category
  const [classCategory, setClassCategory] = React.useState<string>(
    categories[0].name,
  );
  // The currently selected shape in select mode
  const [selectedAnnotation, setSelectedAnnotation] = React.useState<
    string | null
  >(null);
  // Confirmation dialog for updating a category
  const [updateClassModal, setUpdateClassModal] = React.useState<
    | { id: string; from: ClassificationCategory; to: ClassificationCategory }
    | undefined
  >();

  const [activeRef, setActiveRef] = React.useState<RectType | null>(null);
  const transformerRef = React.useRef<TransformerType | null>(null);
  const background = React.useRef<HTMLImageElement | undefined>();

  const { setModal } = useContext(ModalContext);

  const [canvasDimensions, setCanvasDimensions] = React.useState<{
    width: number;
    height: number;
  }>({
    width: 640,
    height: 480,
  });

  // The main container holding the canvas
  const {
    width: canvasContainerWidth,
    height: canvasContainerHeight,
    ref: canvasContainerRef,
  } = useResizeDetector();

  const checkKeyPress = useCallback(
    (e) => {
      const { key } = e;
      if (key === 'Delete') {
        removeSelectedAnnotation();
      }
    },
    [selectedAnnotation],
  );

  useEffect(() => {
    // When the quiz changes, we need to make sure to reset state
    setActiveAnnotation(null);
    setActiveQuiz(currentQuiz.inferences[0]);
    setAnnotations(inferencesToAnnotation(currentQuiz.inferences[0].base));
    setSelectedAnnotation(null);

    if (currentQuiz.mode === 'easy') {
      setTool('select');
    } else if (currentQuiz.mode === 'hard') {
      setTool('box');
    } else {
      setTool('box');
    }
  }, [currentQuiz]);

  useEffect(() => {
    if (canvasContainerWidth === canvasDimensions.width) {
      return;
    }

    /*console.log(
      `canvasContainerWidth=${canvasContainerWidth} canvasContainerHeight=${canvasContainerHeight}`,
    );
    console.log(
      `canvasWidth=${canvasDimensions.width} canvasHeight=${canvasDimensions.height}`,
    );*/

    const activeInference = currentQuiz.inferences.find(
      (i) => i.id === activeInferenceId,
    );

    /*console.log(
      `dimensions changed ... width=${canvasContainerWidth} height=${canvasContainerHeight} inference=${activeInference}`,
    );*/

    // If the canvas container width ever changes we should resize our boxes and
    // place them in the right position
    if (
      canvasContainerWidth &&
      canvasContainerHeight &&
      (activeInference || result)
    ) {
      const inferenceWidth = activeInference?.width || result?.quiz.width;
      const inferenceHeight = activeInference?.height || result?.quiz.height;
      if (!inferenceWidth || !inferenceHeight) {
        return;
      }

      const inverseCanvasAspectRatio = inferenceHeight / inferenceWidth;
      const adjustedContainerHeight =
        canvasContainerWidth * inverseCanvasAspectRatio;

      // Update the annotations
      const adjustedAnnotations: Annotation[] = [];
      annotations.forEach((a) => {
        adjustedAnnotations.push(
          projectAnnotation(
            a,
            { x: canvasDimensions.width, y: canvasDimensions.height },
            { x: canvasContainerWidth, y: adjustedContainerHeight },
          ),
        );
      });

      // console.log(adjustedAnnotations);

      // Set the new canvas dimension
      setCanvasDimensions({
        width: canvasContainerWidth,
        height: adjustedContainerHeight,
      });
      // Update the annotations
      setAnnotations(adjustedAnnotations);
    }
  }, [activeInferenceId, canvasContainerWidth]);

  /*console.log(
    `containerWidth=${canvasContainerWidth} canvasWidth=${canvasDimensions.width}`,
  );*/

  useEffect(() => {
    window.addEventListener('keydown', checkKeyPress);
    return () => {
      window.removeEventListener('keydown', checkKeyPress);
    };
  }, [checkKeyPress]);

  if (transformerRef !== null && activeRef !== null) {
    // Update the transformer with the new selected type
    transformerRef.current?.nodes([activeRef]);
    transformerRef.current?.getLayer()?.batchDraw();
  }

  // All the annotations we will draw to the canvas
  const drawableAnnotations = [...annotations];
  if (activeAnnotation) {
    drawableAnnotations.push(activeAnnotation);
  }

  // Can the user perform the dragging action
  const canDragAnnotation = (id: string): boolean => {
    return tool === 'select' && selectedAnnotation === id;
  };

  // Handles the select of the box tool for drawing annotations
  const handleToolSelectBox = () => {
    if (tool === 'box') {
      return;
    }
    if (tool === 'select') {
      setSelectedAnnotation(null);
    }
    setTool('box');
  };

  const handleSelectBox = () => {
    setTool('select');
  };

  const handleSelectCategory = (id: string) => {
    // If there is a currently selected annotation we should ask the user to if
    // they would like to change classes
    if (selectedAnnotation) {
      const annotation = annotations.find((a) => a.id === selectedAnnotation);
      const from = categories.find((c) => c.name === annotation?.className);
      const to = categories.find((c) => c.name === id);
      if (to && annotation && from && from.name !== to.name) {
        setUpdateClassModal({
          id: annotation.id,
          from,
          to,
        });
      } else {
        const toChange = annotations.find((a) => a.id === selectedAnnotation);
        if (!toChange) {
          return;
        }
        toChange.className = id;

        const updated = annotations.filter((a) => a.id !== selectedAnnotation);
        updated.push(toChange);
        setAnnotations(updated);
      }
    }
    setClassCategory(id);
  };

  // Handle the user pressing the mouse button down on the canvas
  const handleMouseDown = (ev: KonvaEventObject<MouseEvent | TouchEvent>) => {
    // Make sure you can only create a new annotation if one isn't currently
    // being created.
    if (tool === 'box' && !activeAnnotation) {
      const position = ev.target.getStage()?.getRelativePointerPosition();
      setActiveAnnotation({
        x: position?.x || 0,
        y: position?.y || 0,
        width: 0,
        height: 0,
        id: uuidv4(),
        className: classCategory,
      });
      return;
    }

    // We're in selection mode. We need to determine if we're clicking on an annotation to change it
    if (tool === 'select') {
      const clickedId = ev.target.attrs.id;
      // If we clicked on empty, we should remove the current annotation
      const clickedOnEmpty = clickedId === 'background';
      if (clickedOnEmpty) {
        if (currentQuiz.mode === 'hard') {
          setTool('box');
        }
        setSelectedAnnotation(null);
      }
      // If we're clicking on an annotation transition directly
      if (annotations.map((a) => a.id).includes(clickedId)) {
        onShapeSelect(clickedId, ev);
      }
      return;
    }
  };

  // Handle the user letting go of the mouse button
  const handleMouseUp = (ev: KonvaEventObject<MouseEvent | TouchEvent>) => {
    // Make sure we finalize an annotation if it currently being created
    if (tool === 'box' && activeAnnotation) {
      const position = ev.target.getStage()?.getRelativePointerPosition();
      // The current positions
      const prevX = activeAnnotation.x;
      const prevY = activeAnnotation.y;
      // The positions when the mouse was lifted up
      const x = position?.x || 0;
      const y = position?.y || 0;
      // The width and height
      const width = x - prevX;
      const height = y - prevY;

      if (Math.abs(width) < 5 || Math.abs(height) < 5) {
        // Try to fit a default box into this current position
        const fit = fitBox(
          position || { x: 0, y: 0 },
          { x: 100, y: 100 },
          {
            x: canvasDimensions.width,
            y: canvasDimensions.height,
          },
        );
        if (!fit) {
          // Couldn't fit the box to a position
          setActiveAnnotation(null);
          return;
        }

        const { xmin, ymin } = fit;
        const defaultAnnotation: Annotation = {
          className: activeAnnotation.className,
          id: activeAnnotation.id,
          x: xmin,
          y: ymin,
          width: 100,
          height: 100,
        };
        setActiveAnnotation(null);
        setAnnotations([...annotations, defaultAnnotation]);

        return;
      }

      // We're adding this annotation
      const annotationToAdd: Annotation = {
        x: prevX,
        y: prevY,
        width,
        height,
        id: activeAnnotation.id,
        className: activeAnnotation.className,
      };

      setActiveAnnotation(null);
      setAnnotations([...annotations, annotationToAdd]);

      return;
    }

    // Clear an annotation if the box tool is not selected
    if (tool !== 'box' && activeAnnotation) {
      setActiveAnnotation(null);
      return;
    }
  };

  // Handles updates to our annotations during mouse movement
  const handleMouseMove = (ev: KonvaEventObject<MouseEvent | TouchEvent>) => {
    // Make sure we're only updating the active annotation if it exists
    if (activeAnnotation) {
      const position = ev.target.getStage()?.getRelativePointerPosition();
      // The current positions
      const prevX = activeAnnotation.x;
      const prevY = activeAnnotation.y;
      // The positions when the mouse was lifted up
      const x = position?.x || 0;
      const y = position?.y || 0;
      // The width and height
      const width = x - prevX;
      const height = y - prevY;

      setActiveAnnotation({
        x: prevX,
        y: prevY,
        width,
        height,
        id: activeAnnotation.id,
        className: activeAnnotation.className,
      });
      return;
    }

    // Clear an annotation if the box tool is not selected
    if (tool !== 'box' && activeAnnotation) {
      setActiveAnnotation(null);
      setActiveRef(null);
      return;
    }
  };

  // Called when a shape is selected
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onShapeSelect = (
    id: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ev: KonvaEventObject<MouseEvent | Event>,
  ) => {
    if (tool === 'select') {
      if (selectedAnnotation === id) {
        // console.log(`Deselecting ${id}`);
        // setSelectedAnnotation(null);
        // setActiveRef(null);
        return;
      }
      console.log(`Selecting ${id}`);
      setSelectedAnnotation(id);
    }
  };

  const setActiveQuiz = (quiz: QuizImageSnapshot | null) => {
    // Set the background
    if (quiz) {
      const bg = new window.Image(quiz.width, quiz.height);
      bg.src = quiz.image;
      background.current = bg;
    }

    setActiveInferenceId(quiz ? quiz.id : null);
    // console.log(canvasDimensions);
    //setCanvasDimensions({ width: quiz?.width || 0, height: quiz?.height || 0 });

    const adjustedAnnotations: Annotation[] = [];
    if (quiz) {
      inferencesToAnnotation(quiz.base).forEach((a) => {
        adjustedAnnotations.push(
          projectAnnotation(
            a,
            { x: quiz.width, y: quiz.height },
            { x: canvasDimensions.width, y: canvasDimensions.height },
          ),
        );
      });
    }

    // Remove any annotations
    setAnnotations(adjustedAnnotations);
    setActiveAnnotation(null);
  };

  const skipQuizForward = () => {
    if (currentQuiz.inferences.length == 0) {
      setActiveQuiz(null);
      onCloseResultsView();
      return;
    }
    if (!activeInferenceId) {
      return setActiveQuiz(currentQuiz.inferences[0]);
    }
    const selectedIndex = currentQuiz.inferences
      .map((q) => q.id)
      .indexOf(activeInferenceId);
    const skippedIndex = (selectedIndex + 1) % currentQuiz.inferences.length;
    setSelectedAnnotation(null);
    setActiveAnnotation(null);
    setActiveRef(null);
    setActiveQuiz(currentQuiz.inferences[skippedIndex]);
    onCloseResultsView();
    onClearResults();
    if (currentQuiz.mode === 'easy') {
      setTool('select');
    } else {
      setTool('box');
    }
  };

  const skipQuizBackward = () => {
    if (!currentQuiz) {
      return;
    }
    if (currentQuiz.inferences.length == 0) {
      setActiveQuiz(null);
      onCloseResultsView();
      onClearResults();
      return;
    }
    if (!activeInferenceId) {
      return setActiveQuiz(currentQuiz.inferences[0]);
    }
    const selectedIndex = currentQuiz.inferences
      .map((q) => q.id)
      .indexOf(activeInferenceId);
    const skippedIndex =
      selectedIndex === 0
        ? currentQuiz.inferences.length - 1
        : selectedIndex - 1;
    setSelectedAnnotation(null);
    setActiveAnnotation(null);
    setActiveRef(null);
    setActiveQuiz(currentQuiz.inferences[skippedIndex]);
    onCloseResultsView();
    onClearResults();
    if (currentQuiz.mode === 'easy') {
      setTool('select');
    } else {
      setTool('box');
    }
  };

  const removeSelectedAnnotation = () => {
    if (selectedAnnotation && currentQuiz.mode !== 'easy') {
      setAnnotations(annotations.filter((a) => a.id !== selectedAnnotation));
      setSelectedAnnotation(null);
      setActiveAnnotation(null);
      setActiveRef(null);
      if (
        annotations.length === 1 &&
        isSelecting &&
        currentQuiz.mode === 'hard'
      ) {
        setTool('box');
      }
    }
  };

  const removeAllAnnotations = () => {
    // Update annotations
    const activeInference = currentQuiz.inferences.find(
      (i) => i.id === activeInferenceId,
    );
    const adjustedAnnotations: Annotation[] = [];
    if (activeInference) {
      inferencesToAnnotation(activeInference.base).forEach((a) => {
        adjustedAnnotations.push(
          projectAnnotation(
            a,
            { x: activeInference.width, y: activeInference.height },
            { x: canvasDimensions.width, y: canvasDimensions.height },
          ),
        );
      });
    }

    setAnnotations(adjustedAnnotations);
    setSelectedAnnotation(null);
    setActiveAnnotation(null);
    setActiveRef(null);
  };

  const submitQuiz = () => {
    const activeInference = currentQuiz.inferences.find(
      (i) => i.id === activeInferenceId,
    );
    if (!activeInference || !canvasContainerWidth || !currentQuiz) {
      return;
    }

    const adjustedAnnotations: Annotation[] = [];
    const inferences: Inference[] = [];

    annotations.forEach((a) => {
      adjustedAnnotations.push(
        projectAnnotation(
          a,
          { x: canvasDimensions.width, y: canvasDimensions.height },
          { x: activeInference.width, y: activeInference.height },
        ),
      );
    });

    adjustedAnnotations.forEach((a) => {
      const inference: Inference = {
        className: a.className,
        xmin: 0,
        xmax: 0,
        ymin: 0,
        ymax: 0,
      };
      if (a.width < 0) {
        inference.xmin = a.x + a.width;
        inference.xmax = inference.xmin + Math.abs(a.width);
      } else {
        inference.xmin = a.x;
        inference.xmax = a.x + a.width;
      }

      if (a.height < 0) {
        inference.ymin = a.y + a.height;
        inference.ymax = inference.ymin + Math.abs(a.height);
      } else {
        inference.ymin = a.y;
        inference.ymax = a.y + a.height;
      }

      inference.xmin = Math.round(inference.xmin);
      inference.xmax = Math.round(inference.xmax);
      inference.ymin = Math.round(inference.ymin);
      inference.ymax = Math.round(inference.ymax);

      inferences.push(inference);
    });

    // Let the higher-order component handle the submission of these inferences
    onSubmitQuiz(activeInference.id, inferences);
  };

  // Helper variables for rending
  const isAnnotating = tool === 'box';
  const isSelecting = tool === 'select';
  const category =
    categories.find((c) => c.name === classCategory) || categories[0];

  return (
    <div className="flex flex-col lg:flex-row flex-wrap overflow-x-hidden w-screen lg:max-w-5xl">
      <div
        className="w-full overflow-x-hidden flex-1 px-4"
        ref={canvasContainerRef}
      >
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="flex py-2 w-full md:w-auto">
            {currentQuiz.mode === 'hard' && (
              <div className="bg-transparent rounded-lg grid grid-cols-2 w-full md:inline-flex items-center">
                <button
                  className={`${
                    isAnnotating ? 'bg-blue-600' : 'bg-neutral-800'
                  } text-neutral-100 ${
                    isAnnotating
                      ? 'hover:bg-blue-700'
                      : 'hover:bg-neutral-700 border-neutral-900'
                  } border-2 border-transparent transition-colors delay-75 p-2 rounded-l-lg`}
                  onClick={handleToolSelectBox}
                >
                  <ScanOutlineIcon
                    className="inline-block"
                    width={24}
                    height={24}
                    strokeWidth={1.5}
                  />
                  <p className="font-medium inline-block ml-2">Create Box</p>
                </button>
                <button
                  className={`${
                    isSelecting ? 'bg-blue-600' : 'bg-neutral-800'
                  } text-neutral-100 ${
                    isSelecting
                      ? 'hover:bg-blue-700'
                      : 'hover:bg-neutral-700 border-neutral-900'
                  } border-2 border-transparent transition-colors delay-75 p-2 rounded-r-lg`}
                  onClick={handleSelectBox}
                >
                  <PointerIcon
                    className="inline-block"
                    width={24}
                    height={24}
                    strokeWidth={2}
                  />
                  <p className="font-medium inline-block ml-2">Edit Box</p>
                </button>
              </div>
            )}
          </div>
          <div className="flex py-2">
            {selectedAnnotation && currentQuiz.mode !== 'easy' && (
              <button
                className={`hover:bg-neutral-900 bg-neutral-800 border-2 text-neutral-50 border-transparent transition-colors delay-75 p-2 rounded-lg mr-1`}
                onClick={removeSelectedAnnotation}
              >
                <TrashIcon
                  className="inline-block"
                  width={24}
                  height={24}
                  strokeWidth={2}
                />
              </button>
            )}
            <button
              className={`hover:bg-red-700 bg-red-600 border-2 text-neutral-50 border-transparent transition-colors delay-75 p-2 rounded-lg`}
              onClick={removeAllAnnotations}
            >
              <RestartIcon
                className="inline-block"
                width={24}
                height={24}
                strokeWidth={2}
              />
            </button>
            {!annotations.find((a) => a.className === 'No Failure') && (
              <button
                className="bg-blue-600 hover:bg-blue-700 text-neutral-50 font-semibold rounded-md ml-2 md:h-full px-3 whitespace-nowrap w-full"
                onClick={submitQuiz}
              >
                <DoneIcon
                  className="inline-block mr-1"
                  width={24}
                  height={24}
                  strokeWidth={2}
                />
                {annotations.length > 0 && `Submit`}
                {annotations.length === 0 && `I Don't See A Fail`}
              </button>
            )}
            {currentQuiz.mode === 'easy' &&
              annotations.find((a) => a.className === 'No Failure') && (
                <Popup
                  on={'hover'}
                  trigger={
                    <button
                      className="bg-gray-400 cursor-not-allowed text-neutral-50 font-semibold rounded-md px-3 md:h-full ml-2 whitespace-nowrap w-full"
                      onClick={submitQuiz}
                    >
                      <DoneIcon
                        className="inline-block mr-1"
                        width={24}
                        height={24}
                        strokeWidth={2}
                      />
                      Submit
                    </button>
                  }
                  position={['top center', 'bottom center']}
                  arrow={false}
                >
                  <div className="rounded-md p-2 bg-neutral-900 shadow-lg select-none m-2">
                    <p className="text-neutral-50 max-w-sm text-center">
                      Give each failure a category before submitting
                    </p>
                  </div>
                </Popup>
              )}
          </div>
        </div>
        {result && (
          <QuizSubmitModal
            categories={categories}
            isOpen={displayResult}
            width={result.quiz.width}
            height={result.quiz.height}
            points={result.points}
            maxPoints={result.maxPoints}
            img={result.quiz.image}
            test={result.submitted}
            truth={result.expected}
            onClose={() => {
              setModal(false);
              onCloseResultsView();
            }}
            onContinue={() => {
              skipQuizForward();
            }}
          />
        )}
        {updateClassModal && (
          <UpdateCategoryModal
            toUpdateId={updateClassModal.id}
            from={updateClassModal.from}
            to={updateClassModal.to}
            isOpen={updateClassModal !== undefined}
            onResponse={(id, response) => {
              if (response) {
                const toChange = annotations.find(
                  (a) => a.id === selectedAnnotation,
                );
                if (!toChange) {
                  return;
                }
                toChange.className = updateClassModal.to.name;

                const updated = annotations.filter(
                  (a) => a.id !== selectedAnnotation,
                );
                updated.push(toChange);
                setAnnotations(updated);
              }
              setUpdateClassModal(undefined);
            }}
          />
        )}
        {activeInferenceId && (
          <Stage
            className="max-w-full"
            style={{
              cursor:
                tool === 'box'
                  ? `url('${CursorBox}') 15.5 15.5, auto`
                  : undefined,
            }}
            width={canvasDimensions.width}
            height={canvasDimensions.height}
            x={0}
            y={0}
            onTouchStart={handleMouseDown}
            onTouchMove={handleMouseMove}
            onTouchEnd={handleMouseUp}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            <Layer>
              <Image
                id="background"
                image={background.current}
                width={canvasDimensions.width}
                height={canvasDimensions.height}
                x={0}
                y={0}
              />
            </Layer>
            <Layer>
              {drawableAnnotations.map((annotation) => (
                <Group x={0} y={0} key={annotation.id}>
                  {annotation.id !== activeAnnotation?.id && (
                    <Label x={annotation.x} y={annotation.y - 25}>
                      <Tag
                        fill={
                          categories.find(
                            (c) => c.name === annotation.className,
                          )?.color || '#737373'
                        }
                        cornerRadius={5}
                      />
                      <Text
                        text={
                          categories.find(
                            (c) => c.name === annotation.className,
                          )?.name || 'Unmarked Failure'
                        }
                        fontStyle={'bold'}
                        fontSize={12}
                        padding={5}
                        fill="white"
                      />
                    </Label>
                  )}
                  <Rect
                    ref={(e) => {
                      if (annotation.id === selectedAnnotation) {
                        setActiveRef(e);
                        return;
                      }
                      return undefined;
                    }}
                    onDragMove={(e) => {
                      let oldAnnotations = annotations;
                      const updatedAnnotation = oldAnnotations.find(
                        (a) => a.id === annotation.id,
                      );

                      if (!updatedAnnotation) {
                        return;
                      }

                      const stage = e.target.getStage();
                      const W = e.target.width() > 0;
                      const H = e.target.height() > 0;
                      let x = 0;
                      let y = 0;
                      if (!stage) {
                        return;
                      }

                      if (W) {
                        x = Math.max(
                          0,
                          Math.min(
                            e.target.x(),
                            stage.width() - Math.abs(e.target.width()),
                          ),
                        );
                      } else {
                        x = Math.max(
                          Math.abs(e.target.width()),
                          Math.min(e.target.x(), stage.width()),
                        );
                      }

                      if (H) {
                        y = Math.max(
                          0,
                          Math.min(
                            e.target.y(),
                            stage.height() - Math.abs(e.target.height()),
                          ),
                        );
                      } else {
                        y = Math.max(
                          Math.abs(e.target.height()),
                          Math.min(e.target.y(), stage.height()),
                        );
                      }

                      oldAnnotations = oldAnnotations.filter(
                        (a) => a.id !== annotation.id,
                      );

                      updatedAnnotation.x = x;
                      updatedAnnotation.y = y;
                      updatedAnnotation.width = e.target.width();
                      updatedAnnotation.height = e.target.height();

                      oldAnnotations.push(updatedAnnotation);
                      setAnnotations(oldAnnotations);
                    }}
                    onDragEnd={(e) => {
                      let oldAnnotations = annotations;
                      const updatedAnnotation = oldAnnotations.find(
                        (a) => a.id === annotation.id,
                      );

                      if (!updatedAnnotation) {
                        return;
                      }

                      oldAnnotations = oldAnnotations.filter(
                        (a) => a.id !== annotation.id,
                      );

                      updatedAnnotation.x = e.target.x();
                      updatedAnnotation.y = e.target.y();
                      updatedAnnotation.width = e.target.width();
                      updatedAnnotation.height = e.target.height();

                      oldAnnotations.push(updatedAnnotation);
                      setAnnotations(oldAnnotations);
                    }}
                    id={annotation.id}
                    key={annotation.id}
                    x={annotation.x}
                    y={annotation.y}
                    width={annotation.width}
                    height={annotation.height}
                    fill={
                      annotation.className !== 'No Failure'
                        ? 'rgba(0, 0, 0, 0.15)'
                        : 'rgba(0, 0, 0, 0.00)'
                    }
                    strokeWidth={2}
                    stroke={
                      annotation.id === activeAnnotation?.id
                        ? category.color
                        : categories.find(
                            (c) => c.name === annotation.className,
                          )?.color || 'white'
                    }
                    draggable={tool === 'select'}
                    strokeScaleEnabled={false}
                  />
                </Group>
              ))}
              {selectedAnnotation && (
                <Transformer
                  keepRatio={false}
                  flipEnabled={false}
                  onTransform={(e) => {
                    let oldAnnotations = annotations;
                    const updatedAnnotation = oldAnnotations.find(
                      (a) => a.id === selectedAnnotation,
                    );

                    if (!updatedAnnotation) {
                      return;
                    }

                    oldAnnotations = oldAnnotations.filter(
                      (a) => a.id !== selectedAnnotation,
                    );

                    // adjust size to scale
                    // and set minimal size
                    /*console.log({
                        x: e.target.x(),
                        y: e.target.y(),
                        width: e.target.width(),
                        height: e.target.height(),
                        scaleX: e.target.scaleX(),
                        scaleY: e.target.scaleY(),
                      });*/

                    // const boundingBox = e.target.getClientRect();
                    const stage = e.target.getStage();
                    if (!stage) {
                      return;
                    }

                    let x = e.target.x();
                    let y = e.target.y();
                    let width = e.target.width() * e.target.scaleX();
                    let height = e.target.height() * e.target.scaleY();

                    if (x < 0) {
                      x = 0;
                    } else if (x > stage.width()) {
                      x = stage.width();
                    }

                    if (y < 0) {
                      y = 0;
                    } else if (y > stage.height()) {
                      y = stage.height();
                    }

                    if (x + width > stage.width()) {
                      width = stage.width() - x;
                    }
                    if (y + height > stage.height()) {
                      height = stage.height() - y;
                    }

                    updatedAnnotation.x = x;
                    updatedAnnotation.y = y;
                    updatedAnnotation.width = width;
                    updatedAnnotation.height = height;

                    e.target.scaleX(1);
                    e.target.scaleY(1);

                    oldAnnotations.push(updatedAnnotation);
                    setAnnotations(oldAnnotations);
                  }}
                  ref={transformerRef}
                  ignoreStroke
                  rotateEnabled={false}
                />
              )}
            </Layer>
          </Stage>
        )}
        {currentQuiz && currentQuiz.inferences.length > 0 && (
          <div className="w-full flex justify-between">
            <div className="inline-flex items-center py-2 flex-1">
              <Popup
                className="flex justify-center"
                trigger={
                  <button className="bg-neutral-900 hover:bg-neutral-700 text-neutral-100 border-2 border-neutral-900 transition-colors delay-75 p-2 rounded-lg">
                    <QuestionIcon
                      className="text-neutral-50"
                      width={24}
                      height={24}
                      strokeWidth={2.5}
                    />
                  </button>
                }
                closeOnDocumentClick
                modal
              >
                <div
                  className="bg-neutral-50 rounded-md px-4 md:px-8 py-8 overflow-y-scroll mx-4"
                  style={{
                    maxHeight: 'calc(100vh - 10rem)',
                  }}
                >
                  <h1 className="font-semibold text-3xl mb-2">
                    Welcome to 3DPrintle!
                  </h1>
                  <ul className="list list-decimal list-inside">
                    <li>Find the 3D print failures in each image.</li>
                    <li>
                      Select the type of print failure from the menu on the
                      right. Click the &#x26A0; next to each failure type to see
                      examples.
                    </li>
                    <li>Draw a box around each failure.</li>
                    <li>The more accurate you are the more points you get!</li>
                    <li>
                      Once you&apos;ve identified all of the failures, click
                      SUBMIT.
                    </li>
                  </ul>
                  <h2 className="font-semibold text-2xl mt-4">
                    Scoring Points
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div>
                      <p className="font-medium text-xl py-2">
                        <span className="text-sm mr-2">&#x274C;</span> A wrong
                        box location deducts points
                      </p>
                      <img
                        className="rounded-lg border-4 border-red-400"
                        src={QVPoints1}
                        width={'100%'}
                        height={'auto'}
                      ></img>
                    </div>
                    <div>
                      <p className="font-medium text-xl py-2">
                        <span className="text-sm mr-2">&#x274C;</span> A wrong
                        failure type deducts points
                      </p>
                      <img
                        className="rounded-lg border-4 border-red-400"
                        src={QVPoints2}
                        width={'100%'}
                        height={'auto'}
                      ></img>
                    </div>
                    <div>
                      <p className="font-medium text-xl py-2">
                        <span className="text-sm mr-2">&#x274C;</span> A wrong
                        box size deducts points
                      </p>
                      <img
                        className="rounded-lg border-4 border-red-400"
                        src={QVPoints3}
                        width={'100%'}
                        height={'auto'}
                      ></img>
                    </div>
                    <div>
                      <p className="font-medium text-xl py-2">
                        <span className="text-sm mr-2">&#x2705;</span> Perfect!
                        Full Points.
                      </p>
                      <img
                        className="rounded-lg border-4 border-green-400"
                        src={QVPoints4}
                        width={'100%'}
                        height={'auto'}
                      ></img>
                    </div>
                    <div>
                      <p className="font-medium text-xl py-2">
                        <span className="text-sm mr-2">&#x26A0;</span> There may
                        be multiple failures in the same image!
                      </p>
                      <img
                        className="rounded-lg border-4 border-yellow-400"
                        src={QVPoints5}
                        width={'100%'}
                        height={'auto'}
                      ></img>
                    </div>
                  </div>
                  <h2 className="font-semibold text-2xl mt-4">
                    Don&apos;t Forget
                  </h2>
                  <ul className="list list-decimal list-inside">
                    <li>A new 3DPrintle will be available each day!</li>
                    <li>
                      Collect points to level up! Improve your accuracy to get
                      VIP status which grants early access to new features.
                    </li>
                  </ul>
                </div>
              </Popup>
              {activeInferenceId && (
                <Popup
                  trigger={
                    <div className="text-neutral-500 hover:text-neutral-600 inline-flex items-center ml-3 cursor-pointer">
                      <AwardIcon width={20} height={20} strokeWidth={2} />
                      <p className="pt-1 font-semibold">
                        {currentQuiz.inferences.find(
                          (q) => q.id === activeInferenceId,
                        )?.maxPoints || result?.maxPoints}{' '}
                        {currentQuiz.mode === 'easy'
                          ? '(Easy Mode)'
                          : '(Hard Mode)'}
                      </p>
                    </div>
                  }
                  on={['hover']}
                >
                  <div className="rounded-md p-2 bg-neutral-900 shadow-lg select-none m-2">
                    <p className="text-neutral-50 max-w-sm text-center">
                      You can score a maximum of{' '}
                      {
                        currentQuiz.inferences.find(
                          (q) => q.id === activeInferenceId,
                        )?.maxPoints
                      }{' '}
                      points on this test. Prints with more errors will have
                      higher maximum score
                    </p>
                  </div>
                </Popup>
              )}
            </div>
            {currentQuiz && currentQuiz.inferences.length > 1 && !result && (
              <div className="py-2 inline-flex items-center">
                <button
                  className="bg-neutral-900 hover:bg-neutral-700 text-neutral-100 border-2 border-neutral-900 transition-colors delay-75 p-2 rounded-lg"
                  onClick={skipQuizBackward}
                >
                  <SkipForward
                    transform="rotate(180)"
                    fill="none"
                    width={24}
                    height={24}
                    strokeWidth={1.5}
                  />
                </button>
                <p className="inline-block text-center ml-2 font-semibold">
                  {currentQuiz.inferences.findIndex(
                    (q) => q.id === activeInferenceId,
                  ) + 1}
                  /{currentQuiz.inferences.length}
                </p>
                <button
                  className="bg-neutral-900 hover:bg-neutral-700 text-neutral-100 border-2 border-neutral-900 transition-colors delay-75 p-2 ml-2 rounded-lg"
                  onClick={skipQuizForward}
                >
                  <SkipForward
                    fill="none"
                    width={24}
                    height={24}
                    strokeWidth={1.5}
                  />
                </button>
              </div>
            )}
          </div>
        )}
        {currentQuiz && currentQuiz.inferences.length > 0 && (
          <div className="grid auto-cols-auto gap-2 mt-4 w-full">
            {result && currentQuiz.inferences.length > 0 && (
              <>
                <button
                  className="bg-neutral-700 hover:bg-neutral-800 w-full text-neutral-50 font-semibold rounded-md py-2 inline-flex justify-center items-center"
                  onClick={() => {
                    onOpenResultsView();
                  }}
                >
                  <StatsIcon
                    className="mr-2"
                    width={30}
                    height={30}
                    strokeWidth={1.5}
                  />
                  View Results
                </button>
                <button
                  className="bg-blue-600 hover:bg-blue-700 w-full text-neutral-50 font-semibold rounded-md py-2 inline-flex justify-center items-center"
                  onClick={skipQuizForward}
                >
                  <NextIcon
                    className="mr-2"
                    width={30}
                    height={30}
                    strokeWidth={1.5}
                  />
                  Next Image
                </button>
              </>
            )}
          </div>
        )}
      </div>
      <div className="mt-10 px-2 lg:mt-14 w-full lg:w-min flex-shrink-0">
        {currentQuiz && currentQuiz.inferences.length > 0 && (
          <div className="flex-col w-full lg:w-60 bg-gray-200 rounded-lg p-4">
            <p className="font-semibold text-lg">Failure Type</p>
            <p className="font-medium text-neutral-600">
              Click{' '}
              <span role="img" aria-label="sheep">
                ⚠️
              </span>{' '}
              for more info on each failure type.
            </p>
            {categories
              .filter((c) => c.enabled)
              .map((c) => (
                <CategoryButton
                  key={c.name}
                  isSelected={c.name === classCategory}
                  primaryColor={c.color}
                  displayName={c.displayName}
                  onSelect={() => {
                    handleSelectCategory(c.name);
                  }}
                >
                  {c.info}
                </CategoryButton>
              ))}
            <p className="font-semibold mt-4 text-lg">Unlocks Later</p>
            <p className="font-medium text-neutral-600">
              New failure types will be unlocked as Quinly levels up
            </p>
            {categories
              .filter((c) => !c.enabled)
              .map((c) => (
                <CategoryButton
                  disabled
                  key={c.name}
                  isSelected={c.name === classCategory}
                  primaryColor={c.color}
                  displayName={c.displayName}
                  onSelect={() => {
                    handleSelectCategory(c.name);
                  }}
                >
                  {c.info}
                </CategoryButton>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AnnotatorCanvas;
