import { ClassificationCategory } from 'common/APITypes';
import React, { ReactElement } from 'react';
import Popup from 'reactjs-popup';

export interface UpdateCategoryModalProps {
  toUpdateId: string;
  isOpen: boolean;
  from: ClassificationCategory;
  to: ClassificationCategory;
  onResponse: (id: string, response: boolean) => void;
}

export const UpdateCategoryModal = (
  props: UpdateCategoryModalProps,
): ReactElement => {
  const { toUpdateId, from, to, isOpen, onResponse } = props;
  return (
    <Popup
      modal
      open={isOpen}
      closeOnDocumentClick
      onClose={() => {
        onResponse(toUpdateId, false);
      }}
    >
      <div className="bg-neutral-50 rounded-md max-w-5xl overflow-y-auto flex-col justify-center items-center mx-4">
        <div className="px-6 py-4">
          <p className="pt-2 text-lg max-w-sm leading-8">
            Would you like to change the failure type from{' '}
            <span
              className="text-neutral-50 font-medium px-2 py-1 rounded-md whitespace-nowrap"
              style={{ backgroundColor: from.color }}
            >
              {from.displayName}
            </span>{' '}
            to{' '}
            <span
              className="text-neutral-50 font-medium px-2 py-1 rounded-md whitespace-nowrap"
              style={{ backgroundColor: to.color }}
            >
              {to.displayName}
            </span>{' '}
            for this box?
          </p>
          <div className="mt-4">
            <hr></hr>
          </div>
          <div className="mt-4">
            <button
              className="bg-blue-600 hover:bg-blue-700 transition-colors delay-75 text-neutral-50 w-24 py-2 rounded-lg mr-2 font-medium"
              onClick={() => {
                onResponse(toUpdateId, true);
              }}
            >
              Okay
            </button>
            <button
              className="bg-neutral-200 hover:bg-neutral-300 transition-colors delay-75 text-neutral-700 w-24 py-2 rounded-lg mr-2 font-medium"
              onClick={() => {
                onResponse(toUpdateId, false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
};
