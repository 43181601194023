import React, { ReactElement, useState } from 'react';
import { ReactComponent as ChevronDownIcon } from '../icons/chevron-down.svg';

interface SidebarSectionProps {
  title: string;
  description: string;
  children?: React.ReactNode;
}

const SidebarSection = (props: SidebarSectionProps): ReactElement => {
  const { title, description, children } = props;
  const [open, setOpen] = useState<boolean>(true);

  return (
    <div className="w-full px-6 py-4 border-b border-neutral-200">
      <div className="w-full py-2">
        <div className="flex justify-between pb-1">
          <h2 className="text-base text-neutral-900 font-medium">{title}</h2>
          <div
            onClick={() => {
              setOpen(!open);
            }}
            className="w-7 h-7 p-1 bg-neutral-200 bg-opacity-80 rounded-md cursor-pointer flex justify-center items-center select-none"
          >
            <ChevronDownIcon
              className={`${
                open ? 'text-neutral-400' : 'text-blue-700'
              } transition-transform delay-100 ${
                open ? 'transform rotate-180' : 'transform rotate-0'
              }`}
              width={20}
              height={20}
            />
          </div>
        </div>
        {!open && (
          <p className="text-sm text-neutral-600 leading-relaxed">
            {description}
          </p>
        )}
      </div>
      {open && <div className="w-full">{children}</div>}
    </div>
  );
};

export default SidebarSection;
