import React, { ReactElement, useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import AnalyticsSidebar, {
  AnalyticsReport,
} from './components/AnalyticsSidebar/AnalyticsSidebar';
import PrintImagePicker, {
  PrintImagePickerData,
} from './components/PrintImagePicker';
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import PrintShapshotInspector from './components/PrintSnapshotInspector';
import Demo1Raw from 'images/demo-1-raw.jpg';
import Demo2Raw from 'images/demo-2-raw.jpg';
import Demo3Raw from 'images/demo-3-raw.jpg';
import Demo4Raw from 'images/demo-4-raw.jpg';
import Demo5Raw from 'images/demo-5-raw.jpg';
import Demo1Parsed from 'images/demo-1.jpg';
import Demo2Parsed from 'images/demo-2.jpg';
import Demo3Parsed from 'images/demo-3.jpg';
import Demo4Parsed from 'images/demo-4.jpg';
import { ReactComponent as WarningIcon } from 'icons/warning.svg';
import { ReactComponent as EmailIcon } from 'icons/email.svg';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useMarketingEmail from 'hooks/useMarketingEmail';
import Popup from 'reactjs-popup';
import ReactJoyride, {
  CallBackProps,
  ACTIONS,
  EVENTS,
  STATUS,
} from 'react-joyride';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Helmet } from 'react-helmet';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || ''; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

export interface InferenceRequest {
  model: string;
  source: string;
  id: string;
  requestedAt: number;
  requestedBy: string;
}

export interface InferenceResult {
  xmin: number;
  xmax: number;
  ymin: number;
  ymax: number;
  confidence: number;
  class: string;
  name: string;
}

export interface InferenceError {
  code: number;
  message: string;
}

export interface InferenceResponse {
  id: string;
  model: string;
  source: string;
  result: InferenceResult[];
  errors: InferenceError[];
  batchId: string;
  requestedAt: number;
  completedAt: number;
}

export interface InterfaceCacheEntry {
  request?: InferenceRequest;
  response?: InferenceResponse;
}

type InferenceProcess = { [id: string]: InterfaceCacheEntry };

export interface TutorialState {
  active: boolean;
  step: number;
  tutorialProcessId: string;
}

const reports: AnalyticsReport[] = [
  {
    reportId: '1',
    imageRaw: Demo1Raw,
    imageParsed: Demo1Parsed,
    source: 'demo-1-raw.jpg',
    failures: [
      {
        index: '1',
        failureType: 'Nozzle Blob',
        failureDescription:
          'Material accumulated over the nozzle causing it to not extrude properly',
        failureTitleColor: 'yellow-400',
        gradientBarProps: {
          gradientFrom: 'yellow-400',
          gradientTo: 'yellow-500',
          progress: 0.7,
        },
      },
    ],
    notifications: [
      {
        icon: (
          <WarningIcon
            className="text-yellow-400"
            width={25}
            height={25}
            strokeWidth={1.5}
          />
        ),
        color: 'yellow-400',
        text: 'Print automatically stopped at 1:46 AM',
      },
    ],
    userNotifications: [
      {
        icon: (
          <EmailIcon
            className="text-indigo-400"
            width={25}
            height={25}
            strokeWidth={1.5}
          />
        ),
        color: 'indigo-400',
        text: '',
        textElement: (
          <>
            Email notification sent to{' '}
            <span className="text-indigo-400 font-bold">your@email.com</span>
          </>
        ),
      },
    ],
    metrics: [
      {
        name: 'Hours Saved',
        value: '6',
      },
      {
        name: 'Filament Saved',
        value: '180g',
      },
    ],
    tips: [
      {
        title: 'Fix Nozzle Blob',
        description:
          'Material accumulated over the nozzle causing it to not extrude properly',
        fixes: [
          {
            problem: 'Incorrect Bed & Nozzle Temperatures',
            solution:
              'Verify bed and nozzle temperatures are correct for material type.',
          },
          {
            problem: 'Bad First Layer',
            solution: 'Reduce first layer speed to improve bed adhesion.',
          },
          {
            problem: 'Poor First Layer Adhesion',
            solution:
              'Increase nozzle temperature on first layer to improve bed adhesion.',
          },
        ],
      },
    ],
  },
  {
    reportId: '2',
    imageRaw: Demo2Raw,
    imageParsed: Demo2Parsed,
    source: 'demo-2-raw.jpg',
    failures: [
      {
        index: '1',
        failureType: 'Spaghetti',
        failureDescription:
          'Filament that was misplaced by the extruder. Looks like spaghetti.',
        failureTitleColor: 'green-400',
        gradientBarProps: {
          gradientFrom: 'green-400',
          gradientTo: 'green-500',
          progress: 0.8,
        },
      },
    ],
    notifications: [
      {
        icon: (
          <WarningIcon
            className="text-yellow-400"
            width={25}
            height={25}
            strokeWidth={1.5}
          />
        ),
        color: 'yellow-400',
        text: 'Print automatically stopped at 5:20 PM',
      },
    ],
    userNotifications: [
      {
        icon: (
          <EmailIcon
            className="text-indigo-400"
            width={25}
            height={25}
            strokeWidth={1.5}
          />
        ),
        color: 'indigo-400',
        text: '',
        textElement: (
          <>
            Email notification sent to{' '}
            <span className="text-indigo-400 font-bold">your@email.com</span>
          </>
        ),
      },
    ],
    metrics: [
      {
        name: 'Hours Saved',
        value: '2',
      },
      {
        name: 'Filament Saved',
        value: '50g',
      },
    ],
    tips: [
      {
        title: 'Fix Spaghetti',
        description:
          'Print has detached during printing, or extrusion is unsupported',
        fixes: [
          {
            problem: 'Possible Part Detach',
            solution: 'Increase bed adhesion.',
          },
          {
            problem: 'Possible Part Breakage',
            solution: 'Avoid printing tall, thin structures.',
          },
          {
            problem: 'Possible Bad First Layer',
            solution: 'Reduce first layer speed to improve bed adhesion.',
          },
        ],
      },
    ],
  },
  {
    reportId: '3',
    imageRaw: Demo3Raw,
    imageParsed: Demo3Parsed,
    source: 'demo-3-raw.jpg',
    failures: [
      {
        index: '1',
        failureType: 'Spaghetti',
        failureDescription:
          'Filament that was misplaced by the extruded. Looks like spaghetti.',
        failureTitleColor: 'green-400',
        gradientBarProps: {
          gradientFrom: 'green-400',
          gradientTo: 'green-500',
          progress: 0.72,
        },
      },
      {
        index: '2',
        failureType: 'Warping',
        failureDescription:
          'Temperature differences in the plastic causing shrinking in portions of the print',
        failureTitleColor: 'blue-400',
        gradientBarProps: {
          gradientFrom: 'blue-400',
          gradientTo: 'blue-500',
          progress: 0.71,
        },
      },
      {
        index: '3',
        failureType: 'Warping',
        failureDescription:
          'Temperature differences in the plastic causing shrinking in portions of the print',
        failureTitleColor: 'blue-400',
        gradientBarProps: {
          gradientFrom: 'blue-400',
          gradientTo: 'blue-500',
          progress: 0.7,
        },
      },
    ],
    notifications: [
      {
        icon: (
          <WarningIcon
            className="text-yellow-400"
            width={25}
            height={25}
            strokeWidth={1.5}
          />
        ),
        color: 'yellow-400',
        text: 'Print automatically stopped at 6:05 AM',
      },
    ],
    userNotifications: [
      {
        icon: (
          <EmailIcon
            className="text-indigo-400"
            width={25}
            height={25}
            strokeWidth={1.5}
          />
        ),
        color: 'indigo-400',
        text: '',
        textElement: (
          <>
            Email notification sent to{' '}
            <span className="text-indigo-400 font-bold">your@email.com</span>
          </>
        ),
      },
    ],
    metrics: [
      {
        name: 'Hours Saved',
        value: '1',
      },
      {
        name: 'Filament Saved',
        value: '20g',
      },
    ],
    tips: [
      {
        title: 'Fix Spaghetti',
        description:
          'Print has detached during printing, or extrusion is unsupported',
        fixes: [
          {
            problem: 'Possible Part Detach',
            solution: 'Increase bed adhesion.',
          },
          {
            problem: 'Possible Part Breakage',
            solution: 'Avoid printing tall, thin structures.',
          },
          {
            problem: 'Possible Bad First Layer',
            solution: 'Reduce first layer speed to improve bed adhesion.',
          },
        ],
      },
      {
        title: 'Fix Warping',
        description:
          'Temperature differences in the plastic causing shrinking in portions of the print',
        fixes: [
          {
            problem: 'Incorrect Bed & Nozzle Temperature',
            solution:
              'Verify bed and nozzle temperatures are correct for material type.',
          },
          {
            problem: 'Part Not Rigid Enough',
            solution:
              'Increase infill % and wall thickness to increase part rigidity.',
          },
          {
            problem: 'Cold Ambient Temperature',
            solution:
              'Avoid cold ambient temperature. Printer enclosure may be required.',
          },
        ],
      },
    ],
  },
  {
    reportId: '4',
    imageRaw: Demo4Raw,
    imageParsed: Demo4Parsed,
    source: 'demo-4-raw.jpg',
    failures: [
      {
        index: '1',
        failureType: 'No Extrusion',
        failureDescription: 'The nozzle is not extruding filament',
        failureTitleColor: 'yellow-400',
        gradientBarProps: {
          gradientFrom: 'yellow-400',
          gradientTo: 'yellow-500',
          progress: 0.72,
        },
      },
      {
        index: '2',
        failureType: 'Under Extrusion',
        failureDescription: 'The nozzle is not extruding enough filament',
        failureTitleColor: 'blue-400',
        gradientBarProps: {
          gradientFrom: 'blue-400',
          gradientTo: 'blue-500',
          progress: 0.61,
        },
      },
    ],
    notifications: [
      {
        icon: (
          <WarningIcon
            className="text-yellow-400"
            width={25}
            height={25}
            strokeWidth={1.5}
          />
        ),
        color: 'yellow-400',
        text: 'Print automatically stopped at 11:26 PM',
      },
    ],
    userNotifications: [
      {
        icon: (
          <EmailIcon
            className="text-indigo-400"
            width={25}
            height={25}
            strokeWidth={1.5}
          />
        ),
        color: 'indigo-400',
        text: '',
        textElement: (
          <>
            Email notification sent to{' '}
            <span className="text-indigo-400 font-bold">your@email.com</span>
          </>
        ),
      },
    ],
    metrics: [
      {
        name: 'Hours Saved',
        value: '0.5',
      },
      {
        name: 'Filament Saved',
        value: '50g',
      },
    ],
    tips: [
      {
        title: 'Fix No Extrusion',
        description: 'The nozzle is not extruding filament',
        fixes: [
          {
            problem: 'Nozzle Clog',
            solution: 'Perform cold pull.',
          },
          {
            problem: 'Heat Creep',
            solution:
              'Make sure the hotend cooling fan is effectively cooling the heatsink.',
          },
          {
            problem: 'Filament Tangle',
            solution: 'Check if filament spool is tangled.',
          },
        ],
      },
      {
        title: 'Fix Under Extrusion',
        description: 'The nozzle is not extruding enough filament',
        fixes: [
          {
            problem: 'Non-Optimal Slicer Settings',
            solution:
              'Check flow, extrusion multipliers, and line width settings in slicer.',
          },
          {
            problem: 'Incorrect Filament Diameter',
            solution: 'Verify filament diameter is set correctly in slicer.',
          },
          {
            problem: 'Excessive Friction In Filament Path',
            solution: 'Reduce any excessive friction in the filament path.',
          },
          {
            problem: 'Incorrect Extruder Gear Tension',
            solution: 'Check if your extruder gear has correct tension.',
          },
          {
            problem: 'Partial Nozzle Clog',
            solution:
              'Possible partial clog due to debris in nozzle, perform cold pull to clean nozzle.',
          },
        ],
      },
    ],
  },
  {
    reportId: '5',
    imageRaw: Demo5Raw,
    imageParsed: Demo1Parsed,
    source: 'demo-5-raw.jpg',
    failures: [],
    metrics: [
      {
        name: 'Hours Saved',
        value: '0.5',
      },
      {
        name: 'Filament Saved',
        value: '50g',
      },
    ],
    notifications: [],
    tips: [],
    userNotifications: [],
  },
];

const reportToImageData = (report: AnalyticsReport): PrintImagePickerData => {
  const data: PrintImagePickerData = {
    image: report.imageRaw,
    reportId: report.reportId,
  };
  return data;
};

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const App = (): ReactElement => {
  const [activeReport, setActiveReport] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [timesAddedToQueue, setTimesAddedToQueue] = React.useState<number>(0);
  const [selectWhenAvailable, setSelectWhenAvailable] =
    React.useState<string>('');
  const [imagePickerOpen, setImagePickerOpen] = React.useState<boolean>(false);
  const [tutorialEndModalOpen, setTutorialEndModal] =
    React.useState<boolean>(false);
  /*const [, setEmailSubscribeResult] = React.useState<{
    success?: boolean;
  }>({});*/
  const [tutorialState, setTutorialState] = React.useState<TutorialState>({
    active: false,
    step: 0,
    tutorialProcessId: '',
  });
  const [cache, setCache] = React.useState<InferenceProcess>({});

  const {
    isEmailMarketingModalOpen,
    createEmailMarketingModal,
    closeEmailMarketingModal,
  } = useMarketingEmail(false);
  // const { subscribeWithEmail } = useMarketingAPI();

  const apiRef = React.useRef({} as scrollVisibilityApiType);
  const sock = useRef<Socket>();

  const active = reports.find((r) => r.reportId === activeReport);

  useEffect(() => {
    //localStorage.setItem('debug', '*');
    localStorage.debug = '*';
    const socket = io(
      `${process.env.REACT_APP_API_WS_PROTOCOL}://${process.env.REACT_APP_API_URL}/classifier`,
      {
        reconnectionDelayMax: 10000,
        path: '/ws',
        transports: ['websocket', 'polling'],
      },
    );
    sock.current = socket;

    socket.on('pong', function (ms) {
      console.log(`${ms}ms`);
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    socket.on('v1-inference', (message: any) => {
      if (message.error) {
        console.log(message);
        toast(message.error, { type: 'error' });
        return;
      }

      // Update our cache of image inference results
      setCache(message);

      // If the tutorial is waiting on a response, update it here
      if (
        tutorialState.active &&
        cache[tutorialState.tutorialProcessId]?.response
      ) {
        setTutorialState({
          active: true,
          step: tutorialState.step,
          tutorialProcessId: '',
        });
      }
    });
  }, []);

  const handleOnAddToQueue = (id: string) => {
    const image = reports.find((img) => img.reportId === id);
    const reqId = uuidv4();
    sock.current?.emit('v1-inference', {
      type: 'inference',
      id: reqId,
      data: {
        source: image?.source,
      },
    });
    setTimesAddedToQueue(timesAddedToQueue + 1);
    setSelectWhenAvailable(reqId);

    // Display the email modal after a few clicks
    if (timesAddedToQueue > 1) {
      handleOpenMarketingEmail();
    }

    if (tutorialState.active && tutorialState.step === 0) {
      setTutorialState({
        active: true,
        step: tutorialState.step,
        tutorialProcessId: reqId,
      });
    }

    // Fire-off a GA event for adding an image to queue
    ReactGA.event({
      category: 'Demo',
      action: 'add_to_processing_queue',
    });
  };

  const handleRemoveFromQueue = (id: string) => {
    const deletedId = id.split('.')[1];
    if (activeReport === id) {
      setActiveReport('');
    }
    sock.current?.emit('v1-inference', {
      type: 'delete',
      id: uuidv4(),
      data: {
        id: deletedId,
      },
    });
  };

  const handleOpenMarketingEmail = () => {
    setTimeout(() => {
      createEmailMarketingModal();
    }, 1500);
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  /*const handleMarketingEmailSubmission = () => {
    if (validateEmail(email)) {
      subscribeWithEmail(email)
        .then((data) => {
          console.log(data);
          setEmailSubscribeResult({ success: data.status === 200 });
        })
        .catch((err) => {
          console.log(err);
          setEmailSubscribeResult({ success: false });
        });
    }
  };*/

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    if (action === 'skip') {
      setTutorialState({ active: false, step: 0, tutorialProcessId: '' });
      setTutorialEndModal(true);
      return;
    }

    // On the first two steps of the tutorial a close actions means we just end the tutorial.
    if ([0, 1].includes(index) && action === 'close') {
      console.log('needs to stop tutorial here');
      setTutorialState({ active: false, step: 0, tutorialProcessId: '' });
      return;
    }

    if (
      [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]
        .map((e) => e.toString())
        .includes(type)
    ) {
      // Update state to advance the tour
      setTutorialState({
        active: true,
        step: index + (action === ACTIONS.PREV ? -1 : 1),
        tutorialProcessId: tutorialState.tutorialProcessId,
      });
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED]
        .map((e) => e.toString())
        .includes(status)
    ) {
      // Need to set our running state to false, so we can restart if we click start again.
      setTutorialState({ active: false, step: 0, tutorialProcessId: '' });
      setTutorialEndModal(true);
    }

    console.groupCollapsed(type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };

  let emailBoxColor = 'transparent';
  if (email !== '') {
    emailBoxColor = validateEmail(email) ? 'green-600' : 'red-600';
  }

  // Check if we should auto select anything
  if (cache[selectWhenAvailable]) {
    setActiveReport(selectWhenAvailable);
    setSelectWhenAvailable('');
  }

  if (
    tutorialState.active &&
    tutorialState.step == 0 &&
    tutorialState.tutorialProcessId !== '' &&
    cache[tutorialState.tutorialProcessId]
  ) {
    setTutorialState({
      active: true,
      step: 1,
      tutorialProcessId: tutorialState.tutorialProcessId,
    });
  }

  if (
    tutorialState.active &&
    tutorialState.step == 1 &&
    tutorialState.tutorialProcessId !== '' &&
    cache[tutorialState.tutorialProcessId]?.response
  ) {
    setTutorialState({
      active: true,
      step: 2,
      tutorialProcessId: '',
    });
  }

  console.log(tutorialState);

  return (
    <div className="min-h-screen flex-col lg:flex-row lg:h-screen lg:max-h-screen flex sm:p-0">
      <Helmet>
        <title>QuinlyVision AI Demo</title>
        <meta
          name="description"
          content="Watch QuinlyVision AI in action! Pick an image and watch it find the print failures in real time."
        />
        <meta name="og:title" content="QuinlyVision AI Demo" />
        <meta
          name="og:description"
          content="Watch QuinlyVision AI in action! Pick an image and watch it find the print failures in real time."
        />
      </Helmet>
      <ReactJoyride
        steps={[
          {
            target: '.tutorial-step-1',
            content: (
              <div>
                <p className="pt-2 text-left">
                  Choose an image for QuinlyVision to study.
                </p>
                <div className="pt-4 text-left select-none">
                  <span
                    className="font-semibold py-2 px-3 rounded-md text-neutral-100"
                    style={{ backgroundColor: '#3b82f6' }}
                  >
                    {tutorialState.step + 1}/4
                  </span>
                </div>
              </div>
            ),
            disableBeacon: true,
            placement: 'top',
            showSkipButton: false,
            showProgress: true,
            spotlightClicks: true,
            hideFooter: true,
          },
          {
            target: '.tutorial-step-2',
            content: (
              <div className="text-left">
                <p className="">
                  Please wait ... QuinlyVision is working through the queue!
                </p>
                <p className="pt-2">Your image is being processed live.</p>
                <div className="pt-4 text-left select-none">
                  <span
                    className="font-semibold py-2 px-3 rounded-md text-neutral-100"
                    style={{ backgroundColor: '#3b82f6' }}
                  >
                    {tutorialState.step + 1}/4
                  </span>
                </div>
              </div>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            placement: 'auto',
            showSkipButton: false,
            showProgress: true,
            spotlightClicks: false,
            hideFooter: true,
          },
          {
            target: '.tutorial-step-3',
            content: (
              <div className="text-left">
                <p className="pt-2">
                  QuinlyVision marks and labels each failure.
                </p>
                <p className="pt-2">Did QuinlyVision miss any? Let us know:</p>
                <div className="inline-flex text-blue-600">
                  <button
                    onClick={() => {
                      window.open('mailto:Info@3dque.com');
                    }}
                  >
                    Info@3dque.com
                  </button>
                  <p className="ml-3 text-neutral-700">or</p>
                  <button
                    onClick={() => {
                      window.open('https://discord.com/invite/JN9EDP8');
                    }}
                    className="pl-3"
                  >
                    Discord
                  </button>
                </div>
              </div>
            ),
            disableBeacon: true,
            placement: 'auto',
            showSkipButton: true,
            showProgress: true,
            spotlightClicks: false,
            hideBackButton: true,
            hideFooter: false,
          },
          {
            target: '.tutorial-step-4',
            content: (
              <div>
                <p className="pt-2 text-left">
                  QuinlyVision tells you: Failure Types, Action Taken, Possible
                  Fixes, and more ...! Scroll down to see.
                </p>
              </div>
            ),
            disableBeacon: true,
            placement: 'auto',
            showSkipButton: true,
            showProgress: true,
            spotlightClicks: false,
            hideFooter: false,
          },
        ]}
        stepIndex={tutorialState.step}
        continuous
        run={tutorialState.active}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: '#3b82f6',
          },
        }}
      />
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        theme={'colored'}
        autoClose={5000}
      />
      <Popup
        modal
        open={isEmailMarketingModalOpen}
        onClose={() => {
          closeEmailMarketingModal();
        }}
      >
        {(close: () => void) => (
          <div className="w-screen h-screen bg-black bg-opacity-80 backdrop-blur-md flex justify-center items-center">
            <div className="h-5/6 overflow-y-auto bg-neutral-100 rounded-lg py-10 px-4 md:px-10 flex flex-col w-11/12 md:w-5/6 lg:h-auto lg:w-1/3">
              <MailchimpSubscribe
                url={process.env.REACT_APP_MAIL_CHIMP_FORM || ''}
                render={({ subscribe, status, message }) => (
                  <div>
                    {(status === 'sending' || status === null) && (
                      <>
                        <p className="font-semibold text-3xl text-neutral-900">
                          Join the QuinlyVision Challenge!
                        </p>
                        <ul className="font-normal text-lg text-neutral-700 pt-2 pl-4 list-outside list-disc">
                          <li className="pt-1">Watch QuinlyVision develop.</li>
                          <li className="pt-1">
                            Earn points. Vote on actions. Suggest fixes.
                          </li>
                          <li className="pt-1">
                            See how you compare against QuinlyVision and others
                            on the leaderboard.{' '}
                          </li>
                        </ul>
                        <div className="pt-4">
                          <input
                            type={'email'}
                            placeholder="Enter your email ..."
                            value={email}
                            onChange={(event) => {
                              setEmail(event.target.value);
                            }}
                            className={`form-input rounded-md text-neutral-500 font-medium border bg-neutral-200 border-${emailBoxColor} w-full py-4 px-4 text-lg tracking-wide`}
                          />
                        </div>
                        <div className="pt-6">
                          <button
                            className="bg-blue-600 hover:bg-blue-700 font-medium text-neutral-100 py-2 px-3 rounded-md"
                            onClick={() => {
                              if (validateEmail(email)) {
                                subscribe({ EMAIL: email });
                              }
                            }}
                          >
                            Sign Me Up
                          </button>
                          <button
                            className="bg-neutral-300 hover:bg-neutral-400 font-medium text-neutral-700 py-2 px-3 rounded-md ml-2"
                            onClick={close}
                          >
                            Maybe Later
                          </button>
                        </div>
                      </>
                    )}
                    {status === 'error' && (
                      <div className="p-6 text-left">
                        <p className="font-semibold text-2xl text-neutral-800">
                          Something went wrong{' '}
                          <span role="img" aria-label="sheep">
                            😟
                          </span>
                          . We couldn&apos;t subscribe you now, but feel free to
                          try again later.
                        </p>
                        <div
                          style={{ color: 'red' }}
                          dangerouslySetInnerHTML={{
                            __html: message ? message.toString() : '',
                          }}
                        />
                        <button
                          className="bg-blue-500 mt-4 hover:bg-blue-600 font-medium text-neutral-100 py-2 px-3 rounded-md ml-2"
                          onClick={close}
                        >
                          Okay!
                        </button>
                      </div>
                    )}
                    {status === 'success' && (
                      <div className="p-6 text-left">
                        <p className="font-semibold text-2xl text-neutral-800">
                          Thanks for subscribing!{' '}
                          <span role="img" aria-label="sheep">
                            🥳
                          </span>{' '}
                          Check your email for a confirmation! We&apos;ll update
                          you when new QuinlyVision features are ready for you
                          to try.
                        </p>
                        <button
                          className="bg-blue-500 mt-4 hover:bg-blue-600 font-medium text-neutral-100 py-2 px-6 rounded-md"
                          onClick={close}
                        >
                          Okay!
                        </button>
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        )}
      </Popup>
      <Popup
        modal
        open={tutorialEndModalOpen}
        onClose={() => {
          setTutorialEndModal(false);
        }}
      >
        {(close: () => void) => (
          <div className="w-screen min-h-screen bg-black bg-opacity-80 backdrop-blur-md flex justify-center items-center">
            <div className=" bg-neutral-50 rounded-lg py-10 px-10 flex flex-col w-11/12 md:w-5/6 lg:h-auto lg:w-1/3">
              <p className="text-2xl font-semibold">
                Become a part of our community!
              </p>
              <button
                className="bg-blue-600 hover:bg-blue-700 transition-colors delay-75 mt-2 px-2 py-2 font-semibold text-neutral-100 rounded-md"
                onClick={() => {
                  close();
                  createEmailMarketingModal(true);
                }}
              >
                Get Updates
              </button>
              <button
                className="bg-indigo-500 hover:bg-neutral-600 transition-colors delay-75 mt-2 px-2 py-2 font-semibold text-neutral-100 rounded-md"
                onClick={() => {
                  close();
                  window.open('https://discord.com/invite/JN9EDP8');
                }}
              >
                Share Your Knowledge - Join Us On Discord
              </button>
              <button
                className="bg-neutral-200 hover:bg-neutral-300 transition-colors delay-75 mt-2 px-2 py-2 font-semibold text-neutral-700 rounded-md"
                onClick={() => {
                  close();
                }}
              >
                Back To Demo
              </button>
            </div>
          </div>
        )}
      </Popup>
      <div className="flex flex-col flex-1 min-w-0">
        <PrintShapshotInspector
          onRequestRandomImage={() => {
            const chosenDemoImage =
              reports[Math.floor(Math.random() * reports.length)].reportId;
            handleOnAddToQueue(chosenDemoImage);
          }}
          onTutorialStart={() => {
            // When the start tutorial button is clicked fire off an event
            ReactGA.event({
              category: 'Demo',
              action: 'start_tutorial',
            });

            setTutorialState({ active: true, step: 0, tutorialProcessId: '' });
          }}
          onGalleryOpen={() => {
            setImagePickerOpen(true);
          }}
          onGetInTouchOpen={() => {
            setTutorialEndModal(true);
          }}
          report={cache[activeReport]}
        />
        <PrintImagePicker
          pickerOpen={imagePickerOpen}
          selected={activeReport}
          onImageSelected={(id) => {
            setActiveReport(id);
          }}
          onImageDeleted={(id) => {
            handleRemoveFromQueue(id);
          }}
          onSetImagePickerOpen={(open) => {
            setImagePickerOpen(open);
          }}
          onAddToQueue={handleOnAddToQueue}
          images={reports.map(reportToImageData)}
          inferences={Object.keys(cache).map((key) => cache[key])}
          apiRef={apiRef}
        />
      </div>
      {activeReport !== '' &&
        cache[activeReport] &&
        cache[activeReport].response && (
          <AnalyticsSidebar report={active} inference={cache[activeReport]} />
        )}
    </div>
  );
};

export default App;
