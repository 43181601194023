import TwoColumnReportTable, { ReportMetric } from '../TwoColumnReportTable';
import React, { ReactElement, ReactNode } from 'react';
import FailureTypeProgress from '../FailureTypeProgress';
import SidebarSection from '../SidebarSection';
import Notification from '../Notification';
import './AnalyticsSidebar.css';
import FailurePreventionCard, { Fixes } from 'components/FailurePreventionCard';
import { GradientProgressBarProps } from 'components/GradientProgressBar';
import { InferenceResult, InterfaceCacheEntry } from '../../App';
import {
  classificationNameToMetaData,
  imageToMetrics,
} from 'util/ClassificationMetadata';
import { ReactComponent as EmailIcon } from 'icons/email.svg';
import { ReactComponent as WarningIcon } from 'icons/warning.svg';

interface AnalyticsSidebarProps {
  report?: AnalyticsReport;
  inference?: InterfaceCacheEntry;
}

export interface AnalyticsReport {
  reportId: string;
  imageRaw: string;
  imageParsed: string;
  source: string;
  failures: ReportFailure[];
  notifications: ReportNotification[];
  userNotifications: ReportNotification[];
  metrics: ReportMetric[];
  tips: FailurePreventionTips[];
}

interface ReportFailure {
  index: string;
  failureType: string;
  failureDescription: string;
  failureTitleColor: string;
  gradientBarProps: GradientProgressBarProps;
}

interface ReportNotification {
  color: string;
  text?: string;
  textElement?: ReactNode;
  icon: ReactNode;
}

export interface FailurePreventionTips {
  title: string;
  description: string;
  fixes: Fixes[];
}

// Given an inference result suggest some fixes based on the classifications of failures
const suggestFixesFromInferenceResult = (
  results?: InferenceResult[],
): FailurePreventionTips[] => {
  if (!results || results.length == 0) {
    return [];
  }

  // Collect the unique failure types
  const uniqueFailures: { [key: string]: boolean } = {};
  results.forEach((r) => {
    if (!uniqueFailures[r.name]) {
      uniqueFailures[r.name] = true;
    }
  });

  const tips: FailurePreventionTips[] = [];
  Object.keys(uniqueFailures).forEach((t) => {
    const data = classificationNameToMetaData(t);
    const { tips: tip } = data;
    if (tip) {
      tips.push(tip);
    }
  });

  return tips;
};

const formatAMPM = (date: Date): string => {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + formattedMinutes + ' ' + ampm;
  return strTime;
};

const AnalyticsSidebar = (props: AnalyticsSidebarProps): ReactElement => {
  const { report, inference } = props;

  /*const uniqueFailureTypes = new Set<string>(
    report ? report?.failures.map((f) => f.failureType) : [],
  );*/

  const unixTimestamp = props.inference?.response?.completedAt || 0;
  const dateCompleted = new Date(unixTimestamp * 1000);

  return (
    <div className="tutorial-step-4 sidebar-container flex-shrink-0 flex flex-col lg:ml-4 mt-4 lg:mt-0 sm:w-full lg:w-96 bg-neutral-100 rounded-xl relative">
      <div className="w-full overflow-y-auto pb-2 scroll-smooth">
        <div className="bg-cover px-6 py-7 rounded-t-lg bg-gradient-to-br from-neutral-100 to-neutral-200">
          <h1 className="text-2xl text-neutral-900 font-semibold">Analytics</h1>
          <p className="text-base text-neutral-700 pt-1 leading-relaxed">
            View an aggregated report of failures, actions, and preventions for
            your print snapshot
          </p>
        </div>
        <SidebarSection
          title="Failure Types"
          description="Get detailed information on which print failures were found in the print, and how confident we are (%) that they happened."
        >
          {inference?.response?.result
            .filter((r) => r.name !== 'model')
            .map((r, i) => {
              const { displayName, description, colors } =
                classificationNameToMetaData(r.name);
              const { primary, primaryDarker } = colors;
              return (
                <FailureTypeProgress
                  key={i}
                  index={(i + 1).toString()}
                  failureType={displayName}
                  failureDescription={description}
                  failureTitleColor={primary}
                  progressBarProps={{
                    gradientFrom: primary,
                    gradientTo: primaryDarker,
                    progress: r.confidence,
                  }}
                />
              );
            })}
        </SidebarSection>
        <SidebarSection
          title="Action Taken"
          description="Describes what happens when Quinly Vision detected the failures"
        >
          {inference?.response && (
            <>
              <Notification
                icon={
                  <WarningIcon
                    className="text-neutral-100"
                    width={25}
                    height={25}
                    strokeWidth={2}
                  />
                }
                color="neutral-700"
                text={`Print automatically stopped at ${formatAMPM(
                  dateCompleted,
                )}`}
              />
              <Notification
                icon={
                  <EmailIcon
                    className="text-neutral-100"
                    width={25}
                    height={25}
                    strokeWidth={1.5}
                  />
                }
                color="indigo-400"
                text=""
                textElement={
                  <>
                    Email notification sent to{' '}
                    <span className="text-indigo-50 font-bold">
                      your@email.com
                    </span>
                  </>
                }
              />
            </>
          )}
        </SidebarSection>
        <SidebarSection
          title="Possible Fixes"
          description="Gives you pointers on how to achieve a better print next time"
        >
          {suggestFixesFromInferenceResult(inference?.response?.result).map(
            (f, i) => (
              <FailurePreventionCard
                key={`${report?.reportId || 'none'}-${i}`}
                title={f.title}
                description={f.description}
                tips={f.fixes}
              />
            ),
          )}
        </SidebarSection>
        <SidebarSection
          title="More Info"
          description="Additional information on what happened with the print"
        >
          {inference?.response && (
            <TwoColumnReportTable
              metrics={imageToMetrics(inference.response.source)}
            />
          )}
        </SidebarSection>
      </div>
      <div className="absolute z-10 bottom-0 left-0 w-full h-14 min-h-2 bg-gradient-to-t from-neutral-300 to-transparent rounded-b-xl"></div>
    </div>
  );
};

export default AnalyticsSidebar;
