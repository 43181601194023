const buildAPIPath = (path: string) => {
  return `${process.env.REACT_APP_API_REST_PROTOCOL}://${process.env.REACT_APP_API_URL}/${path}`;
};

// Stored in local storage
interface MarketingSettings {
  newUser: boolean;
  onEmailList: boolean;
  v: number;
}

interface MarketingAPI {
  subscribeWithEmail(
    email: string,
  ): Promise<
    | { status: number }
    | { status: number; errors: { error: string; message: string }[] }
  >;
  getMarkettingSettings: () => MarketingSettings;
  storeMarketingSettings: (settings: MarketingSettings) => void;
}

export const useMarketingAPI = (): MarketingAPI => {
  // Subscribe a user with their email address
  const subscribeWithEmail = async (
    email: string,
  ): Promise<
    | { status: number }
    | { status: number; errors: { error: string; message: string }[] }
  > => {
    return new Promise((resolve, reject) => {
      fetch(buildAPIPath('api/v1/marketing/email'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Get the user's marketing settings from local storage if it exists
  const getMarkettingSettings = (): MarketingSettings => {
    let settings: MarketingSettings;
    try {
      const raw = localStorage.getItem('qv.marketing');
      if (!raw) {
        const newMarkettingSettings: MarketingSettings = {
          newUser: true,
          onEmailList: false,
          v: 1,
        };
        storeMarketingSettings(newMarkettingSettings);
        return newMarkettingSettings;
      }
      settings = JSON.parse(raw);
    } catch (err) {
      console.log(err);
      return {
        newUser: true,
        onEmailList: false,
        v: 1,
      };
    }
    return settings;
  };

  // Stores the user's marketting settings in local storage
  const storeMarketingSettings = (settings: MarketingSettings) => {
    try {
      localStorage.setItem('qv.marketing', JSON.stringify(settings));
    } catch (err) {
      console.log(err);
    }
  };

  return {
    subscribeWithEmail,
    getMarkettingSettings,
    storeMarketingSettings,
  };
};

export default useMarketingAPI;
