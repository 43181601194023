import useDrag from 'hooks/useDrag';
import React, { ReactElement, ReactNode } from 'react';
import PrintImageThumbnail from './PrintImageThumbnail';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { ReactComponent as ChevronDownIcon } from '../icons/chevron-down.svg';
import { ReactComponent as UploadIcon } from '../icons/file-add.svg';
import { InterfaceCacheEntry } from '../App';
import imageToAssetName from '../util/ImageImporter';
import { ReactComponent as ImageIcon } from 'icons/image.svg';
import Popup from 'reactjs-popup';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  console.log(ev.deltaY);

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

function Arrow({
  children,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        right: '1%',
        opacity: disabled ? '0' : '1',
        userSelect: 'none',
      }}
    >
      {children}
    </button>
  );
}

export function LeftArrow(): ReactNode {
  const { scrollPrev } = React.useContext(VisibilityContext);

  return (
    <Arrow disabled={false} onClick={() => scrollPrev()}>
      <div className="md:ml-2">
        <ChevronDownIcon
          className="text-neutral-500 transform rotate-90"
          width={25}
          height={25}
        />
      </div>
    </Arrow>
  );
}

export function RightArrow(): ReactNode {
  const { scrollNext } = React.useContext(VisibilityContext);

  return (
    <Arrow disabled={false} onClick={() => scrollNext()}>
      <div className="md:mr-2">
        <ChevronDownIcon
          className="text-neutral-500 transform -rotate-90"
          width={25}
          height={25}
        />
      </div>
    </Arrow>
  );
}

export interface PrintImagePickerData {
  reportId: string;
  image: string;
}

export interface PrintImagePickerProps {
  images: PrintImagePickerData[];
  inferences: InterfaceCacheEntry[];
  apiRef: React.MutableRefObject<scrollVisibilityApiType>;
  pickerOpen: boolean;
  onImageSelected?: (id: string) => void;
  onImageDeleted?: (id: string) => void;
  onSetImagePickerOpen?: (open: boolean) => void;
  onAddToQueue?: (id: string) => void;
  scrollToImage?: (id: string) => void;
  selected: string;
}

const PrintImagePicker = (props: PrintImagePickerProps): ReactElement => {
  const {
    images,
    onImageSelected,
    onImageDeleted,
    onSetImagePickerOpen,
    apiRef,
    selected,
    onAddToQueue,
    inferences,
    pickerOpen,
  } = props;

  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const handleItemClick = (itemId: string) => {
    if (dragging) {
      return false;
    }
    const reqId = itemId.split('.')[1];
    const selectedId = selected !== reqId ? reqId : '';

    if (onImageSelected) {
      onImageSelected(selectedId);
    }
  };

  return (
    <div
      className="lg:bg-neutral-200 rounded-lg overflow-y-auto min-w-0 hide-scroll flex flex-none items-stretch flex-wrap md:flex-nowrap"
      onMouseLeave={dragStop}
    >
      <div
        className="lg:bg-neutral-200 w-full md:w-auto min-h-full flex flex-col flex-shrink flex-grow-0 p-3 h-48"
        onClick={() => {
          onSetImagePickerOpen && onSetImagePickerOpen(true);
        }}
      >
        <div
          className={`tutorial-step-1 w-full flex-1 lg:w-80 bg-gradient-to-br from-blue-500 to-blue-700 transition-colors ease-in-out delay-75 flex flex-col justify-center items-center rounded-lg text-center cursor-pointer select-none`}
        >
          <div className="w-3/4 flex flex-col justify-center items-center">
            <UploadIcon
              className={`text-neutral-100 ${
                inferences.length === 0 ? 'animate-bounce' : ''
              }`}
              strokeWidth={1.25}
              width={30}
              height={30}
            />
            <p className="text-md font-normal text-neutral-100 pt-2">
              Image Gallery
            </p>
          </div>
        </div>
      </div>
      <Popup
        onClose={() => {
          if (onSetImagePickerOpen) {
            onSetImagePickerOpen(false);
          }
        }}
        open={pickerOpen}
      >
        {(close: () => void) => (
          <div
            className="w-screen h-screen bg-black bg-opacity-80 backdrop-blur-md flex justify-center items-center"
            onClick={() => {
              close();
            }}
          >
            <div className="bg-neutral-100 rounded-lg px-5 pt-8 h-screen md:h-5/6 w-full md:w-96 flex flex-col">
              <div className="flex-shrink-0">
                <p className="text-xl font-semibold text-neutral-800 text-">
                  Choose an image to detect failures
                </p>
                <p className="text-normal text-neutral-600 pt-1">
                  The image you choose will be added the processing queue. Once
                  our model detects failures it will notify you with the results
                </p>
              </div>
              <div className="flex-1 mt-2 flex-shrink min-h-0 justify-center items-center overflow-y-scroll">
                {images.map((i) => (
                  <PrintImageThumbnail
                    defaultBorderColor="border-neutral-200"
                    key={i.reportId}
                    selected={false}
                    isInHorizontalScroll={true}
                    onClick={(id: string) => {
                      close();
                      onAddToQueue && onAddToQueue(id);
                    }}
                    itemId={i.reportId}
                    image={i.image}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </Popup>
      {inferences.length > 0 && (
        <ScrollMenu
          scrollContainerClassName="tutorial-step-2"
          onWheel={onWheel}
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
          apiRef={apiRef}
          options={{
            threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
          }}
        >
          {inferences
            .sort((a, b) => {
              if (!a.request || !b.request) return 0;
              if (a.request.requestedAt < b.request.requestedAt) {
                return 1;
              } else if (a.request.requestedAt > b.request.requestedAt) {
                return -1;
              } else {
                return 0;
              }
            })
            .map((i) => (
              <PrintImageThumbnail
                interactive
                key={i.request?.id.split('.')[1]}
                selected={i.request?.id.split('.')[1] === selected}
                loading={i.response == null}
                submittedAt={i.request?.requestedAt}
                onClick={() => {
                  handleItemClick(i.request?.id || '');
                }}
                onDelete={() => {
                  if (onImageDeleted) {
                    console.log('delete ' + i.request?.id);
                    onImageDeleted(i.request?.id || '');
                  }
                }}
                itemId={i.request?.id || ''}
                image={imageToAssetName(i.request?.source || '')}
              />
            ))}
        </ScrollMenu>
      )}
      {inferences.length === 0 && (
        <div className="w-full flex flex-col h-52 md:h-auto select-none items-stretch">
          <div className="bg-neutral-300 my-2 mr-2 flex flex-col h-full rounded-md justify-center items-center">
            <ImageIcon
              className="text-neutral-900"
              strokeWidth={1.5}
              width={35}
              height={35}
            />
            <p className="text-neutral-900 font-medium max-w-sm text-center pt-2">
              The processed image gallery is empty. Pick some images to get
              started!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrintImagePicker;
