import React, { ReactElement } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import Popup from 'reactjs-popup';

export interface EmailSubscribeModalProps {
  triggerButton: JSX.Element;
  hintEmail?: string;
  onClose?: () => void;
}

export const EmailSubscribeModal = (
  props: EmailSubscribeModalProps,
): ReactElement => {
  const { triggerButton, hintEmail } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>(hintEmail || '');

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  return (
    <Popup
      modal
      open={open}
      trigger={triggerButton}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setEmail(hintEmail || '');
        setOpen(false);
      }}
    >
      <MailchimpSubscribe
        url={process.env.REACT_APP_MAIL_CHIMP_FORM || ''}
        render={({ subscribe, status, message }) => (
          <div className="bg-neutral-50 py-6 px-7 rounded-lg max-w-2xl">
            {(status === 'sending' || status === null) && (
              <>
                <p className="font-semibold text-3xl text-neutral-900">
                  Join the QuinlyVision AI newsletter
                </p>
                <ul className="font-normal text-lg text-neutral-700 pt-2 pl-4 list-outside list-disc">
                  <li className="pt-1">
                    Exclusive look at QuinlyVision development.
                  </li>
                  <li className="pt-1">
                    Join the conversation. Send us your feedback!
                  </li>
                  <li className="pt-1">
                    Be the first to learn how you can get this for your printer.
                  </li>
                </ul>
                <div className="pt-4">
                  <input
                    type={'email'}
                    placeholder="Enter your email ..."
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    className={`form-input rounded-md text-neutral-800 font-medium border-2 border-neutral-200 bg-neutral-100 w-full py-4 px-4 text-lg tracking-wide`}
                  />
                </div>
                <div className="pt-6">
                  <button
                    className="bg-blue-600 hover:bg-blue-700 font-medium text-neutral-100 py-2 px-3 rounded-md"
                    onClick={() => {
                      if (validateEmail(email)) {
                        subscribe({ EMAIL: email });
                      }
                    }}
                  >
                    Sign Me Up
                  </button>
                  <button
                    className="bg-neutral-300 hover:bg-neutral-400 font-medium text-neutral-700 py-2 px-3 rounded-md ml-2"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Maybe Later
                  </button>
                </div>
              </>
            )}
            {status === 'error' && (
              <div className="p-6 text-left">
                <p className="font-semibold text-2xl text-neutral-800">
                  Something went wrong{' '}
                  <span role="img" aria-label="sheep">
                    😟
                  </span>
                  . We couldn&apos;t subscribe you now, but feel free to try
                  again later.
                </p>
                <div
                  style={{ color: 'red' }}
                  dangerouslySetInnerHTML={{
                    __html: message ? message.toString() : '',
                  }}
                />
                <button
                  className="bg-blue-500 mt-4 hover:bg-blue-600 font-medium text-neutral-100 py-2 px-3 rounded-md ml-2"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Okay!
                </button>
              </div>
            )}
            {status === 'success' && (
              <div className="p-6 text-left">
                <p className="font-semibold text-2xl text-neutral-800">
                  Thanks for subscribing!{' '}
                  <span role="img" aria-label="sheep">
                    🥳
                  </span>{' '}
                  Check your email for a confirmation! We&apos;ll update you
                  when new QuinlyVision features are ready for you to try.
                </p>
                <button
                  className="bg-blue-500 mt-4 hover:bg-blue-600 font-medium text-neutral-100 py-2 px-6 rounded-md"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Okay!
                </button>
              </div>
            )}
          </div>
        )}
      />
    </Popup>
  );
};

export default EmailSubscribeModal;
