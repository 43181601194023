import { UserProfile } from 'common/APITypes';
import React, { createContext, ReactElement, useState } from 'react';

// May optionally contain a user profile
export type OptionalAuthType = (UserProfile & { guest: boolean }) | undefined;
// All of the data we carry in this context
export type AuthContextData = {
  auth: OptionalAuthType;
  setAuth: React.Dispatch<React.SetStateAction<OptionalAuthType>>;
};

// Initial context state
export const AuthContext = createContext<AuthContextData>({
  auth: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAuth: () => {
    console.log('Unimplemented!');
  },
});

export const AuthProvider = (
  props: React.PropsWithChildren<unknown>,
): ReactElement => {
  const { children } = props;
  const [auth, setAuth] = useState<OptionalAuthType>(undefined);
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
