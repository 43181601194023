import React from 'react';
import useQuiz from './api/useQuiz';

export interface IUsePoints {
  retrievePointsBackend: () => void;
  addPoints: (addedPoints: number) => void;
  points: number;
  setPoints: React.Dispatch<React.SetStateAction<number>>;
}

const usePoints = (): IUsePoints => {
  const [points, setPoints] = React.useState(0);
  const { getPoints } = useQuiz();

  const retrievePointsBackend = () => {
    getPoints().then((resp) => {
      if ('errors' in resp) {
        setPoints(0);
        console.log(resp);
        return;
      }
      setPoints(resp.points);
    });
  };

  const addPoints = (addedPoints: number) => {
    setPoints(points + addedPoints);
  };

  return {
    addPoints,
    retrievePointsBackend,
    points,
    setPoints,
  };
};

export default usePoints;
