import React, { ReactElement } from 'react';

export interface ReportMetric {
  name: string;
  value: string;
}

interface TwoColumnReportTableProps {
  metrics: ReportMetric[];
}

const TwoColumnReportTable = (
  props: TwoColumnReportTableProps,
): ReactElement => {
  const { metrics } = props;
  return (
    <div className="w-full py-2">
      {metrics.map((m, i) => (
        <div
          key={i}
          className={`rounded-md flex justify-between px-4 py-2 ${
            i % 2 == 0 ? 'bg-neutral-200' : 'bg-transparent'
          }`}
        >
          <p className="text-sm text-neutral-900 font-medium">{m.name}</p>
          <p className="text-sm text-neutral-700 font-semibold">{m.value}</p>
        </div>
      ))}
    </div>
  );
};

export default TwoColumnReportTable;
