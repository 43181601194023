import React, { ReactElement, useEffect, useState } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { ReactComponent as SpinnerIcon } from '../icons/spinner.svg';
import { ReactComponent as TrashIcon } from '../icons/trash.svg';
import { ReactComponent as CheckIcon } from '../icons/check.svg';

interface PrintImageThumbnail {
  image: string;
  itemId: string;
  selected: boolean;
  loading?: boolean;
  submittedAt?: number;
  interactive?: boolean;
  defaultBorderColor?: string;
  outlinedBorderColor?: string;
  isInHorizontalScroll?: boolean;
  className?: string;
  onClick?: (id: string) => void;
  onDelete?: (id: string) => void;
}

const PrintImageThumbnail = (props: PrintImageThumbnail): ReactElement => {
  const {
    image,
    itemId,
    loading,
    selected,
    onClick,
    onDelete,
    interactive,
    className,
    submittedAt,
    isInHorizontalScroll,
    defaultBorderColor,
    outlinedBorderColor,
  } = props;

  const now = Math.floor(Date.now() / 1000);
  const [processingTime, setProcessingTime] = useState<number>(
    now - (submittedAt ? submittedAt : now),
  );

  if (isInHorizontalScroll) {
    const visibility = React.useContext(VisibilityContext);
    if (visibility && visibility.isItemVisible) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const visible = visibility.isItemVisible(itemId);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      setProcessingTime(Math.max(now - (submittedAt ? submittedAt : now), 0));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      onClick={() => {
        if (onClick) {
          onClick(itemId);
        }
      }}
      style={{ backgroundImage: `url("${image}")` }}
      className={`bg-cover w-80 rounded-xl h-48 m-2 border-2 cursor-pointer transition-colors ease-in-out duration-150 ${
        selected
          ? `border-2 ${
              outlinedBorderColor ? outlinedBorderColor : 'border-blue-600'
            } shadow-md`
          : `${
              defaultBorderColor ? defaultBorderColor : 'border-neutral-100'
            } hover:border-blue-600`
      } ${className && className}`}
    >
      {interactive && loading && (
        <div className="flex w-full justify-end select-none">
          <div className="bg-neutral-900 bg-opacity-80 rounded-lg mt-3 mr-3 p-2 text-neutral-500 hover:text-blue-500 inline-flex items-center justify-center">
            <SpinnerIcon className="animate-spin h-5 w-5 text-blue-500" />
            <p className="text-blue-500 font-semibold text-sm pl-2 pt-1">
              {processingTime} seconds
            </p>
          </div>
        </div>
      )}
      {interactive && !loading && (
        <div className="flex w-full justify-end select-none">
          <div className="bg-neutral-900 bg-opacity-80 rounded-lg mt-3 mr-3 p-2 text-green-500">
            <p>
              <CheckIcon className="h-5 w-5 inline-block" strokeWidth={2} />
              <span className="text-sm font-medium inline-block pl-1 pr-1">
                Completed
              </span>
            </p>
          </div>
          <div
            className="bg-neutral-900 bg-opacity-80 rounded-lg mt-3 mr-3 p-2 text-neutral-300 hover:text-blue-500"
            onClick={(event) => {
              event.stopPropagation();
              if (onDelete) {
                onDelete(itemId);
              }
            }}
          >
            <TrashIcon className="h-5 w-5" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PrintImageThumbnail;
