import React, { ReactElement, useEffect } from 'react';

interface RedirectProps {
  url: string;
}

export const Redirect = (props: RedirectProps): ReactElement => {
  const { url } = props;
  useEffect(() => {
    window.location.replace(url);
  }, []);
  return <></>;
};
