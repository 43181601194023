import React, { ReactElement, ReactNode } from 'react';

interface NotificationProps {
  icon?: ReactNode;
  color: string; // Tailwind css color
  text?: string;
  textElement?: ReactNode;
}

const Notification = (props: NotificationProps): ReactElement => {
  const { text, color, icon, textElement } = props;

  return (
    <div
      className={`py-2 px-2 border border-${color} bg-${color} rounded-md my-2 inline-flex justify-start place-items-start w-full`}
    >
      {icon}
      <p
        className={`text-sm text-neutral-100 font-semibold pl-2`}
        style={{ paddingTop: 3 }}
      >
        {textElement || text || 'Empty notification message :('}
      </p>
    </div>
  );
};

export default Notification;
