import { ClassificationCategory, Inference } from 'common/APITypes';
import { Vector2D } from 'common/Geometry';
import React, { ReactElement, useEffect } from 'react';
import {
  Stage,
  Layer,
  Image,
  Group,
  Label,
  Rect,
  Tag,
  Text,
} from 'react-konva';

export interface AnnotatorResultProps {
  inferences: Inference[];
  img: string;
  width: number;
  height: number;
  containerWidth?: number;
  containerHeight?: number;
  isOpen: boolean;
  categories: ClassificationCategory[];
}

const projectInference = (
  a: Inference,
  from: Vector2D,
  to: Vector2D,
): Inference => {
  const width = a.xmax - a.xmin;
  const height = a.ymax - a.ymin;
  const adjustedX = (a.xmin / from.x) * to.x;
  const adjustedY = (a.ymin / from.y) * to.y;
  const adjustedWidth = (width / from.x) * to.x;
  const adjustedHeight = (height / from.y) * to.y;
  return {
    className: a.className,
    xmin: adjustedX,
    ymin: adjustedY,
    xmax: adjustedX + adjustedWidth,
    ymax: adjustedY + adjustedHeight,
  };
};

const projectInferences = (a: Inference[], from: Vector2D, to: Vector2D) => {
  return a.map((a) => projectInference(a, from, to));
};

export const AnnotatorResult = (props: AnnotatorResultProps): ReactElement => {
  const {
    inferences,
    img,
    categories,
    containerHeight,
    containerWidth,
    width,
    height,
    isOpen,
  } = props;
  const [annotations, setAnnotations] = React.useState<Inference[]>(
    projectInferences(
      inferences,
      { x: width, y: height },
      { x: containerWidth || width, y: containerHeight || height },
    ),
  );
  const [canvasDimensions, setCanvasDimensions] = React.useState<{
    width: number;
    height: number;
  }>({
    width: width,
    height: height,
  });

  const updateBackgroundImage = (): HTMLImageElement => {
    const bg = new window.Image(
      canvasDimensions.width,
      canvasDimensions.height,
    );
    bg.src = img;
    return bg;
  };

  const background = React.useRef<HTMLImageElement | undefined>(
    updateBackgroundImage(),
  );

  // When the background image changes, we update our ref
  useEffect(() => {
    background.current = updateBackgroundImage();
  }, [img]);

  // Whenever our container width, height, or inferences change we need to re-draw
  useEffect(() => {
    if (
      containerWidth &&
      containerHeight &&
      containerWidth > 0 &&
      containerHeight > 0
    ) {
      const inverseCanvasAspectRatio = height / width;
      const adjustedContainerHeight = containerWidth * inverseCanvasAspectRatio;
      // Update all of our annotations
      setAnnotations(
        projectInferences(
          inferences,
          { x: width, y: height },
          { x: containerWidth, y: adjustedContainerHeight },
        ),
      );
      console.log(`setting width=${containerWidth} height=${containerHeight}`);
      setCanvasDimensions({
        width: containerWidth,
        height: adjustedContainerHeight,
      });
    } else {
      console.log(
        `Not ready for render containerWidth=${containerWidth} containerHeight=${containerHeight}`,
      );
      setCanvasDimensions({
        width: canvasDimensions.width,
        height: canvasDimensions.height,
      });
    }
  }, [containerWidth, inferences, isOpen]);

  /*console.log(
    `id=${customId} containerWidth=${containerWidth} canvasWidth=${canvasDimensions.width}`,
  );*/

  return (
    <div className="mt-2">
      <Stage
        width={canvasDimensions.width}
        height={canvasDimensions.height}
        x={0}
        y={0}
        style={{ borderRadius: 15, overflow: 'hidden' }}
      >
        <Layer>
          <Image
            id="background"
            image={background.current}
            width={canvasDimensions.width}
            height={canvasDimensions.height}
            x={0}
            y={0}
          />
        </Layer>
        <Layer>
          {annotations.map((inference, index) => {
            // The category this refers to. We store some additional information
            // about the category on the frontend, such as the color
            const category = categories.find(
              (c) => c.name === inference.className,
            );

            return (
              <Group key={index}>
                <Label x={inference.xmin} y={inference.ymin - 25}>
                  <Tag fill={category?.color || '#737373'} cornerRadius={5} />
                  <Text
                    text={category?.name || inference.className}
                    fontSize={12}
                    padding={5}
                    fill="white"
                  />
                </Label>
                <Rect
                  x={inference.xmin}
                  y={inference.ymin}
                  width={inference.xmax - inference.xmin}
                  height={inference.ymax - inference.ymin}
                  fill="rgba(0, 0, 0, 0.15)"
                  strokeWidth={2}
                  stroke={category?.color || '#737373'}
                />
              </Group>
            );
          })}
        </Layer>
      </Stage>
    </div>
  );
};
