import useMarketingAPI from 'hooks/useMarketingAPI';
import React, { ReactElement } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import Popup from 'reactjs-popup';

export interface NewPrintleUserModal {
  hintEmail: string;
  open: boolean;
  onClose?: () => void;
}

export const NewPrintleUserModal = (
  props: NewPrintleUserModal,
): ReactElement => {
  const { hintEmail, open, onClose } = props;
  const [subscribeEmail, setSubscribeEmail] = React.useState<boolean>(true);
  const { getMarkettingSettings, storeMarketingSettings } = useMarketingAPI();

  const onModalActionClose = () => {
    const markettingSettings = getMarkettingSettings();
    if (markettingSettings.newUser) {
      markettingSettings.newUser = false;
    }
    console.log(markettingSettings);
    storeMarketingSettings(markettingSettings);
    onClose && onClose();
  };

  return (
    <Popup
      modal
      open={open}
      closeOnDocumentClick
      onClose={() => {
        onModalActionClose();
      }}
    >
      <MailchimpSubscribe
        url={process.env.REACT_APP_MAIL_CHIMP_FORM || ''}
        render={({ subscribe, status, message }) => (
          <div className="bg-neutral-50 py-6 px-7 m-4 rounded-lg max-w-2xl">
            {(status === 'sending' || status === null) && (
              <>
                <p className="font-semibold text-3xl text-neutral-900">
                  Welcome to 3DPrintle Challenger Mode!
                </p>
                <p className="pt-1 text-lg text-neutral-900">
                  Join the QuinlyVision community and recieve further benefits.
                </p>
                <ul className="font-normal text-lg text-neutral-800 pt-2 pl-4 list-outside list-disc">
                  <li className="pt-1">
                    Exclusive look at QuinlyVision development.
                  </li>
                  <li className="pt-1">
                    Join the conversation. Send us your feedback!
                  </li>
                  <li className="pt-1">
                    Be the first to learn how you can get this for your printer.
                  </li>
                </ul>
                <div className="mt-4 inline-flex items-center ml-2">
                  <input
                    type="checkbox"
                    className="rounded text-blue-500 cursor-pointer mt-1 w-5 h-5"
                    checked={subscribeEmail}
                    onChange={(e) => {
                      setSubscribeEmail(e.target.checked);
                    }}
                  />
                  <p className="pt-1 ml-2 text-neutral-800">
                    Send me emails about QuinlyVision
                  </p>
                </div>
                <div className="pt-4">
                  <button
                    className="bg-blue-600 hover:bg-blue-700 font-medium text-neutral-100 py-2 px-3 rounded-md"
                    onClick={() => {
                      if (subscribeEmail) {
                        subscribe({ EMAIL: hintEmail });
                        const markettingSettings = getMarkettingSettings();
                        markettingSettings.newUser = false;
                        markettingSettings.onEmailList = true;
                        storeMarketingSettings(markettingSettings);
                      } else {
                        onModalActionClose();
                      }
                    }}
                  >
                    Continue To 3DPrintle
                  </button>
                </div>
              </>
            )}
            {status === 'error' && (
              <div className="p-6 text-left">
                <p className="font-semibold text-2xl text-neutral-800">
                  Something went wrong{' '}
                  <span role="img" aria-label="sheep">
                    😟
                  </span>
                  . We couldn&apos;t subscribe you now, but feel free to try
                  again later.
                </p>
                <div
                  style={{ color: 'red' }}
                  dangerouslySetInnerHTML={{
                    __html: message ? message.toString() : '',
                  }}
                />
                <button
                  className="bg-blue-500 mt-4 hover:bg-blue-600 font-medium text-neutral-100 py-2 px-3 rounded-md ml-2"
                  onClick={() => {
                    onModalActionClose();
                  }}
                >
                  Okay!
                </button>
              </div>
            )}
            {status === 'success' && (
              <div className="p-6 text-left">
                <p className="font-semibold text-2xl text-neutral-800">
                  Thanks for subscribing!{' '}
                  <span role="img" aria-label="sheep">
                    🥳
                  </span>{' '}
                  Check your email for a confirmation! We&apos;ll update you
                  when new QuinlyVision features are ready for you to try.
                </p>
                <button
                  className="bg-blue-500 mt-4 hover:bg-blue-600 font-medium text-neutral-100 py-2 px-6 rounded-md"
                  onClick={() => {
                    onModalActionClose();
                  }}
                >
                  Okay!
                </button>
              </div>
            )}
          </div>
        )}
      />
    </Popup>
  );
};

export default NewPrintleUserModal;
