import React, { ReactElement } from 'react';

export interface GradientProgressBarProps {
  gradientFrom: string; // Tailwind color
  gradientTo: string; // Tailwind color
  progress: number | null; // Decimal value (ex... 0.15, 0.99)
}

const GradientProgressBar = (props: GradientProgressBarProps): ReactElement => {
  const { gradientFrom, gradientTo, progress } = props;
  const roundedProgress =
    progress == null ? 0 : Math.round(progress * 1000) / 10;

  return (
    <div className={`h-7 flex w-full`}>
      <div
        className={`rounded-l-md bg-gradient-to-r from-${gradientFrom} to-${gradientTo}`}
        style={{ width: roundedProgress + '%' }}
      />
      <div
        className={`bg-${gradientFrom} rounded-r-md opacity-40 flex-1 `}
      ></div>
    </div>
  );
};

export default GradientProgressBar;
