import React, { ReactElement } from 'react';
import { ReactComponent as WarningIcon } from 'icons/warning.svg';
import Popup from 'reactjs-popup';

interface CategoryButtonProps {
  children?: React.ReactNode;
  onSelect?: () => void;
  displayName: string;
  isSelected: boolean;
  primaryColor: string;
  disabled?: boolean;
}

const CategoryButton = (props: CategoryButtonProps): ReactElement => {
  const {
    isSelected,
    primaryColor,
    displayName,
    children,
    onSelect,
    disabled,
  } = props;
  const [isInfoActive, setIsInfoActive] = React.useState<boolean>(false);

  const categoryClicked = () => {
    onSelect && onSelect();
  };

  const infoClicked = () => {
    setIsInfoActive(true);
  };

  return (
    <div
      className={`w-full ${
        disabled !== undefined && disabled ? 'bg-gray-400' : 'bg-neutral-800'
      } py-1 px-2 rounded-md mt-1 flex items-center ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } ${!isSelected && !disabled ? 'hover:bg-neutral-700' : ''}`}
      style={{ backgroundColor: isSelected ? primaryColor : undefined }}
      onClick={() => {
        if (!disabled) {
          categoryClicked();
        }
      }}
    >
      <button className="bg-neutral-700 rounded-lg p-1 hover:bg-neutral-900">
        <WarningIcon
          onClick={infoClicked}
          className="text-yellow-400"
          width={24}
          height={24}
          strokeWidth={2}
        />
      </button>
      <p className="ml-2 text-neutral-50 font-medium flex-1">{displayName}</p>
      <Popup
        open={isInfoActive}
        closeOnDocumentClick
        onClose={() => {
          setIsInfoActive(false);
        }}
      >
        <div
          className="bg-neutral-50 py-8 px-6 rounded-md max-w-lg overflow-y-auto"
          style={{ maxHeight: 'calc(100vh - 50px)' }}
        >
          {children}
        </div>
      </Popup>
    </div>
  );
};

export default CategoryButton;
