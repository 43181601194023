import React, { useContext } from 'react';
import { APIErrorResponse } from 'common/APITypes';
import useAuth from 'hooks/useAuth';
import { AuthContext } from 'providers/AuthContext';

const baseURL = `${process.env.REACT_APP_API_REST_PROTOCOL}://${
  process.env.REACT_APP_API_URL || ''
}/api/v1/minigames/quiz`;

export interface QuizImageSnapshot {
  id: string;
  width: number;
  height: number;
  image: string;
  maxPoints: number;
  base: Inference[];
}

export interface Inference {
  className: string;
  xmax: number;
  xmin: number;
  ymax: number;
  ymin: number;
  _id?: string;
}

export interface QuizImagesResponse {
  id: string;
  active: boolean;
  mode: string;
  submitted: {
    inferenceId: string;
    points: number;
    maxPoints: number;
    iou: number;
    inferences: Inference[];
  }[];
  inferences: QuizImageSnapshot[];
}

export interface QuizSubmitResponse {
  quiz: QuizImageSnapshot;
  points: number;
  maxPoints: number;
  submitted: Inference[];
  expected: Inference[];
}

export interface QuizPointsResponse {
  points: number;
}

export interface QuizExperienceResponse {
  level: number;
  experienceCurrentLevel: number;
  experienceToNextLevel: number;
  totalExperience: number;
  userContribution: number;
}

export interface QuizSummaryResponse {
  percentile: number;
  averageScore: number;
  submissions: {
    inferenceId: string;
    points: number;
    maxPoints: number;
    iou: number;
    image: string;
    width: number;
    height: number;
    inferences: Inference[];
    expected: Inference[];
  }[];
}

export interface QuizCompleteResponse {
  _id: string;
  __v: number;
  v: number;
  quiz: string;
  submittedBy: string;
  completed: boolean;
  createdAt: string;
  updatedAt: string;
  mode: string;
  id: string;
  submissions: {
    quizId: string;
    points: number;
    maxPoints: number;
    image: string;
    inferences: Inference[];
  }[];
}

export interface QuizNextAvailableResponse {
  id: string;
  availableAt: string;
}

export type PrintleDifficulty = 'easy' | 'hard';

export interface IUseQuiz {
  getOngoingQuiz: () => Promise<QuizImagesResponse | APIErrorResponse>;
  getNewQuiz: (
    difficulty: PrintleDifficulty,
  ) => Promise<QuizImagesResponse | APIErrorResponse>;
  submitQuizResponse: (
    quizId: string,
    inferenceId: string,
    inferences: Inference[],
  ) => Promise<QuizSubmitResponse | APIErrorResponse>;
  completeQuiz: (
    id: string,
  ) => Promise<QuizCompleteResponse | APIErrorResponse>;
  getSummary: (id?: string) => Promise<QuizSummaryResponse | APIErrorResponse>;
  getSummaryGuest: (
    points: number,
  ) => Promise<QuizSummaryResponse | APIErrorResponse>;
  getPoints: () => Promise<QuizPointsResponse | APIErrorResponse>;
  getExperienceStats: () => Promise<QuizExperienceResponse | APIErrorResponse>;
  getNextAvailableQuiz: () => Promise<
    QuizNextAvailableResponse | APIErrorResponse
  >;
  globalQuizExperience: QuizExperienceResponse | undefined;
  setGlobalQuizExperience: React.Dispatch<
    React.SetStateAction<QuizExperienceResponse | undefined>
  >;
}

const useQuiz = (): IUseQuiz => {
  const { getCredentials } = useAuth();
  const [globalQuizExperience, setGlobalQuizExperience] = React.useState<
    QuizExperienceResponse | undefined
  >();
  const { auth } = useContext(AuthContext);

  const getOngoingQuiz = (): Promise<QuizImagesResponse | APIErrorResponse> => {
    // Either anonymous or authenticated requests can be made
    const credentials = getCredentials();
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${credentials?.token}`,
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  };

  const getNewQuiz = (
    difficulty: PrintleDifficulty,
  ): Promise<QuizImagesResponse | APIErrorResponse> => {
    // Either anonymous or authenticated requests can be made
    let req: RequestInit;
    if (auth && !auth.guest) {
      const credentials = getCredentials();
      req = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${credentials?.token}`,
        },
      };
    } else {
      req = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
    }

    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/new?difficulty=${difficulty}`, req)
        .then((resp) => resp.json())
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  };

  const submitQuizResponse = (
    quizId: string,
    inferenceId: string,
    inferences: Inference[],
  ): Promise<QuizSubmitResponse | APIErrorResponse> => {
    // Either anonymous or authenticated requests can be made
    let req: RequestInit;
    if (auth && !auth.guest) {
      const credentials = getCredentials();
      req = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${credentials?.token}`,
        },
        body: JSON.stringify({
          inferences,
        }),
      };
    } else {
      req = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inferences,
        }),
      };
    }

    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/submit/${quizId}/${inferenceId}`, req)
        .then((resp) => resp.json())
        .then((resp) => {
          if ('errors' in resp) {
            resolve(resp);
          }
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  };

  const completeQuiz = (
    id: string,
  ): Promise<QuizCompleteResponse | APIErrorResponse> => {
    const credentials = getCredentials();
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/complete/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${credentials?.token}`,
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if ('errors' in resp) {
            resolve(resp);
          }
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  };

  const getPoints = (): Promise<QuizPointsResponse | APIErrorResponse> => {
    const credentials = getCredentials();
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/stats/@me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${credentials?.token}`,
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if ('errors' in resp) {
            resolve(resp);
          }
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  };

  const getSummary = (
    id?: string,
  ): Promise<QuizSummaryResponse | APIErrorResponse> => {
    const credentials = getCredentials();
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/stats/summary/${id || '@latest'}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${credentials?.token}`,
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if ('errors' in resp) {
            resolve(resp);
          }
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  };

  // Gets the summary for guest users
  const getSummaryGuest = (
    points: number,
  ): Promise<QuizSummaryResponse | APIErrorResponse> => {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/stats/summary/@latest?points=${points}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if ('errors' in resp) {
            resolve(resp);
          }
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  };

  const getExperienceStats = (): Promise<
    QuizExperienceResponse | APIErrorResponse
  > => {
    // Either anonymous or authenticated requests can be made
    let req: RequestInit;
    if (auth && !auth.guest) {
      const credentials = getCredentials();
      req = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${credentials?.token}`,
        },
      };
    } else {
      req = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
    }

    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/stats/experience`, req)
        .then((resp) => resp.json())
        .then((resp) => {
          if ('errors' in resp) {
            resolve(resp);
          }
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  };

  const getNextAvailableQuiz = (): Promise<
    QuizNextAvailableResponse | APIErrorResponse
  > => {
    // Either anonymous or authenticated requests can be made
    let req: RequestInit;
    if (auth && !auth.guest) {
      const credentials = getCredentials();
      req = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${credentials?.token}`,
        },
      };
    } else {
      req = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
    }

    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/next`, req)
        .then((resp) => resp.json())
        .then((resp) => {
          if ('errors' in resp) {
            resolve(resp);
          }
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  };

  return {
    getNewQuiz,
    getOngoingQuiz,
    submitQuizResponse,
    getPoints,
    getExperienceStats,
    globalQuizExperience,
    setGlobalQuizExperience,
    getSummary,
    getSummaryGuest,
    completeQuiz,
    getNextAvailableQuiz,
  };
};

export default useQuiz;
