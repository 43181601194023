import { FailurePreventionTips } from 'components/AnalyticsSidebar/AnalyticsSidebar';
import { ReportMetric } from 'components/TwoColumnReportTable';

export interface ClassificationMetaData {
  displayName: string;
  description: string;
  colors: {
    primary: string;
    primaryDarker: string;
  };
  tips?: FailurePreventionTips;
}

export const classificationNameToMetaData = (
  className: string,
): ClassificationMetaData => {
  switch (className) {
    case 'model': {
      return {
        displayName: 'Model',
        description:
          'We detected that this part of the image is the model you are printing',
        colors: {
          primary: 'green-500',
          primaryDarker: 'green-600',
        },
      };
    }
    case 'spaghetti': {
      return {
        displayName: 'Spaghetti',
        description:
          'Filament that was misplaced by the extruder. Looks like spaghetti.',
        colors: {
          primary: 'green-400',
          primaryDarker: 'green-500',
        },
        tips: {
          title: 'Fix Spaghetti',
          description:
            'Print has detached during printing, or extrusion is unsupported',
          fixes: [
            {
              problem: 'Possible Part Detach',
              solution: 'Increase bed adhesion.',
            },
            {
              problem: 'Possible Part Breakage',
              solution: 'Avoid printing tall, thin structures.',
            },
            {
              problem: 'Possible Bad First Layer',
              solution: 'Reduce first layer speed to improve bed adhesion.',
            },
          ],
        },
      };
    }
    case 'warping': {
      return {
        displayName: 'Warping',
        description:
          'Temperature differences in the plastic causing shrinking in portions of the print',
        colors: {
          primary: 'blue-400',
          primaryDarker: 'blue-500',
        },
        tips: {
          title: 'Fix Warping',
          description:
            'Temperature differences in the plastic causing shrinking in portions of the print',
          fixes: [
            {
              problem: 'Incorrect Bed & Nozzle Temperature',
              solution:
                'Verify bed and nozzle temperatures are correct for material type.',
            },
            {
              problem: 'Part Not Rigid Enough',
              solution:
                'Increase infill % and wall thickness to increase part rigidity.',
            },
            {
              problem: 'Cold Ambient Temperature',
              solution:
                'Avoid cold ambient temperature. Printer enclosure may be required.',
            },
          ],
        },
      };
    }
    case 'nozzle-blob': {
      return {
        displayName: 'Nozzle Blob',
        description:
          'Material accumulated over the nozzle causing it to not extrude properly',
        colors: {
          primary: 'yellow-400',
          primaryDarker: 'yellow-500',
        },
        tips: {
          title: 'Fix Nozzle Blob',
          description:
            'Material accumulated over the nozzle causing it to not extrude properly',
          fixes: [
            {
              problem: 'Incorrect Bed & Nozzle Temperatures',
              solution:
                'Verify bed and nozzle temperatures are correct for material type.',
            },
            {
              problem: 'Bad First Layer',
              solution: 'Reduce first layer speed to improve bed adhesion.',
            },
            {
              problem: 'Poor First Layer Adhesion',
              solution:
                'Increase nozzle temperature on first layer to improve bed adhesion.',
            },
          ],
        },
      };
    }
    case 'no-extrusion': {
      return {
        displayName: 'No Extrusion',
        description: 'The nozzle is not extruding filament',
        colors: {
          primary: 'pink-400',
          primaryDarker: 'pink-500',
        },
        tips: {
          title: 'Fix No Extrusion',
          description: 'The nozzle is not extruding filament',
          fixes: [
            {
              problem: 'Nozzle Clog',
              solution: 'Perform cold pull.',
            },
            {
              problem: 'Heat Creep',
              solution:
                'Make sure the hotend cooling fan is effectively cooling the heatsink.',
            },
            {
              problem: 'Filament Tangle',
              solution: 'Check if filament spool is tangled.',
            },
          ],
        },
      };
    }
    case 'under-extrusion': {
      return {
        displayName: 'Under Extrusion',
        description: 'The nozzle is not extruding enough filament',
        colors: {
          primary: 'rose-400',
          primaryDarker: 'rose-500',
        },
        tips: {
          title: 'Fix Under Extrusion',
          description: 'The nozzle is not extruding enough filament',
          fixes: [
            {
              problem: 'Non-Optimal Slicer Settings',
              solution:
                'Check flow, extrusion multipliers, and line width settings in slicer.',
            },
            {
              problem: 'Incorrect Filament Diameter',
              solution: 'Verify filament diameter is set correctly in slicer.',
            },
            {
              problem: 'Excessive Friction In Filament Path',
              solution: 'Reduce any excessive friction in the filament path.',
            },
            {
              problem: 'Incorrect Extruder Gear Tension',
              solution: 'Check if your extruder gear has correct tension.',
            },
            {
              problem: 'Partial Nozzle Clog',
              solution:
                'Possible partial clog due to debris in nozzle, perform cold pull to clean nozzle.',
            },
          ],
        },
      };
    }
    default: {
      return {
        displayName: className,
        description: '',
        colors: {
          primary: 'neutral-400',
          primaryDarker: 'neutral-500',
        },
      };
    }
  }
};

export const imageToMetrics = (image: string): ReportMetric[] => {
  switch (image) {
    case 'demo-1-raw.jpg': {
      return [
        {
          name: 'Hours Saved',
          value: '6',
        },
        {
          name: 'Filament Saved',
          value: '180g',
        },
      ];
    }
    case 'demo-2-raw.jpg': {
      return [
        {
          name: 'Hours Saved',
          value: '2',
        },
        {
          name: 'Filament Saved',
          value: '50g',
        },
      ];
    }
    case 'demo-3-raw.jpg': {
      return [
        {
          name: 'Hours Saved',
          value: '1',
        },
        {
          name: 'Filament Saved',
          value: '20g',
        },
      ];
    }
    case 'demo-4-raw.jpg': {
      return [
        {
          name: 'Hours Saved',
          value: '0.5',
        },
        {
          name: 'Filament Saved',
          value: '50g',
        },
      ];
    }
    case 'demo-5-raw.jpg': {
      return [
        {
          name: 'Hours Saved',
          value: '0.5',
        },
        {
          name: 'Filament Saved',
          value: '50g',
        },
      ];
    }
    default: {
      return [];
    }
  }
};
