import { useState } from 'react';

const SESSION_KEY_EMAIL = 'qv.marketing.email';

interface MarketEmailModal {
  wasOffered: boolean;
}

interface UseMarketingEmailHook {
  isEmailMarketingModalOpen: boolean;
  createEmailMarketingModal: (force?: boolean) => void;
  closeEmailMarketingModal: () => void;
}

const useMarketingEmail = (initial: boolean): UseMarketingEmailHook => {
  const [open, setOpen] = useState<boolean>(initial);

  // Get the marketing modal session if it exists
  const getMarketingModalSession = (): MarketEmailModal => {
    const session = sessionStorage.getItem(SESSION_KEY_EMAIL);
    const emptySession: MarketEmailModal = {
      wasOffered: false,
    };
    if (!session) {
      return emptySession;
    }
    try {
      const modalSession: MarketEmailModal = JSON.parse(session);
      return modalSession;
    } catch (err) {
      console.log(err);
      return emptySession;
    }
  };

  // Store a marketing modal session to the browser session storage
  const storeMarketingModalSession = (session: MarketEmailModal) => {
    try {
      sessionStorage.setItem(SESSION_KEY_EMAIL, JSON.stringify(session));
    } catch (err) {
      console.log(err);
    }
  };

  // Open a marketing modal if the user wasn't prompted to this session
  const createEmailMarketingModal = (force?: boolean) => {
    const currentSession = getMarketingModalSession();
    const { wasOffered } = currentSession;
    if (wasOffered && !force) {
      return;
    }
    setOpen(true);
    storeMarketingModalSession({ wasOffered: true });
  };

  const closeEmailMarketingModal = () => {
    if (open) {
      setOpen(false);
    }
  };

  return {
    isEmailMarketingModalOpen: open,
    createEmailMarketingModal,
    closeEmailMarketingModal,
  };
};

export default useMarketingEmail;
