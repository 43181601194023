import React, { ReactElement } from 'react';

interface NumericBubbleProps {
  color: string; // Tailwind color
  textSize: string; // Tailwind size
  bubbleSize: string; // Tailwind size
  text: string;
}

const NumericBubble = (props: NumericBubbleProps): ReactElement => {
  const { color, text, bubbleSize } = props;

  return (
    <div
      className={`w-${bubbleSize} h-${bubbleSize} bg-${color} border-${color} border-2 rounded-full flex justify-center items-center select-none`}
    >
      <p className={`font-bold text-neutral-100`} style={{ paddingTop: 2 }}>
        {text}
      </p>
    </div>
  );
};

export default NumericBubble;
