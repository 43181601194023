import React, { createContext, ReactElement, useState } from 'react';

// All of the data we carry in this context
export type ModalContextData = {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

// Initial context state
export const ModalContext = createContext<ModalContextData>({
  modal: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setModal: () => {
    console.log('Unimplemented!');
  },
});

export const ModalProvider = (
  props: React.PropsWithChildren<unknown>,
): ReactElement => {
  const { children } = props;
  const [modal, setModal] = useState<boolean>(false);
  return (
    <ModalContext.Provider value={{ modal, setModal }}>
      {children}
    </ModalContext.Provider>
  );
};
