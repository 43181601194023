import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Annotator from './Annotator';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GoogleCallback from 'components/auth/GoogleCallback';
import { AuthProvider } from './providers/AuthContext';
import { ModalProvider } from 'providers/ModalContext';
import { Redirect } from 'components/Redirect';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ModalProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<Redirect url="https://www.3dque.com/quinlyvision" />}
            />
            <Route path="/demo" element={<App />} />
            <Route path="/quiz" element={<Annotator />} />
            <Route path="/oauth/google" element={<GoogleCallback />} />
          </Routes>
        </BrowserRouter>
      </ModalProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
