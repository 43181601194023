import React, { ReactElement } from 'react';
import { InterfaceCacheEntry } from '../App';
import imageToAssetName from '../util/ImageImporter';
import { ReactComponent as SpinnerIcon } from '../icons/spinner.svg';
import { ReactComponent as PlayIcon } from '../icons/click.svg';
import { ReactComponent as ImageIcon } from '../icons/image.svg';
import { classificationNameToMetaData } from 'util/ClassificationMetadata';
import PrintersImage from 'images/printers.jpg';
interface PrintSnapshotInspectorProps {
  report?: InterfaceCacheEntry;
  onRequestRandomImage?: () => void;
  onTutorialStart?: () => void;
  onGalleryOpen?: () => void;
  onGetInTouchOpen?: () => void;
}

const PrintShapshotInspector = (
  props: PrintSnapshotInspectorProps,
): ReactElement => {
  const { report, onTutorialStart, onGalleryOpen, onGetInTouchOpen } = props;

  return (
    <div className="tutorial-step-3 flex-1 bg-neutral-200 mb-5 md:mb-4 rounded-lg flex flex-col justify-center items-center">
      {report && !report.response && (
        <div className="rounded-lg">
          <div className="flex flex-col justify-center items-center p-8">
            <SpinnerIcon
              className="animate-spin mt-3 mr-3 h-5 w-5 text-blue-500"
              width={50}
              height={50}
            />
            <p className="text-neutral-800 text-sm pt-4 text-center">
              We&apos;re currently processing{' '}
              <span className="text-blue-500 font-bold">
                {report.request?.source}
              </span>
              , with model{' '}
              <span className="text-blue-500 font-bold">
                {report.request?.model}
              </span>
            </p>
            <p className="text-sm pt-4 italic max-w-xl text-neutral-500 text-center">
              Please wait ... QuinlyVision is working through the queue! Your
              image is being processed live.
            </p>
          </div>
        </div>
      )}
      {report && report.response && (
        <div className="relative left-0 top-0 h-full">
          {report.response.result
            .filter((r) => r.name !== 'model')
            .map((r, i) => {
              const zIndex = i + 1;
              // All values are calculated in % to make sure they scale dynamically
              const yOffset = (r.ymin / 480) * 100;
              const xOffset = (r.xmin / 640) * 100;
              const width = (Math.abs(r.xmax - r.xmin) / 640) * 100;
              const height = (Math.abs(r.ymax - r.ymin) / 480) * 100;
              // Get the preferred color for this failure type
              const { colors, displayName } = classificationNameToMetaData(
                r.name,
              );
              const { primary, primaryDarker } = colors;

              return (
                <div
                  className={`absolute inline-block border-2 bg-${primary} bg-opacity-40 border-${primary} rounded-md select-none overflow-visible`}
                  key={i}
                  style={{
                    zIndex: zIndex,
                    top: `${yOffset}%`,
                    left: `${xOffset}%`,
                    width: `${width}%`,
                    height: `${height}%`,
                  }}
                >
                  <div
                    className={`absolute -top-9 -left-1 px-2 rounded-md bg-gradient-to-br from-${primary} to-${primaryDarker} overflow-visible`}
                  >
                    <p className="text-neutral-200 px-2 py-1 font-medium whitespace-nowrap">
                      {displayName}
                    </p>
                  </div>
                </div>
              );
            })}
          <img
            className="select-none"
            src={imageToAssetName(report.request?.source || '')}
            style={{
              maxWidth: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      )}
      {!report && (
        <div className="w-full h-full relative flex justify-center items-center flex-1">
          <img
            src={PrintersImage}
            className="absolute object-cover w-full h-full opacity-20 filter blur-md"
            style={{ minWidth: '100%', minHeight: '100%' }}
          />
          <div className="w-full md:w-3/4 lg:w-1/2 md:max-w-3xl relative z-10 px-8 py-16">
            <p className="text-4xl font-semibold text-neutral-900">
              Welcome to{' '}
              <span className="text-blue-500 relative">QuinlyVision AI</span>{' '}
              Beta Demo
            </p>
            <ul className="list-disc list-outside ml-8">
              <li className="text-neutral-800 font-medium text-2xl pt-2">
                The only system that detects 14 types of 3D print failures,
                alerts the operator, and gives helpful tips and fixes.
              </li>
              <li className="text-neutral-800 font-medium text-2xl pt-2">
                QuinlyVision AI can be used standalone or with Quinly
                automation.
              </li>
            </ul>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-2 mt-5">
              <button
                className="bg-blue-500 hover:bg-blue-600 transition-colors delay-75 animate-border-pulsate border-2 text-neutral-100 text-xl font-semibold py-2 px-4 rounded-lg text-center"
                onClick={() => {
                  onTutorialStart && onTutorialStart();
                }}
              >
                <PlayIcon
                  className="inline-block"
                  strokeWidth={2}
                  width={25}
                  height={25}
                />
                <p className="inline-block text pl-2 vertical align-middle">
                  Try Live Demo
                </p>
              </button>
              <button
                className="bg-neutral-800 hover:bg-neutral-700 transition-colors delay-75 text-neutral-100 text-xl font-semibold py-2 px-4 rounded-lg text-center"
                onClick={() => {
                  onGalleryOpen && onGalleryOpen();
                }}
              >
                <ImageIcon
                  className="inline-block"
                  strokeWidth={2}
                  width={25}
                  height={25}
                />
                <p className="inline-block text pl-2 vertical align-middle text-center">
                  Latest Print Failure Collection
                </p>
              </button>
              <button
                className="bg-neutral-200 hover:bg-neutral-300 transition-colors delay-75 text-neutral-800 text-xl font-semibold py-2 px-4 rounded-lg text-center"
                onClick={() => {
                  onGetInTouchOpen && onGetInTouchOpen();
                }}
              >
                <p className="inline-block text pl-2 vertical align-middle text-center">
                  Join Our Community
                </p>
              </button>
            </div>
            <p className="text-neutral-600 pt-4 leading-6 max-w-lg">
              New print failures loaded into the gallery daily. Check back
              frequently to see how fast QuinlyVision AI is learning!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrintShapshotInspector;
