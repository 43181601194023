import { ClassificationCategory } from 'common/APITypes';
import React, { ReactElement } from 'react';
import { ReactComponent as LockIcon } from 'icons/lock-closed-outline.svg';

export interface FailVideosProps {
  className?: string | undefined;
  categories: ClassificationCategory[];
}

export const FailVideos = (props: FailVideosProps): ReactElement => {
  const { className, categories } = props;
  const [selected, setSelected] = React.useState<string | undefined>(
    categories.find((c) => c.videoURL)?.name,
  );

  const onCategorySelect = (id: string) => {
    setSelected(id);
  };

  const selectedCategory = categories.find((c) => c.name === selected);

  return (
    <div className={`${className} px-2 lg:px-10 flex justify-center`}>
      <div className="max-w-7xl flex flex-col w-full justify-center">
        <div className="w-full flex justify-center">
          <h1 className="text-5xl font-semibold max-w-4xl text-center">
            Learn More About How QuinlyVision Detects Failures
          </h1>
        </div>
        <div className="flex flex-row flex-wrap lg:flex-nowrap justify-center py-7">
          <div className="flex-col w-full">
            <h1 className="text-3xl font-medium">
              {selectedCategory?.displayName}
            </h1>
            {selectedCategory?.videoURL && (
              <div
                className="relative w-full h-0"
                style={{ paddingBottom: '56.25%' }}
              >
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  width="700"
                  height="394"
                  src={selectedCategory.videoURL}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            )}
          </div>
          <div className="lg:max-w-sm rounded-lg px-6 py-2 w-full flex flex-col justify-start items-start bg-neutral-800 lg:ml-4 mt-11">
            <p className="text-neutral-50 font-bold text-lg pt-4 pb-2">
              Failure Type
            </p>
            {categories
              .filter((c) => c.videoURL)
              .map((c) => {
                if (c.name === selected) {
                  return (
                    <button
                      className="w-full text-left bg-blue-600 mb-1 text-neutral-50 font-medium px-4 py-2 rounded-lg"
                      key={c.name}
                    >
                      {c.displayName}
                    </button>
                  );
                } else {
                  return (
                    <button
                      className="w-full text-left bg-neutral-900 mb-1 text-neutral-50 hover:bg-neutral-800 font-medium px-4 py-2 rounded-lg"
                      key={c.name}
                      onClick={() => {
                        onCategorySelect(c.name);
                      }}
                    >
                      {c.displayName}
                    </button>
                  );
                }
              })}
            <p className="text-neutral-100 font-bold text-lg pt-4 pb-2">
              Coming Soon ...
            </p>
            {categories
              .filter((c) => !c.videoURL)
              .map((c) => (
                <button
                  className="w-full text-left bg-gray-600 text-neutral-200 mb-2 font-medium px-4 py-2 rounded-lg cursor-not-allowed inline-flex justify-start items-center"
                  key={c.name}
                >
                  <LockIcon width={25} height={25} strokeWidth={2} />
                  <p className="ml-2">{c.displayName}</p>
                </button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailVideos;
