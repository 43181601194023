import { useContext } from 'react';
import useOAuth, { OAuthResponse } from './api/useOAuth';
import useUsers from './api/useUsers';
import { AuthContext } from 'providers/AuthContext';
import { UserProfile } from 'common/APITypes';

const localStorageTokenKey = 'qv.auth';

interface IUseAuth {
  storeTokenCredentials: (tokens: OAuthResponse) => void;
  removeCredentials: () => void;
  getCredentials: () => OAuthResponse | null;
  authenticate: () => void;
  authenticateGuest: (profile: UserProfile) => void;
  unAuthenticate: () => void;
}

// A hook for managing user authentication state
const useAuth = (): IUseAuth => {
  const { setAuth } = useContext(AuthContext);
  const { me } = useUsers();
  const { refreshToken } = useOAuth();

  const storeTokenCredentials = (tokens: OAuthResponse) => {
    localStorage.setItem(localStorageTokenKey, JSON.stringify(tokens));
  };

  const removeCredentials = () => {
    localStorage.removeItem(localStorageTokenKey);
  };

  const getCredentials = (): OAuthResponse | null => {
    const value = localStorage.getItem(localStorageTokenKey);
    if (!value) {
      return null;
    }
    return <OAuthResponse>JSON.parse(value);
  };

  // Try to authenticate the user. We first check to see if the token is stored
  // in local storage, if it is, we get the user's profile. This may fail if the
  // token is expired, so we may need to use the refresh token.
  const authenticate = async () => {
    let storedToken: OAuthResponse;
    try {
      storedToken = JSON.parse(
        localStorage.getItem(localStorageTokenKey) || '',
      );
      if (!storedToken.token) {
        setAuth(undefined);
        return;
      }
    } catch (err) {
      // No token was found so we assign null
      setAuth(undefined);
      return;
    }

    // The token stored in local storage still has a chance of being valid
    if (storedToken.expiresAt > Math.round(Date.now() / 1000)) {
      // Now let's check if the token is valid by getting the user's profile. If
      // it's not we will try to refresh
      let user;
      try {
        user = await me({ token: storedToken.token });
        // At this point the token we used was still valid and so the user was authenticated
        if (!('errors' in user)) {
          setAuth({ ...user, guest: false });
          return;
        }
      } catch (err) {
        console.log(err);
      }
    }

    // Let's try to refresh the user's token for them
    let refreshedToken;
    try {
      refreshedToken = await refreshToken(storedToken.refresh);
      if ('errors' in refreshedToken) {
        setAuth(undefined);
        return;
      }
    } catch (err) {
      console.log(err);
      removeCredentials();
      setAuth(undefined);
      return;
    }

    // Update the local storage with this new authentication token
    storedToken.token = refreshedToken.token;
    storedToken.expiresAt = refreshedToken.expiresAt;
    storeTokenCredentials(storedToken);

    // Try to get the user's profile now
    let user;
    try {
      user = await me({ token: storedToken.token });
      // At this point the token we used was still valid and so the user was authenticated
      if (!('errors' in user)) {
        setAuth({ ...user, guest: false });
        return;
      }
    } catch (err) {
      console.log(err);
      setAuth(undefined);
    }
  };

  const authenticateGuest = (profile: UserProfile) => {
    setAuth({ ...profile, guest: true });
  };

  const unAuthenticate = () => {
    removeCredentials();
    setAuth(undefined);
  };

  return {
    authenticate,
    authenticateGuest,
    unAuthenticate,
    storeTokenCredentials,
    removeCredentials,
    getCredentials,
  };
};

export default useAuth;
