import { APIErrorResponse } from 'common/APITypes';

const baseURL = `${process.env.REACT_APP_API_REST_PROTOCOL}://${
  process.env.REACT_APP_API_URL || ''
}/api/v1/oauth`;

export interface OAuthResponse {
  token: string;
  expiresAt: number;
  refresh: string;
}

export interface RefreshResponse {
  token: string;
  expiresAt: number;
}

interface UseOAuthHook {
  oauthGoogle: (code: string) => Promise<OAuthResponse | APIErrorResponse>;
  refreshToken: (token: string) => Promise<RefreshResponse | APIErrorResponse>;
}

const useOAuth = (): UseOAuthHook => {
  // Authenticates with google given a code
  const oauthGoogle = async (
    code: string,
  ): Promise<OAuthResponse | APIErrorResponse> => {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Refresh an authentication token for the user
  const refreshToken = async (
    token: string,
  ): Promise<RefreshResponse | APIErrorResponse> => {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return { oauthGoogle, refreshToken };
};

export default useOAuth;
